import React, { useEffect, useState } from 'react';
import { Button, Col, FormInput, InputGroup, InputGroupAddon, InputGroupText, ListGroupItem, Row } from 'shards-react';
import { APIService } from '../../../utils/APIService';
import Loader from '../../../components/Loader/Loader';

function SocialMediaLinks() {
  const [edits, setEdits] = useState({});
  const [data, setData] = useState();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [elemLoading, setElemLoading] = useState();

  useEffect(() => {
    getService();
  }, []);

  const setInitialState = (idx) => {
    state.contents[idx] = { ...data.contents[idx] };
    setState({ ...state });
    setEdits({ [idx]: false });
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.currentTarget;
    state.contents[idx][name] = value;
    setState({ ...state });
  };

  const getService = () => {
    setState();
    setData();
    setLoading(true);
    APIService.getCMSSubSection('get-in-touch', 'social-media-links')
      .then((resp) => {
        if (typeof resp === 'object' && Array.isArray(resp.contents)) {
          const dataToSet = resp.contents.map((content, idx) => ({
            id: content.id,
            label: content.label,
            src: content.video_src,
          }));

          setData({ contents: JSON.parse(JSON.stringify(dataToSet)) });
          setState({ contents: dataToSet });
          setEdits({});
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (idx) => {
    const updates = state.contents[idx];

    if (updates.src) {
      let url;
      let isValidVideoSrc = true;

      try {
        url = new URL(updates.src);
      } catch (_) {
        isValidVideoSrc = false;
      } finally {
        isValidVideoSrc = isValidVideoSrc && (url.protocol === 'http:' || url.protocol === 'https:');

        if (!isValidVideoSrc) return alert(`${updates.label} url is not valid`);
      }
    }
    setElemLoading(idx);
    APIService.updateCmsContent(updates.id, { video_src: updates.src })
      .then(getService)
      .finally(() => setElemLoading());
  };

  if (!state) return <Loader />;

  return (
    <ListGroupItem>
      {state.contents.map((content, idx) => (
        <div style={{ marginBottom: '20px' }}>
          <Row>
            <Col md={{ size: 12 }}>
              <label htmlFor="feFirstName">{content.label}</label>
            </Col>
            <Col md={{ size: 9 }}>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>Source URL</InputGroupText>
                </InputGroupAddon>
                <FormInput
                  type="text"
                  name="src"
                  value={content.src}
                  disabled={!edits[idx]}
                  onChange={(e) => handleChange(e, idx)}
                />
              </InputGroup>
            </Col>
            <Col md={{ size: 3 }}>
              {edits[idx] && (
                <>
                  {elemLoading === idx ? (
                    <Button outline theme="primary" className="mb-2 mr-2" disabled={elemLoading === idx}>
                      <span class="spinner-border" />
                    </Button>
                  ) : (
                    <>
                      <Button
                        outline
                        theme="primary"
                        className="mb-2 mr-2"
                        disabled={loading}
                        onClick={() => handleSubmit(idx)}
                      >
                        Save
                      </Button>
                      {!loading && (
                        <Button outline theme="danger" className="mb-2 mr-1" onClick={() => setInitialState(idx)}>
                          Cancel
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
              {!edits[idx] && (
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  disabled={loading}
                  onClick={() => setEdits({ ...edits, [idx]: true })}
                >
                  {loading ? <span class="spinner-border" /> : 'Edit'}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ))}
    </ListGroupItem>
  );
}

export default SocialMediaLinks;

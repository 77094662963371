import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

function RadioButton() {
  const [value, setValue] = React.useState("home");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="address"
        name="adress"
        value={value}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value="home"
          control={<Radio color="primary" />}
          label="Home Address"
        />
        <FormControlLabel
          value="office"
          control={<Radio color="primary" />}
          label="Other Address"
        />
      </RadioGroup>
    </FormControl>
  );
}
export default RadioButton;

function RadioButtonDis() {
  const [value, setValue] = React.useState("home");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="address"
        name="adress"
        value={value}
        onChange={handleChange}
        readonly="true"
        row
      >
        <FormControlLabel
          value="home"
          control={<Radio color="primary" />}
          label="Home Address"
          disabled
        />
        <FormControlLabel
          value="office"
          control={<Radio color="primary" />}
          label="Other Address"
          disabled
        />
      </RadioGroup>
    </FormControl>
  );
}

export {RadioButtonDis}
import React, { useState, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MainTitle from "../../components/common/MainTitle";
import ContentHeader from "../../components/common/ContentHeader";
import {
  Container,
  ListGroupItem,
  Row,
  Col,
  Card,
  // CardHeader,
  CardBody,
  Button,
  // ButtonGroup,
  Form,
  FormInput,
  Alert,
  FormSelect,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from "shards-react";
import { APIService } from "../../utils/APIService";
import Calender, { SelectDate } from "../ServiceRequest/Calender";
import RadioButtons from "../../components/components-overview/RadioButtons";
import RadioButton from "../ServiceRequest/RadioButton";

function Payment(props) {
  // Country Series API intregation

  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });

  useEffect(() => {
    fetchCountryData();
  }, []);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    if (event.target.checked)
      setState({ ...state, [event.target.name]: event.target.checked });
    else setState({ ...state, [event.target.name]: event.target.value });
  };

  //End of COuntry Series

  return (
    <>
      <Container fluid className="main-content-container">
        <MainTitle title="Payment Information" />
        <ContentHeader>
          <Button
            onClick={() => {
              if (
                window.confirm(
                  "All your changes will be lost.Do you still want to continue ?"
                )
              ) {
                props.history.push("/users-new-personal");
              }
            }}
            outline
            theme="primary"
            className="mb-2 mr-1"
          >
            Back
          </Button>
        </ContentHeader>
        <ListGroupItem>
          <Row>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <h4>Credit Card/Debit Card</h4>
            </Col>
          </Row>

          <Row form>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label htmlFor="feMiddleName">Card Number*</label>
              <FormInput
                id="fecardNumber"
                placeholder="card Number"
                type="number"
                name="card_number"
                required
                onChange={handleChange}
              />
            </Col>

            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label htmlFor="feFirstName">Name on Card*</label>
              <FormInput
                id="feFirstName"
                type="text"
                placeholder="First Name"
                name="first_name"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feFirstName">Expiry Date*</label>
              <SelectDate />
            </Col>

            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="feAddress">CVV Code*</label>
              <FormInput
                id="fecvvCOde"
                type="number"
                placeholder="CVV Code"
                name="address_1"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size:4, order: 4 }} className="form-group ">
                </Col>
                </Row>
     
           
                 <Row>
       <Col md={{ size: 12, order: 4 }} className="form-group ">
<h4>Billing Address</h4>
           </Col>

           </Row>
           <Row>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress">Address Line 1*</label>
              <FormInput
                id="feAddress"
                type="text"
                placeholder="Address Line 1"
                name="address_1"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">Address Line 2</label>
              <FormInput
                id="feAddress2"
                type="text"
                placeholder="Address Line 2"
                name="address_2"
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feCountry">Country</label>
              <FormSelect
                id="feCountry"
                name="country_id"
                onChange={(e) => fetchStateData(e.target.value)}
                required
              >
                <option value={0}>country</option>
                {state.countries.map((country, i) => (
                  <option
                    value={country.id}
                    key={i}
                    selected={state.country == country.id}
                  >
                    {country.name}
                  </option>
                ))}
              </FormSelect>
            </Col>

            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feState">State</label>
              <FormSelect
                id="feState"
                onChange={(e) => fetchCityData(e.target.value)}
                required
              >
                <option value={0}>state</option>
                {state.states.map((selectedState, i) => (
                  <option
                    value={selectedState.id}
                    key={i}
                    selected={state.state == selectedState.id}
                  >
                    {selectedState.name}
                  </option>
                ))}
              </FormSelect>
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feCity">City</label>
              <FormSelect
                id="feCity"
                value={state.city}
                onChange={(e) => setState({ ...state, city: e.target.value })}
                required
              >
                <option value={0}>city</option>
                {state.cities.map((city, i) => (
                  <option
                    value={city.id}
                    key={i}
                    selected={state.city == city.id}
                  >
                    {city.name}
                  </option>
                ))}
              </FormSelect>
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="feZip">Zip Code*</label>
              <FormInput
                id="feZip"
                type="text"
                placeholder="Zip Code"
                name="zip_code"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feEmail">Email ID*</label>
              <FormInput
                id="feEmail"
                type="email"
                placeholder="Email"
                name="email"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feMobile">Mobile Phone*</label>
              <FormInput
                id="feMobile"
                type="number"
                placeholder="Mobile Phone"
                name="mobile"
                maxLength="10"
                required
                onChange={handleChange}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="fepassword">Nick Name*</label>
              <InputGroup>
                <FormInput
                  id="fepassword"
                  type="text"
                  placeholder="Nick Nmare"
                  name="nick_name"
                  required
                />
              </InputGroup>
            
            </Col>
             <Col md={{ size: 12, order: 4 }} className="form-group">
              <h4>Primary</h4>
              <RadioButton />
            </Col>

            </Row>
      
           
              <Row>
              <Col md={{ size: 6, order: 4 }} className="form-group ">
                <h4> Bank Account</h4>
        
              </Col>
              </Row>  
              <Row form>
              <Col md={{ size: 6, order: 4 }} className="form-group">
                <label htmlFor="fepassword"> Name of the Bank*</label>
                <InputGroup>
                  <FormInput
                    id="febankName"
                    type="text"
                    placeholder="Name of the Bank"
                    name="bank_name"
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group">
                <label htmlFor="fepassword"> Name of Account*</label>
                <InputGroup>
                  <FormInput
                    id="fepassword"
                    type="text"
                    placeholder=" Name of Account"
                    name="account_name"
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group">
                <label htmlFor="fepassword">Account Number*</label>
                <InputGroup>
                  <FormInput
                    id="feaccountNumber"
                    type="text"
                    placeholder="Account Number"
                    name="account_number"
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group">
                <label htmlFor="fepassword"> Routing number*</label>
                <InputGroup>
                  <FormInput
                    id="feRoutingNumber"
                    type="text"
                    placeholder="Routing number"
                    name="routing_number"
                    required
                  />
                </InputGroup>
              </Col>
           
            </Row>

            

              <Row>
              <Col md={{ size: 12, order: 4 }} className="form-group mb-5 ">
                <h4>Account Type</h4>
                <Col md={{ size: 6, order: 4 }} className="form-group ">
               
               <RadioButton />
             </Col>
             <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="fepassword">Nick Name*</label>
              <InputGroup>
                <FormInput
                  id="fepassword"
                  type="text"
                  placeholder="Nick Nmare"
                  name="nick_name"
                  required
                />
              </InputGroup>
            
            </Col>
            <Col md={{ size: 12, order: 4 }} className="form-group">
              <h4>Primary</h4>
              <RadioButton />
            </Col>
                </Col>
                </Row>
                
        
         

          <Col md={{ size: 4, order: 4, offset:4 }} className="form-group mb-5">
            <Button
              outline
              theme="danger"
              className="mb-2 mr-1"
              onClick={() => {
                if (window.confirm("Are you sure, to save the data")) {
                  console.log(state);
                  APIService.addSubAdmin(state)
                    .then((res) => {
                      console.log(res);
                      props.history.push("/users-new-personal");
                    })
                    .catch((err) => console.log(err));
                }
              }}
            >
             Back
            </Button>
         
            <Button
              outline
              theme="danger"
              className="mb-2 mr-1 ml-5"
              onClick={() => {
                if (window.confirm("Are you sure, to save the data")) {
                  console.log(state);
                  APIService.addSubAdmin(state)
                    .then((res) => {
                      console.log(res);
                      props.history.push("");
                    })
                    .catch((err) => console.log(err));
                }
              }}
            >
              Continue
            </Button>
          </Col>
          <Col md={{ size: 4, order: 4, offset: 3 }} className="form-group ">
            <h4 style={{ textAlign: "center" }}>
              Already have any account?
              <span href="" style={{ color: "Orange" }}>
                Sign In
              </span>
            </h4>
          </Col>
        </ListGroupItem>
      </Container>
    </>
  );
}
export default Payment;


import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'shards-react';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
import { APIService } from '../../utils/APIService';
import MaterialTable from 'material-table';

function SubAdmin(props) {
  const [srRequest, setSrRequest] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setSrRequest();
    const serviceReq = async () => {
      const response = await APIService.fetchAllSubAdmins();

      setSrRequest(
        response.map((item) => {
          return {
            id: item.id,
            first_name: item.first_name,
            middle_name: item.middle_name,
            last_name: item.last_name,
            email: item.email,
            mobile_phone: item.phone,
            home_phone: item.phone,
            status: item.status == 1 ? 'Active' : 'Inactive',
            role_type: item.role_types && item.role_types.map((rt) => rt.role_types[0].role_type).join(','),
          };
        })
      );
    };
    serviceReq();
  }

  return (
    <Container fluid className="main-content-container px-4">
      <MainTitle title="Sub-Admin List" />
      <Row>
        <Col>
          <Card small className="mb-4">
            <ContentHeader>
              <Button
                outline
                theme="primary"
                className="mb-2 mr-1"
                onClick={() => {
                  props.history.push('/sub-admin-add');
                }}
              >
                Add Sub Admin
              </Button>
            </ContentHeader>
            <CardBody className="p-0 pb-3">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title="Users"
                  isLoading={!srRequest}
                  columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Middle Name', field: 'middle_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Email ID', field: 'email' },
                    { title: 'Mobile Phone', field: 'mobile_phone' },
                    { title: 'Home Phone', field: 'home_phone' },
                    { title: 'Status', field: 'status' },
                    { title: 'Access Module', field: 'role_type' },
                  ]}
                  data={srRequest}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Sub Admin Details',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push({
                          pathname: '/sub-admin-View/' + data.id,
                        });
                      },
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Edit Sub Admin',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/sub-admin-edit/' + data.id);
                      },
                    },
                  ]}
                  localization={{
                    body: { editRow: { deleteText: `Are you sure you want to delete this Admin?` } },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        APIService.deleteSubAdmin(oldData.id).then(getService).then(resolve);
                      }),
                  }}
                />
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default SubAdmin;

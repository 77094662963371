import React from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	// CardHeader,
	CardBody,
	Button,
	// ButtonGroup,
	Form,
	FormInput,
	Alert,
	FormTextarea,
	FormSelect
} from 'shards-react';
// import PageTitle from '../../components/common/PageTitle';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
import Loader from '../../components/Loader/Loader';
import { Redirect } from 'react-router-dom';
import { APIService } from '../../utils/APIService';
import userLoginStatus from '../../utils/userLoginStatus';


class AddEditCommissionTax extends React.Component {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		loginStatus: undefined,
	// 		success: false,
	// 		redirect: false,
	// 		loading: false,
	// 		listItems: false,
	// 		internetConnected: true,
	// 		visible: false,
	// 		name: this.props.location.state ? this.props.location.state.name : '',
	// 		code:this.props.location.state ? this.props.location.state.code : '',
	// 		value:this.props.location.state ? this.props.location.state.value : '',
	// 		id: this.props.location.state ? this.props.location.state.id : '',
	// 		update: this.props.location.state ? this.props.location.state.update : false,
	// 		status: this.props.location.state ? this.props.location.state.status : true,
	// 	};

	// 	this._handleChange = this._handleChange.bind(this);
	// 	this._handleSubmitAdd = this._handleSubmitAdd.bind(this);
	// 	this._handleSubmitUpdate = this._handleSubmitUpdate.bind(this);
	// 	this.dismiss = this.dismiss.bind(this);
	// }

	// componentDidMount() {
	// 	if (this.state.loginStatus === undefined) {
	// 		userLoginStatus().then(
	// 			(value) => {
	// 				this.setState({
	// 					loginStatus: true,
	// 					loading: false
	// 				});
	// 			},
	// 			(reason) => {
	// 				this.setState({ loginStatus: false });
	// 			}
	// 		);
	// 	}
	// }

	// _handleChange(e) {
	// 	const { name, value } = e.target;
	// 	this.setState({ [name]: value });

		
	// }

	// _handleSubmitAdd(e) {
	// 	e.preventDefault();
	// 	this.setState({ loading: true });

	// 	APIService.addcommissionTax(this.state).then(
	// 		(unit) => {
	// 			this.setState({
	// 				success: true,
	// 				loading: false,
	// 				redirect: true,
	// 				redirectPath: '/charge-factor',
	// 				redirectData: {
	// 					visible: true,
	// 					alertStyle: 'success',
	// 					alertIcon: 'fa fa-check mx-2',
	// 					alertMessage: 'Charge-factor added successfully.'
	// 				}
	// 			});
	// 		},
	// 		(error) => {
	// 			// alert(JSON.stringify(error, null, 2));
	// 			this.setState({
	// 				success: false,
	// 				loading: false,
	// 				visible: true,
	// 				alertStyle: 'danger',
	// 				alertIcon: 'fa fa-exclamation mx-2',
	// 				alertMessage: error.errorMessage
	// 			});
	// 		}
	// 	);
	// }

	// _handleSubmitUpdate(e) {
	// 	e.preventDefault();

	// 	const id = this.state.id;
	// 	// console.log(name);
	// 	APIService.updatecommissionTax(id, this.state).then(
	// 		(unit) => {
	// 			this.setState({
	// 				success: true,
	// 				loading: false,
	// 				redirect: true,
	// 				redirectPath: '/charge-factor',
	// 				redirectData: {
	// 					visible: true,
	// 					alertStyle: 'success',
	// 					alertIcon: 'fa fa-check mx-2',
	// 					alertMessage: 'Charge-factor updated successfully.'
	// 				}
	// 			});
	// 		},

	// 		(error) => {
	// 			// console.log('error == ' + error);

	// 			this.setState({
	// 				success: false,
	// 				loading: false,
	// 				visible: true,
	// 				alertStyle: 'danger',
	// 				alertIcon: 'fa fa-exclamation mx-2',
	// 				alertMessage: error.errorMessage
	// 			});
	// 		}
	// 	);
	// }

	// dismiss() {
	// 	this.setState({ visible: false });
	// }

	_renderForm() {
		return (
			<Form onSubmit={this.state.update ? this._handleSubmitUpdate : this._handleSubmitAdd}>
				<Row form>
					<Col sm={{ size: 4, order: 4, offset: 4 }} className="mt-3">
					<label>Charge-factor</label>
						<FormInput
							id="unitName"
							type="text"
							placeholder="Charge-factor Name"
							name="name"
							value={this.state.name}
							onChange={this._handleChange}
						/>
					</Col>
				</Row>
				<Row form>
					<Col sm={{ size: 4, order: 4, offset: 4 }} className="mt-3">
					<label>Charge-factor code</label>
						<FormInput
							id="unitCode"
							type="text"
							placeholder="Charge-factor code"
							name="code"
							value={this.state.code}
							onChange={this._handleChange}
						/>
					</Col>
				</Row>
				<Row form>
					<Col sm={{ size: 4, order: 4, offset: 4 }} className="mt-3">
					<label>Charge-factor value</label>
						<FormInput
							id="unitValue"
							type="text"
							placeholder="Charge-factor value"
							name="value"
							value={this.state.value}
							onChange={this._handleChange}
						/>
					</Col>
				</Row>
				<Row form>
					<Col md={{ size: 4, order: 4, offset: 4 }} className="mt-3">
						<label htmlFor="feStatus">Status</label>
						<FormSelect id="feStatus" onChange={this._handleChange} name="status">
							<option disabled  value=''>Choose Status</option>
							<option value="1" selected={this.state.status == "1" ? true : false}>Active</option>
							<option value="0" selected={this.state.status == "0" ? true : false}>Inactive</option>
						</FormSelect>
					</Col>
				</Row>
				<Row form>
					<Col sm={{ size: 6, order: 6, offset: 5 }}>
						{this.state.update ? <Button type="submit">Update</Button> : <Button type="submit">Add</Button>}
					</Col>
				</Row>
			</Form>
		);
	}

	render() {
		// const { loginStatus, loading, internetConnected } = this.state;
		// if (this.state.redirect) {
		// 	return (
		// 		<Redirect
		// 			to={{
		// 				pathname: this.state.redirectPath,
		// 				state: this.state.redirectData
		// 			}}
		// 		/>
		// 	);
		// }
		// if (!internetConnected) {
		// 	return <div>No Internet Connection.</div>;
		// }
		// if (loading) {
		// 	return <Loader />;
		// }
		// if (loginStatus === undefined) {
		// 	return <Loader />;
		// } else if (loginStatus) {
			return (
				<div>
					<Container fluid className="px-0">
						<Alert
							theme={this.state.alertStyle || 'primary'}
							dismissible={this.dismiss}
							open={this.state.visible}
							className="mb-0"
						>
							<i className={this.state.alertIcon} /> {this.state.alertMessage}
						</Alert>
					</Container>
					<Container fluid className="main-content-container px-4">
						<MainTitle title="Charge Factor" />
						<Row>
							<Col>
								<Card small className="mb-4">
									<ContentHeader title={this.state.name === '' ? 'Add Charge-factor' : 'Edit Charge-factor'}>
										<Button
											outline
											theme="primary"
											className="mb-2 mr-1"
											onClick={() => {
												if (
													window.confirm(
														'All your changes will be lost. Do you still want continue?'
													)
												) {
													this.setState({
														redirect: true,
														redirectPath: '/charge-factor'
													});
												}
											}}
										>
											Back
										</Button>
									</ContentHeader>
									<CardBody className="p-0 pb-3">
										{this._renderForm()}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			);
		// } else {
		// 	return <Redirect to="/login" />;
		// }
	}
}

export default AddEditCommissionTax;

import React, { useState, useEffect } from 'react';
import Calender from '../ServiceRequest/Calender';
import MaterialTable from 'material-table';
import TextField from '@material-ui/core/TextField';
import { APIService } from '../../utils/APIService';
import { SR_STATUSES, DISPUTE_REASONS, REVERSE_DISPUTE_REASONS } from '../../data/constants';
import { ListGroupItem, Row, Col, FormInput, FormSelect, Container, Card, CardBody, Button } from 'shards-react';

const DisputeMgt = (props) => {
  const [srRequest, setSrRequest] = useState();
  const [filters, setFilters] = useState({});
  const [state, setState] = useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });
  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);

  useEffect(() => {
    fetchCountryData();

    APIService.fetchAllCategories()
      .then((res) => {
        setServiceCats(res.data);
      })
      .catch((err) => {});

    APIService.fetchAllSubCategories()
      .then((res) => {
        setServiceSubCats(res);
      })
      .catch((err) => {});
  }, []);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setSrRequest();
    const serviceReq = async () => {
      const response = await APIService.fetchDisputeService({ queryParams: filters });
      console.log(response);
      setSrRequest(
        response.map((item) => {
          return {
            id: item.id,
            disputeDetails: item.dispute_details,
            disputeReason: DISPUTE_REASONS[item.dispute_reason],
            disputeCreationDate: item.created_at?.split('T')[0] || '',
            srId: item.request_id,
            quoteId: item.quote_id,
            customerId: item.user_id,
            providerId: item.provider_id,
            closureDate: item.dispute_closure_date?.split('T')[0] || '',
            closureComments: item.closure_comments,
          };
        })
      );
    };
    serviceReq();
  }

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'categoryId') filters.subCategoryId = '';

    setFilters({ ...filters, [name]: value });
  };

  return (
    <div>
      <Container fluid className="main-content-container">
        {/* <MainTitle title="Dispute Request Management" /> */}
        <Row>
          <Col lg="12" className="mb-4">
            {/* <ContentHeader>
              <Button  onClick={()=>{props.history.push('/warranty-dispute-mgt')}} outline theme="primary" className="mb-2 mr-1">
                Back
              </Button>
            </ContentHeader> */}

            <ListGroupItem>
              <Row>
                <Col md={{ size: 1, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser" name="userType" onChange={handleChange}>
                    <option value="">Choose</option>
                    <option value="2">Customer</option>
                    <option value="3">Both</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="custLastName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="custFirstName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile #</label>
                  <FormInput
                    id="feMobile"
                    type="number"
                    placeholder="Mobile"
                    name="custMobile"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput id="feEmail" type="email" placeholder="Email" name="custEmail" onChange={handleChange} />
                </Col>
                <Col md={{ size: 1, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Customer Id"
                    name="custId"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory" name="categoryId" onChange={handleChange}>
                    <option value="">Select the service category *</option>
                    {serviceCats.map((sc) => (
                      <option value={sc.id}>{sc.category_name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser" name="subCategoryId" onChange={handleChange} value={filters.subCategoryId}>
                    <option value="">Choose</option>
                    {serviceSubCats
                      .filter((sc) => sc.category_id == filters.categoryId)
                      .map((sc) => (
                        <option value={sc.id}>{sc.sub_category_name}</option>
                      ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Service Request ID"
                    name="srId"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label htmlFor="feCountry">Country</label>
                  <FormSelect
                    id="feCountry"
                    name="countryId"
                    onChange={(e) => {
                      fetchStateData(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value={0}>country</option>
                    {state.countries.map((country, i) => (
                      <option value={country.id} key={i} selected={state.country == country.id}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feState">State</label>
                  <FormSelect
                    id="feState"
                    onChange={(e) => {
                      fetchCityData(e.target.value);
                      handleChange(e);
                    }}
                    required
                    name="stateId"
                  >
                    <option value={0}>state</option>
                    {state.states.map((selectedState, i) => (
                      <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                        {selectedState.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormSelect
                    id="feCity"
                    value={state.city}
                    onChange={(e) => {
                      setState({ ...state, city: e.target.value });
                      handleChange(e);
                    }}
                    required
                    name="cityId"
                  >
                    <option value={0}>city</option>
                    {state.cities.map((city, i) => (
                      <option value={city.id} key={i} selected={state.city == city.id}>
                        {city.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput id="feZip" type="text" placeholder="Zip Code" name="zipCode" onChange={handleChange} />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feSrStatus">SR Status</label>
                  <FormSelect id="feSrStatus" onChange={handleChange} name="srStatus">
                    <option value="">Choose</option>
                    {Object.entries(SR_STATUSES).map(([key, val]) => (
                      <option value={val}>{key}</option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Dispute Request Creation Date Range</label>
                  <Calender name="created_at" onChange={handleChange} />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Schedule Date Range</label>
                  <Calender name="service_date" onChange={handleChange} />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button
                    outline
                    theme="primary"
                    className="mb-2 mr-4"
                    onClick={getService}
                    disabled={!Object.keys(filters).length}
                  >
                    Apply Filter
                  </Button>
                  {!!Object.keys(filters).length && (
                    <Button
                      outline
                      theme="danger"
                      className="mb-2 mr-1"
                      onClick={() => {
                        window.location.reload();
                      }}
                      disabled={!Object.keys(filters).length}
                    >
                      Clear Filter
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              {' '}
              <b>Dispute Requests List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  isLoading={!srRequest}
                  title=""
                  columns={[
                    { title: 'ID', field: 'id', editable: 'never' },
                    { title: 'Dispute Details', field: 'disputeDetails' },
                    {
                      title: 'Dispute Reason',
                      field: 'disputeReason',
                      editComponent: (props) => (
                        <select
                          value={REVERSE_DISPUTE_REASONS[props.value]}
                          onChange={(e) => props.onChange(e.currentTarget.value)}
                        >
                          <option value={0}>Choose</option>
                          {Object.entries(DISPUTE_REASONS).map(([id, reason]) => (
                            <option value={id} key={id}>
                              {reason}
                            </option>
                          ))}
                        </select>
                      ),
                    },
                    { title: 'Dispute Creation Date', field: 'disputeCreationDate', editable: 'never' },
                    { title: 'SR ID', field: 'srId', editable: 'never' },
                    { title: 'Quote ID', field: 'quoteId', editable: 'never' },
                    { title: 'Customer ID ', field: 'customerId', editable: 'never' },
                    { title: 'Provider ID', field: 'providerId', editable: 'never' },
                    {
                      title: 'Closure Date',
                      field: 'closureDate',
                      editComponent: (props) => (
                        <TextField
                          type="date"
                          name="closureDate"
                          InputLabelProps={{ shrink: true }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.currentTarget.value)}
                        />
                      ),
                      validate: (rowData) => !!rowData.closureDate,
                    },
                    {
                      title: 'Closure Comments',
                      field: 'closureComments',
                      validate: (rowData) => !!rowData.closureComments,
                    },
                  ]}
                  data={srRequest}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  localization={{
                    body: { editRow: { deleteText: `Are you sure you want to delete this Dispute Request?` } },
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        const updates = {};

                        Object.entries(newData).forEach(([key, val]) => {
                          if (newData[key] !== oldData[key]) {
                            if (key === 'disputeReason')
                              val = REVERSE_DISPUTE_REASONS[newData.disputeReason] || newData.disputeReason;

                            updates[key] = val;
                          }
                        });

                        APIService.updateDisputeRequest(oldData.id, updates).then(getService).then(resolve);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        APIService.deleteDisputeRequest(oldData.id).then(getService).then(resolve);
                      }),
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default DisputeMgt;

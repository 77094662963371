import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const CreateAdjustment = (props) => {
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Create New Adjustment" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/adjustment-management");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2"
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  props.history.push("");
                }}
                outline
                theme="primary"
                className="mb-2 ml-2 mr-2"
              >
                Cancel
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
                <Col lg="4" className="mb-4">
                  <Row>
                    <Col md={{ size: 6, order: 1 }}>
                      <label htmlFor="feLastName">Enter User ID</label>
                      <FormInput
                        id="feLastName"
                        placeholder="Enter User ID"
                        type="text"
                        name="lastName"
                      />
                    </Col>
                    <Col md={{ size: 6, order: 1 }}>
                      <label>User Type</label>
                      <FormSelect id="feUser">
                        <option>Choose</option>
                        <option>Customer</option>
                        <option>Service Provider</option>
                        <option>Both</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 6, order: 1 }}>
                      <label>Transaction ID</label>
                      <FormSelect id="feUser">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                  </Row>
                </Col>

                <Col lg="8" className="mb-4">
                  <Row>
                    <Col md={{ size: 6, order: 1 }}>
                      <label>User First Name</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="User First Name"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label>User Last Name</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="User Last Name"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }} className="mb-2">
                      <label>Date Of Birth</label>
                      <br />
                      {/* <TextField
                        id="date"
                        type="date"
                        defaultValue="yyyy-mm-dd"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        readonly="true"
                      /> */}
                        <FormInput
                        id="feDate"
                        type="date"
                        name="date"
                        value={"YYYY-MM-DD"}
                        readonly="true"
                   
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>User Type</label>
                      <FormSelect id="feUser"
                      readonly="true"
                      >
                        <option>Choose</option>
                        <option>Customer</option>
                        <option>ISP</option>
                        <option>Both</option>
                        
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Invoice Amount</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="Invoice Amount $"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>SP Payout Amount</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="SP Payout Amount $"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>eZunction Payout Amount</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="eZunction Payout Amount $"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }} className="mb-4">
                      <label>Transaction Date</label>
                      <br />
                      <FormInput
                        id="feDate"
                        type="date"
                        name="date"
                        value={"YYYY-MM-DD"}
                        readonly="true"
                   
                      />
                    </Col>
                    
                       
                    <Col md={{ size: 4, order: 4 }} className="mb-4">
                      <label>Service Request Close Date</label>
                      {/* <TextField
                        id="date"
                        type="date"
                        defaultValue="yyyy-mm-dd"
                        // InputLabelProps={{
                        //   shrink: true
                        // }}
                        readonly="true"
                      /> */}
                        <FormInput
                        id="feDate"
                        type="date"
                        name="date"
                        value={"YYYY-MM-DD"}
                        readonly="true"
                   
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Request Status</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="Service Request Status"
                        name="customerId"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Quote Status</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="Quote Status"
                        name="quoteStatus"
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>User Balance Amount</label>
                      <FormInput
                        id="feCustId"
                        type="text"
                        placeholder="User Balance Amount $"
                        name="userBalanceAmount"
                        readonly="true"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Manual Adjustment Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                    <option>Refund</option>
                    <option>Charge</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Adjustment Amount</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Adjustment Amount $"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label>Adjustment Reason</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Adjustment Reason$"
                    name="adjustmentReason"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Request Id</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                    
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Quote Id</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }} className="mb-4">
                      <label>Adjustment Date</label>
                      <br />
                      <TextField
                        id="date"
                        type="date"
                        defaultValue="yyyy-mm-dd"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Payment Method</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label>Payment Method Type</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Payment Method Type"
                    name="customerId"
                  />
                </Col>
              </Row>
              <Col className="text-md-right mb-2 mt-4 mr-5">
                <Button
                //   onClick={() => {
                //     props.history.push("");
                //   }}
                  outline
                  theme="primary"
                >
                  Submit Adjustment
                </Button>
              </Col>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CreateAdjustment;

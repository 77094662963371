import React, { useEffect, useState } from 'react';
import { Button, Col, Row, ListGroupItem } from 'shards-react';
import config from '../../../data/config';
import { APIService } from '../../../utils/APIService';

const allowedDimensions = { width: 533, height: 324 };

function PeoplesVoiceRow({ content, getService }) {
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    content && setInitialState();
  }, [content]);

  const setInitialState = () => {
    setState({ ...state, ...content });

    setImage({
      photo_url: content.image_thumbnail,
      photo_name: content.image_thumbnail.split('/')[4],
    });

    setEdit(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setState({ ...state, [name]: value });
    setUpdates({ ...updates, [name]: value });
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    const newImg = new Image();

    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      newImg.src = reader.result;
      newImg.onload = () => {
        if (allowedDimensions.height !== newImg.naturalHeight || allowedDimensions.width !== newImg.naturalWidth) {
          alert(`Please select image of dimension ${allowedDimensions.width} X ${allowedDimensions.height} only`);
        } else {
          setImage({ photo: image, photo_name: image.name, photo_url: reader.result });
        }
      };
    };
  };

  const handleSubmit = () => {
    if (state.video_src) {
      let url;
      let isValidVideoSrc = true;

      try {
        url = new URL(state.video_src);
      } catch (_) {
        isValidVideoSrc = false;
      } finally {
        isValidVideoSrc = isValidVideoSrc && (url.protocol === 'http:' || url.protocol === 'https:');

        if (!isValidVideoSrc) return alert('Video src is not valid');
      }
    }
    setLoading(true);
    APIService.updatePeopleVoice(state.id, { ...updates, image: image.photo })
      .then(getService)
      .finally(() => setLoading(false));
  };

  return (
    <ListGroupItem>
      <Row>
        <Col md={{ size: 4 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!!image.photo_url && <img src={image.photo_url} width="100%" id="img_prev" alt="herography" />}
        </Col>
        <Col md={{ size: 8, padding: 3 }} className="form-group mt-3">
          <div style={{ fontWeight: 'bold' }}>
            {edit ? (
              <input
                type="text"
                name="image_name"
                style={{ width: '50%' }}
                onChange={handleChange}
                placeholder="People voice's name"
                value={state.image_name || ''}
              />
            ) : (
              <span>{state.image_name}</span>
            )}
          </div>
          <div className="mt-3">
            <span style={{ fontWeight: 'bold' }}>Allowed dimension: </span>
            {allowedDimensions.width} X {allowedDimensions.height}
          </div>
          <div className="mt-3 mb-3">
            <span style={{ fontWeight: 'bold' }}>Image: </span>
            {edit ? (
              <input
                style={{ display: 'inline-block' }}
                type="file"
                id="avatar"
                name="avatar"
                accept="image/png, image/jpeg"
                disabled={!edit}
                onChange={handleChangeImage}
              />
            ) : (
              <span>{image.photo_name}</span>
            )}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Video src: </span>
            {edit ? (
              <input
                type="text"
                name="image_src"
                style={{ width: '50%' }}
                onChange={handleChange}
                placeholder="Enter video url"
                value={state.image_src || ''}
              />
            ) : (
              <a href={state.image_src || ''} target="_blank" rel="noopener noreferrer">
                {state?.image_src || ''}
              </a>
            )}
          </div>

          <div className="mt-3 mb-3">
            <span style={{ fontWeight: 'bold' }}>Status: </span>
            {edit ? (
              <select onChange={handleChange} value={state?.status} name="status">
                <option value="1">Active</option>
                <option value="0">In-Active</option>
              </select>
            ) : (
              <span>{state && parseInt(state.status) ? 'Active' : 'In-Active'}</span>
            )}
          </div>

          <Col md={{ size: 8, order: 4, offset: 8 }}>
            {edit && (
              <>
                {loading ? (
                  <Button outline theme="primary" className="mb-2 mr-2" disabled={loading}>
                    <span class="spinner-border" />
                  </Button>
                ) : (
                  <>
                    <Button outline theme="primary" className="mb-2 mr-2" disabled={loading} onClick={handleSubmit}>
                      Save
                    </Button>
                    {!loading && (
                      <Button outline theme="danger" className="mb-2 mr-1" onClick={setInitialState}>
                        Cancel
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
            {!edit && (
              <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={setEdit}>
                {loading ? <span class="spinner-border" /> : 'Edit'}
              </Button>
            )}
          </Col>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export default PeoplesVoiceRow;

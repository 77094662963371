import React, { useState } from 'react';
import { Col, Container, Row, CardHeader, Button } from 'shards-react';
import CustBotton from './CustButton';
import SpPayout from './SpPayout';
import Invoice from './Invoice';
import { useParams } from 'react-router-dom';
import ContentHeader from '../../components/common/ContentHeader';

function InvoiceSpPayout(props) {
  const { id } = useParams();
  const [state, setState] = useState({
    viewInvoice: props.location.state?.isInvoice ? true : false,
    viewSpPayout: props.location.state?.isInvoice ? false : true,
  });

  return (
    <Container fluid className="main-content-container">
      <ContentHeader>
        <Button
          onClick={() => {
            props.history.push('/service-request-mgt');
          }}
          outline
          theme="primary"
          className="mb-2 mr-1"
        >
          Back
        </Button>
      </ContentHeader>
      <CardHeader>
        <Row>
          <Col sm={{ size: 6, order: 2, offset: 4 }}>
            <CustBotton
              state={state}
              setState={setState}
              buttons={{ viewInvoice: 'Customer Invoice', viewSpPayout: 'SP Payout' }}
            />
          </Col>
        </Row>
      </CardHeader>
      {state.viewInvoice ? <Invoice srId={id} history={props.history} /> : null}
      {state.viewSpPayout ? <SpPayout srId={id} history={props.history} /> : null}
    </Container>
  );
}

export default InvoiceSpPayout;

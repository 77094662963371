module.exports = {
  url: 'baseurl', // modify this setting only if explicit pointing is required.
  baseurl: process.env.NODE_ENV === 'production' ? 'https://api.ezunction.com' : 'http://18.219.186.222:3334',
  localurl: 'http://localhost:3334',
  ngrokurl: 'https://3da17821.ngrok.io',
  apiKey: '6d0db711938bea9d123a5bc8afb41acc3ee3b4cd',
  contentType: 'application/json',
  accept: 'application/json',
  endpoints: {
    login: '/api/dashboard/admins/login',
    categories: '/api/dashboard/categories/',
    subcategories: '/api/dashboard/subcategories/',
    serviceType: '/api/dashboard/service-type/',
    housetype: '/api/dashboard/housetype/',
    timeslots: '/api/dashboard/timeslots/',
    withdrawalReason: '/api/dashboard/withdrawalReason/',
    rejectionReason: '/api/dashboard/rejectionReason/',
    srCancelReason: '/api/dashboard/srCancelReason/',
    srStatus: '/api/dashboard/srStatus/',
    quoteStatus: '/api/dashboard/quoteStatus/',
    commissionTax: '/api/dashboard/chargeFactor/',
    serviceRequest: '/api/dashboard/serviceRequest/',
    srActDeactivate: '/api/dashboard/serviceRequest/activateDeactivate/',
    quoteActDeactivate: '/api/dashboard/quoteService/activateDeactivate/',
    addServiceRequest: '/api/dashboard/service_request',
    disciplines: '/api/dashboard/disciplines/',
    technologies: '/api/v1/technologies/',
    stats: '/api/v1/stats',
    configurations: '/api/v1/configurations/',
    sub_admins: '/api/dashboard/sub-admins/',
    users: '/api/dashboard/users/',
    providers: '/api/dashboard/providers/',
    add_categories: '/api/dashboard/add_category',
    location: '/api/dashboard/location/',
    profile: '/api/dashboard/admin-profile',
    image_download_path: '/api/v1/download/',
    image_path: 'https://ezunction-dev.s3.amazonaws.com/',
    testURL: '/api/dashboard/serviceRequest',
    dispute: '/api/dashboard/disputeReason/',
    coupon: '/api/dashboard/coupons/',
    quoteService: '/api/dashboard/quoteService',
    warrantyService: '/api/dashboard/WarrantyService/',
    warrantyServiceSr: '/api/dashboard/WarrantyService/sr/',
    disputeService: '/api/dashboard/DisputeService/',
    disputeServiceSr: '/api/dashboard/DisputeService/sr/',
    quoteDisable: '/api/dashboard/QuoteDisableReason/',
    report: '/api/dashboard/Report/',
    adminRole: '/api/dashboard/AdminRole',
    registerUser: '/api/v1/users/register',
    srFileById: '/api/dashboard/service_request/file/',
    userActDeactivate: '/api/dashboard/users/activateDeactivate/',
    quoteComments: '/api/dashboard/quoteComments/',
    sendPassword: '/api/dashboard/sendPassword/',
    revisitReason: '/api/dashboard/RevisitReason/',
    srDisableReason: '/api/dashboard/SrDisableReason/',
    srInvoice: '/api/dashboard/srInvoice/',
    srSpPayout: '/api/dashboard/srSpPayout/',
    userDl: '/api/dashboard/user-dl/',
    spBl: '/api/dashboard/sp-bl/',
    spCompany: '/api/dashboard/sp-company/',
    spProfile: '/api/dashboard/sp-profile/',
    currentAdminRoles: '/api/dashboard/current-admin-roles/',
    cms: '/api/dashboard/cms/',
    cmsContent: '/api/dashboard/cmsContent/',
    peopleVoices: '/api/dashboard/peopleVoices/',
  },
};

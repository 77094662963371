import React, { useState, useEffect } from "react";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import { APIService } from "../../utils/APIService";
import ContentHeader from "../../components/common/ContentHeader";
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const ViewReport = (props) => {
  
//   const [srRequest, setSrRequest] = useState();

//   useEffect(() => {
//     getService();
//   }, []);

//   function getService() {
//     const serviceReq = async () => {
//       const response = await APIService.fetchUserMgt();
//       console.log(response);
//       console.log(srRequest);
//       setSrRequest(
//         response.map((item) => {
//           return {
//             id: item.id,
//             firstName: item.first_name,
//             middleName: item.middle_name,
//             lastName: item.last_name,
//             emailId: item.email,
//             mobile: item.phone,
//             userId: item.id,
//             status: item.status==1?'Active':'Inactive',
//             userType: item.user_type
//           };
//         })
//       );
//     };
//     serviceReq();
//   }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="View Report" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/reports-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
                <Col md={{ size: 1, order: 2 }}>
                  <label>ID</label>
                  <FormInput
                    id="feId"
                    placeholder="ID"
                    type="text"
                    name="id"
                    readonly="true"

                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Report Name</label>
                  <FormInput
                    id="feReportName"
                    placeholder="Report Name"
                    type="text"
                    name="reportName"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feReportDescription">Report Description</label>
                  <FormInput
                    id="feReport Description"
                    type="text"
                    placeholder="Report Description"
                    name="reportDescription"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Report Frequency</label>
                  <FormInput
                    id="feReport Frequency"
                    type="text"
                    placeholder="Report Frequency"
                    name="reportFrequency"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Report Date</label>
                  <FormInput
                    id="feReportDate"
                    type="email"
                    placeholder="Report Date"
                    name="reportDate"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Report Time</label>
                  <FormInput
                    id="feReportTime"
                    type="text"
                    placeholder="Report Time"
                    name="reportTime"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Automatic Schedule (Yes/No)</label>
                  <FormInput
                    id="feAutomaticSchedule"
                    type="text"
                    placeholder="Automatic Schedule"
                    name="automaticSchedule"
                    // value="Yes"
                    readonly="true"
                  />
                </Col>
             
                <Col md={{ size: 3, order: 4 }} className="mb-5">
                  <label htmlFor="feZip">Email To</label>
                  <FormInput
                    id="feEmailTo"
                    type="text"
                    placeholder="Email To"
                    name="Email_To"
                    readonly="true"
                  />
                </Col>
                <Col
            md={{ size: 4, order: 4, offset: 10 }}
            className="form-group "
          >
            <Button
              outline
              theme="primary"
              className="mb-2 mr-1"
              onClick={() => {
                props.history.push("/edit-report");
              }}
            >
              Edit
            </Button>
            </Col>
               
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
  
    </div>
  );
};
export default ViewReport;

import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout, LoginLayout } from './layouts';

// Route Views
import Login from './views/Login';
import Dashboard from './views/Dashboard';

import AdminProfile from './views/admin/profile';

import Categories from './views/Categories/list';
import AddCategories from './views/Categories/addEdit';
import CategoryDetails from './views/Categories/detail';

import SubCategories from './views/SubCategories/list';
import AddSubCategories from './views/SubCategories/addEdit';
import SubCategoryDetails from './views/SubCategories/detail';

// import SubAdmins from './views/SubAdmin/list';
// import AddSubAdmins from './views/SubAdmin/addEdit';
// import SubAdminsDetails from './views/SubAdmin/details';

import Users from './views/Users/list';
import AddUser from './views/Users/addEdit';
import UserDetail from './views/Users/detail';

import Providers from './views/Providers/list';
import AddProvider from './views/Providers/addEdit';
import ProviderDetail from './views/Providers/detail';

import ServiceType from './views/ServiceType/list';
import AddEditServiceType from './views/ServiceType/addEdit';
import ServiceDetail from './views/ServiceType/detail';

import HouseType from './views/DataSet/HouseType/list';
import AddHouseType from './views/DataSet/HouseType/addEdit';

import TimeSlots from './views/DataSet/TimeSlots/list';
import AddTimeSlots from './views/DataSet/TimeSlots/addEdit';

import QuoteWithDrawal from './views/DataSet/QuoteWithdrawal/list';
import AddQuoteWithDrawal from './views/DataSet/QuoteWithdrawal/addEdit';

import QuoteRejection from './views/DataSet/QuoteRejection/list';
import AddQuoteRejection from './views/DataSet/QuoteRejection/addEdit';

import SrCancel from './views/DataSet/SrCancel/list';
import AddSrCancel from './views/DataSet/SrCancel/addEdit';

import SrStatus from './views/DataSet/SrStatus/list';
import AddSrStatus from './views/DataSet/SrStatus/addEdit';

import quoteStatus from './views/DataSet/QuoteStatus/list';
import AddquoteStatus from './views/DataSet/QuoteStatus/addEdit';

import commissionTax from './views/commissionTax/list';
import AddcommissionTax from './views/commissionTax/addEdit';

import ServiceRequest from './views/ServiceRequest/list';
import AddServiceRequest from './views/ServiceRequest/addEdit';

import UserProfileLite from './views/UserProfileLite';
import ErrNotFound from './views/ErrNotFound';
import ComponentsOverview from './views/ComponentsOverview';
import Tables from './views/Tables';
import ServiceRequestMainSrc from './views/ServiceRequest/ServiceRequestMainSrc';

import CreateServiceRequest from './views/ServiceRequest/CreateServiceRequest';

import ViewServiceReques from './views/ServiceRequest/ViewServiceRequest';
import EditServiceRequest from './views/ServiceRequest/EditServiceRequest';

import QuoteManagement from './views/QuoteManagement/QuoteManagement';
import ViewQuoteDetails from './views/QuoteManagement/ViewQuoteDetails';
import EditQuote from './views/QuoteManagement/EditQuote';
import ViewChats from './views/QuoteManagement/ViewChats';
import WarrantyAndDisputeMgt from './views/WarrantyAndDisputeMgt/WarrantyAndDisputeMgt';
import WarrantyMgt from './views/WarrantyAndDisputeMgt/WarrantyMgt';
import DisputeMgt from './views/WarrantyAndDisputeMgt/DisputeMgt';
import CouponManagement from './views/Coupon Management/CouponManagement';
import AdjustmentManagement from './views/Adjustment Management/AdjustmentManagement';
import ReportsManagement from './views/Report Management/ReportsManagement';
import CreateAdjustment from './views/Adjustment Management/CreateAdjustment';

import CommissionTax from './views/Commisssion/CommisssionTax';
import SubAdminAdd from './views/SubAdmin/SubAdminAdd';
import SubAdminView from './views/SubAdmin/SubAdminView';
import SubAdminEdit from './views/SubAdmin/SubAdminEdit';
import SubAdmin from './views/SubAdmin/SubAdmin';
import UserManagementMain from './views/UserManagemnet/UserManagemnetMain';
import DisputeReason from './views/DataSet/Dispute Reason/disputeReason';
import RevisiteReason from './views/DataSet/Revisit Reason/revisitReason';
import SrDisableReason from './views/DataSet/SR Disable Reason/srDiableReason';
import QuoteDisableReason from './views/DataSet/Quote Disable Reason/quoteDisableReason';
import WarrantyClosureReason from './views/DataSet/Warranty Closure/warrantyClosureReason';
import ViewComments from './views/QuoteManagement/ViewComments';
import DisputeComment from './views/WarrantyAndDisputeMgt/DisputeComment';
import WarrantyComment from './views/WarrantyAndDisputeMgt/WarrantyComment';
import ViewReport from './views/Report Management/ViewReport';
import EditReport from './views/Report Management/EditReport';
import AddDispute from './views/DataSet/Dispute Reason/AddDispute';
import AddRevisit from './views/DataSet/Revisit Reason/AddRevisitReason';
import AddSrDisable from './views/DataSet/SR Disable Reason/AddSrDisable';
import AddQuoteDisableReason from './views/DataSet/Quote Disable Reason/AddQuoteDisableReason';
import AddWarrantyClosureReason from './views/DataSet/Warranty Closure/AddWarrantyClosureReason';
import AddEditTimeSlots from './views/DataSet/TimeSlots/addEdit';
import Personal from './views/UserManagemnet/Personal';
import Payment from './views/UserManagemnet/Payment';
import Test from './views/Test';
import InvoiceSpPayout from './views/ServiceRequest/InvoiceSpPayout';
import ViewEditUser from './views/UserManagemnet/ViewEdit';
import ContentManagement from './views/Content Management';

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: localStorage.getItem('user') ? () => <Redirect to="/dashboard" /> : () => <Redirect to="/login" />,
  },
  {
    path: '/login',
    layout: LoginLayout,
    component: Login,
  },
  {
    path: '/dashboard',
    layout: DefaultLayout,
    component: Dashboard,
  },

  {
    path: '/admin',
    layout: DefaultLayout,
    component: AdminProfile,
  },

  // {
  // 	path: '/sub-admins/new',
  // 	exact: true,
  // 	layout: DefaultLayout,
  // 	component: AddSubAdmins
  // },
  // {
  // 	path: '/sub-admins/:id',
  // 	exact: true,
  // 	layout: DefaultLayout,
  // 	component: SubAdminsDetails
  // },
  // {
  // 	path: '/sub-admins/:id/edit',
  // 	exact: true,
  // 	layout: DefaultLayout,
  // 	component: AddSubAdmins
  // },
  {
    path: '/users',
    exact: true,
    layout: DefaultLayout,
    component: UserManagementMain,
  },
  {
    path: '/users/new',
    exact: true,
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: '/users/:id',
    exact: true,
    layout: DefaultLayout,
    component: UserDetail,
  },
  {
    path: '/users/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: '/users-new-personal',
    exact: true,
    layout: DefaultLayout,
    component: Personal,
  },
  {
    path: '/users-new-Payment',
    exact: true,
    layout: DefaultLayout,
    component: Payment,
  },
  {
    path: '/users-new-additional',
    exact: true,
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: '/providers',
    exact: true,
    layout: DefaultLayout,
    component: Providers,
  },
  {
    path: '/providers/new',
    exact: true,
    layout: DefaultLayout,
    component: AddProvider,
  },
  {
    path: '/providers/:id',
    exact: true,
    layout: DefaultLayout,
    component: ProviderDetail,
  },
  {
    path: '/providers/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddProvider,
  },
  {
    path: '/categories',
    exact: true,
    layout: DefaultLayout,
    component: Categories,
  },
  {
    path: '/categories/new',
    exact: true,
    layout: DefaultLayout,
    component: AddCategories,
  },
  {
    path: '/categories/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddCategories,
  },
  {
    path: '/categories/:id',
    exact: true,
    layout: DefaultLayout,
    component: CategoryDetails,
  },
  {
    path: '/sub-categories',
    exact: true,
    layout: DefaultLayout,
    component: SubCategories,
  },
  {
    path: '/sub-categories/new',
    exact: true,
    layout: DefaultLayout,
    component: AddSubCategories,
  },
  {
    path: '/sub-categories/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddSubCategories,
  },
  {
    path: '/sub-categories/:id',
    exact: true,
    layout: DefaultLayout,
    component: SubCategoryDetails,
  },
  {
    path: '/service-type',
    exact: true,
    layout: DefaultLayout,
    component: ServiceType,
  },
  {
    path: '/service-type/new',
    exact: true,
    layout: DefaultLayout,
    component: AddEditServiceType,
  },
  {
    path: '/service-type/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddEditServiceType,
  },
  {
    path: '/service-type/:id',
    exact: true,
    layout: DefaultLayout,
    component: ServiceDetail,
  },

  {
    path: '/data-set',
    exact: true,
    layout: DefaultLayout,
    component: HouseType,
  },
  {
    path: '/house-type/new',
    exact: true,
    layout: DefaultLayout,
    component: AddHouseType,
  },
  {
    path: '/house-type/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddHouseType,
  },
  {
    path: '/time-slots',
    exact: true,
    layout: DefaultLayout,
    component: TimeSlots,
  },
  {
    path: '/time-slot/new',
    exact: true,
    layout: DefaultLayout,
    component: AddTimeSlots,
  },
  {
    path: '/time-slot/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddTimeSlots,
  },
  {
    path: '/quote-withdrawal-reasons',
    exact: true,
    layout: DefaultLayout,
    component: QuoteWithDrawal,
  },
  {
    path: '/quote-withdrawal-reason/new',
    exact: true,
    layout: DefaultLayout,
    component: AddQuoteWithDrawal,
  },
  {
    path: '/quote-withdrawal-reason/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddQuoteWithDrawal,
  },
  {
    path: '/quote-rejection-reasons',
    exact: true,
    layout: DefaultLayout,
    component: QuoteRejection,
  },
  {
    path: '/quote-rejection-reason/new',
    exact: true,
    layout: DefaultLayout,
    component: AddQuoteRejection,
  },
  {
    path: '/quote-rejection-reason/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddQuoteRejection,
  },
  {
    path: '/sr-cancel-reasons',
    exact: true,
    layout: DefaultLayout,
    component: SrCancel,
  },
  {
    path: '/sr-cancel-reason/new',
    exact: true,
    layout: DefaultLayout,
    component: AddSrCancel,
  },
  {
    path: '/sr-cancel-reason/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddSrCancel,
  },
  {
    path: '/sr-status',
    exact: true,
    layout: DefaultLayout,
    component: SrStatus,
  },
  {
    path: '/sr-status/new',
    exact: true,
    layout: DefaultLayout,
    component: AddSrStatus,
  },
  {
    path: '/sr-status/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddSrStatus,
  },
  {
    path: '/quote-status',
    exact: true,
    layout: DefaultLayout,
    component: quoteStatus,
  },
  {
    path: '/quote-status/new',
    exact: true,
    layout: DefaultLayout,
    component: AddquoteStatus,
  },
  {
    path: '/quote-status/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddquoteStatus,
  },
  {
    path: '/charge-factor',
    exact: true,
    layout: DefaultLayout,
    component: CommissionTax,
  },
  {
    path: '/charge-factor/new',
    exact: true,
    layout: DefaultLayout,
    component: AddcommissionTax,
  },
  {
    path: '/charge-factor/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddcommissionTax,
  },
  {
    path: '/components-overview',
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  {
    path: '/service-request',
    exact: true,
    layout: DefaultLayout,
    component: ServiceRequest,
  },
  {
    path: '/service-request/new',
    exact: true,
    layout: DefaultLayout,
    component: AddServiceRequest,
  },
  {
    path: '/service-request/:id/edit',
    exact: true,
    layout: DefaultLayout,
    component: AddServiceRequest,
  },
  {
    path: '/tables',
    layout: DefaultLayout,
    component: Tables,
  },
  {
    path: '/service-request-mgt',
    exact: true,
    layout: DefaultLayout,
    component: ServiceRequestMainSrc,
  },
  {
    path: '/Create-service-request',
    exact: true,
    layout: DefaultLayout,
    component: CreateServiceRequest,
  },
  {
    path: '/edit-service-request/:srId',
    exact: true,
    layout: DefaultLayout,
    component: EditServiceRequest,
  },

  {
    path: '/view-service-request/:srId',
    exact: true,
    layout: DefaultLayout,
    component: ViewServiceReques,
  },
  {
    path: '/quote-management',
    exact: true,
    layout: DefaultLayout,
    component: QuoteManagement,
  },
  {
    path: '/view-quote-details/:quoteId',
    exact: true,
    layout: DefaultLayout,
    component: ViewQuoteDetails,
  },
  {
    path: '/edit-quote/:quoteId',
    exact: true,
    layout: DefaultLayout,
    component: EditQuote,
  },
  {
    path: '/warranty-dispute-mgt',
    exact: true,
    layout: DefaultLayout,
    component: WarrantyAndDisputeMgt,
  },

  {
    path: '/warranty-management',
    exact: true,
    layout: DefaultLayout,
    component: WarrantyMgt,
  },
  {
    path: '/dispute-managemnet',
    exact: true,
    layout: DefaultLayout,
    component: DisputeMgt,
  },
  {
    path: '/coupon-managemnet',
    exact: true,
    layout: DefaultLayout,
    component: CouponManagement,
  },
  {
    path: '/adjustment-management',
    exact: true,
    layout: DefaultLayout,
    component: AdjustmentManagement,
  },
  {
    path: '/create-adjustment',
    exact: true,
    layout: DefaultLayout,
    component: CreateAdjustment,
  },
  {
    path: '/reports-managemnet',
    exact: true,
    layout: DefaultLayout,
    component: ReportsManagement,
  },
  {
    path: '/view-chats',
    exact: true,
    layout: DefaultLayout,
    component: ViewChats,
  },
  {
    path: '/view-comments',
    exact: true,
    layout: DefaultLayout,
    component: ViewComments,
  },
  {
    path: '/warranty-comments',
    exact: true,
    layout: DefaultLayout,
    component: WarrantyComment,
  },
  {
    path: '/dispute-comments',
    exact: true,
    layout: DefaultLayout,
    component: DisputeComment,
  },

  {
    path: '/sub-admins',
    exact: true,
    layout: DefaultLayout,
    component: SubAdmin,
  },
  {
    path: '/sub-admin-add',
    exact: true,
    layout: DefaultLayout,
    component: SubAdminAdd,
  },
  {
    path: '/sub-admin-view/:id',
    exact: true,
    layout: DefaultLayout,
    component: SubAdminView,
  },
  {
    path: '/sub-admin-edit',
    exact: true,
    layout: DefaultLayout,
    component: SubAdminEdit,
  },
  {
    path: '/sub-admin-edit/:id',
    exact: true,
    layout: DefaultLayout,
    component: SubAdminEdit,
  },
  {
    path: '/dispute-reasons',
    exact: true,
    layout: DefaultLayout,
    component: DisputeReason,
  },
  {
    path: '/revisit-reasons',
    exact: true,
    layout: DefaultLayout,
    component: RevisiteReason,
  },
  {
    path: '/sr-disable-reasons',
    exact: true,
    layout: DefaultLayout,
    component: SrDisableReason,
  },
  {
    path: '/quote-disable-reasons',
    exact: true,
    layout: DefaultLayout,
    component: QuoteDisableReason,
  },
  {
    path: '/warranty-closure-reasons',
    exact: true,
    layout: DefaultLayout,
    component: WarrantyClosureReason,
  },
  {
    path: '/view-report',
    exact: true,
    layout: DefaultLayout,
    component: ViewReport,
  },
  {
    path: '/edit-report',
    exact: true,
    layout: DefaultLayout,
    component: EditReport,
  },
  {
    path: '/add-dispute-list',
    exact: true,
    layout: DefaultLayout,
    component: AddDispute,
  },
  {
    path: '/add-revisit-list',
    exact: true,
    layout: DefaultLayout,
    component: AddRevisit,
  },
  {
    path: '/add-srDisable-list',
    exact: true,
    layout: DefaultLayout,
    component: AddSrDisable,
  },
  {
    path: '/add-quote-Disable-list',
    exact: true,
    layout: DefaultLayout,
    component: AddQuoteDisableReason,
  },
  {
    path: '/add-warranty-closure-reasons',
    exact: true,
    layout: DefaultLayout,
    component: AddWarrantyClosureReason,
  },
  {
    path: '/additional',
    exact: true,
    layout: DefaultLayout,
    component: Test,
  },
  {
    path: '/invoice-and-sp-payout/:id',
    exact: true,
    layout: DefaultLayout,
    component: InvoiceSpPayout,
  },
  {
    path: '/view-edit-user/:id',
    exact: true,
    layout: DefaultLayout,
    component: ViewEditUser,
  },
  {
    path: '/cms',
    exact: true,
    layout: DefaultLayout,
    component: ContentManagement,
  },
  {
    layout: LoginLayout,
    component: ErrNotFound,
  },
];

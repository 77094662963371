import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import HowItWorksGifs from './gifs';
import HowItWorksTexts from './texts';
import HowItWorksVideos from './videos';

function HowItWorks(props) {
  const [state, setState] = useState({ gifs: true, texts: false, videos: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ gifs: 'GIFs', texts: 'Texts', videos: 'Videos' }} />
        </ListGroupItem>
      </ListGroup>

      {state.gifs && (
        <HowItWorksGifs isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.texts && (
        <HowItWorksTexts isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.videos && (
        <HowItWorksVideos isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default HowItWorks;

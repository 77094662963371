import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { APIService } from '../../utils/APIService';
import { SelectDate } from '../ServiceRequest/Calender';
import { ListGroupItem, Row, Col, FormInput, Container, Card, CardBody, Button, FormSelect } from 'shards-react';
import { QUOTE_STATUSES } from '../../data/constants';

const EditQuote = (props) => {
  const { quoteId } = useParams();
  const [quoteMgt, setQuoteMgt] = useState({});
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = async () => {
      const response = await APIService.fetchQuoteById(quoteId);
      const quote = response.quote[0] || {};
      const quoteData = {
        quoteId: quote.id,
        srId: quote.request_id,
        srStatus: quote.sr?.srstatus?.name || '',
        custId: quote.user_id,
        custFirstName: quote.user?.first_name || '',
        custLastName: quote.user?.last_name || '',
        spId: quote.provider_id,
        spFirstName: quote.provider?.first_name || '',
        spLastName: quote.provider?.last_name || '',
        quoteStatus: quote.quote_status,
        serviceIncluded: quote.services_included,
        labourQuoteAmount: quote.labor_quote_amount || '0.00',
        materialQuoteAmount: quote.material_quote_amount || '0.00',
        materialIncluded: quote.material_included_flag === 1 ? 'yes' : 'no',
        totalQuoteAmountFromSP: (
          parseFloat(quote.labor_quote_amount || 0) + parseFloat(quote.material_quote_amount || 0)
        ).toFixed(2),
        totalQuoteAmountToCustomer: (
          parseFloat(quote.uplifted_labor_quote_amount || 0) + parseFloat(quote.uplifted_material_quote_amount || 0)
        ).toFixed(2),
        additionalComments: quote.additional_comments,
        quoteServiceDate: quote.quote_service_date.split('T')[0],
        actionComment: quote.quote_status == 6 ? quoteMgt.disable_comments : '',
      };

      setQuoteMgt(quoteData);
    };

    serviceReq();
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setQuoteMgt((prev) => ({ ...prev, [name]: value }));
    setUpdates((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit() {
    APIService.updateQuote(quoteId, updates)
      .then(getService)
      .catch((error) => alert(error.errorMessage || error.message));
  }

  function RadioButton({ name, value, onChange }) {
    return (
      <FormControl component="fieldset">
        <RadioGroup name={name} value={value} row onChange={onChange}>
          <FormControlLabel value="yes" control={<Radio color="primary" />} label="YES" />
          <FormControlLabel value="no" control={<Radio color="primary" />} label="NO" />
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Edit Quote" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push('/quote-management');
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="custId"
                    value={quoteMgt.custId}
                    disabled
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR ID </label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="SR Id"
                    name="srId"
                    value={quoteMgt.srId}
                    disabled
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="custLastName"
                    value={quoteMgt.custLastName}
                    disabled
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="custFirstName"
                    value={quoteMgt.custFirstName}
                    disabled
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Status"
                    name="srStatus"
                    value={quoteMgt.srStatus}
                    disabled
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Service Provider ID</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Service Provider ID"
                    name="spId"
                    value={quoteMgt.spId}
                    disabled
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Quote ID</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Quote ID"
                    name="quoteId"
                    value={quoteMgt.quoteId}
                    disabled
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Service Provider Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Service Provider Last Name"
                    type="text"
                    name="spLastName"
                    value={quoteMgt.spLastName}
                    disabled
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Service Provider First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="Service Provider First Name"
                    name="spFirstName"
                    value={quoteMgt.spFirstName}
                    disabled
                  />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile" className="mb-2 mt-2 mr-2">
                    Material Included
                  </label>
                  <RadioButton name="materialIncluded" value={quoteMgt.materialIncluded} onChange={handleChange} />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feDate">Quote Service Date</label>
                  <Col md={{ size: 4, order: 4 }}>
                    <TextField
                      type="date"
                      name="quoteServiceDate"
                      InputLabelProps={{ shrink: true }}
                      value={quoteMgt.quoteServiceDate}
                      onChange={handleChange}
                    />
                  </Col>
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Labour Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Labour Quote Amount"
                    name="labourQuoteAmount"
                    value={quoteMgt.labourQuoteAmount}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Material Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Material Quote Amount"
                    name="materialQuoteAmount"
                    value={quoteMgt.materialQuoteAmount}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Included</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Included"
                    name="serviceIncluded"
                    value={quoteMgt.serviceIncluded}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feSrStatus">Quote Status</label>
                  <FormSelect id="feState" name="quoteStatus" value={quoteMgt.quoteStatus} onChange={handleChange}>
                    <option value={0}>Choose</option>
                    {Object.entries(QUOTE_STATUSES).map(([name, status]) => (
                      <option value={status}>{name}</option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 5, order: 4 }}>
                  <label>Action Comments</label>
                  <FormInput
                    id="actionComments"
                    placeholder="Action Comments"
                    type="text"
                    name="actionComment"
                    onChange={handleChange}
                    value={quoteMgt.actionComment}
                  />
                </Col>

                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feMobile">Additional Comments </label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Additional Comments"
                    name="additionalComments"
                    value={quoteMgt.additionalComments}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 4, order: 4, offset: 8 }} className="form-group p-3">
                  {/* <Button
                    onClick={() => {
                      props.history.push('');
                    }}
                    outline
                    theme="primary"
                    className="mb-2 mr-2"
                  >
                    Disable Quote
                  </Button> */}
                  {/* <Button
                    onClick={() => {
                      props.history.push('/view-chats');
                    }}
                    outline
                    theme="primary"
                    className="mb-2 ml-2 mr-2"
                  >
                    View Chats
                  </Button> */}
                  <Button outline theme="primary" className="mb-2 ml-2" onClick={handleSubmit}>
                    Update
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              <b>Comments List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Comments Description', field: 'commentDescription' },
                    { title: 'Comment Date and Time', field: 'commentDate' },
                    { title: 'Comment From (Customer or Service Provider ID)', field: 'commentFrom' },
                  ]}
                  data={[]}
                  actions={[]}
                  editable={{
                    onRowAddCancelled: (rowData) => console.log('Row adding cancelled'),
                    onRowUpdateCancelled: (rowData) => console.log('Row editing cancelled'),
                    // onRowAdd: (newData) => new Promise((resolve, reject) => {}),
                    onRowUpdate: (newData, oldData) => {},
                    // APIService.updateServiceRequest(newData)

                    onRowDelete: (oldData) => {},
                    // APIService.fetchServiceRequestDelete(oldData.srId),
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
};
export default EditQuote;

import React, { useEffect, useState } from 'react';
import { Button, Col, Row, ListGroupItem } from 'shards-react';
import config from '../../../data/config';
import { APIService } from '../../../utils/APIService';

function ContentRowImage({ content, getService }) {
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    content && setInitialState();
  }, [content]);

  const setInitialState = () => {
    setState({ ...state, ...content });

    setImage({
      photo_url: config.endpoints.image_path + content.image,
      photo_name: content.image.replace('cms/', ''),
    });

    setEdit(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setState({ ...state, [name]: value });
    setUpdates({ ...updates, [name]: value });
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    const newImg = new Image();

    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      newImg.src = reader.result;
      newImg.onload = () => {
        let validDimensions = true;
        console.log(newImg.naturalWidth, newImg.naturalHeight);
        if (state.allowed_img_height && state.allowed_img_width) {
          if (state.allowed_img_height != newImg.naturalHeight || state.allowed_img_width != newImg.naturalWidth) {
            alert(`Please select image of dimension ${state.allowed_img_width} X ${state.allowed_img_height} only`);
            validDimensions = false;
          }
        }
        validDimensions && setImage({ photo: image, photo_name: image.name, photo_url: reader.result });
      };
    };
  };

  const handleSubmit = () => {
    if (state.video_src) {
      let url;
      let isValidVideoSrc = true;

      try {
        url = new URL(state.video_src);
      } catch (_) {
        isValidVideoSrc = false;
      } finally {
        isValidVideoSrc = isValidVideoSrc && (url.protocol === 'http:' || url.protocol === 'https:');

        if (!isValidVideoSrc) return alert('Video src is not valid');
      }
    }
    setLoading(true);
    APIService.updateCmsContent(state.id, { ...updates, image: image.photo })
      .then(getService)
      .finally(() => setLoading(false));
  };

  return (
    <ListGroupItem>
      <Row>
        <Col md={{ size: 4 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!!image.photo_url && <img src={image.photo_url} width="100%" id="img_prev" alt="herography" />}
        </Col>
        <Col md={{ size: 8 }} className="form-group mt-3">
          {state.allowed_img_width && state.allowed_img_height && (
            <div>
              <span style={{ fontWeight: 'bold' }}>Allowed dimension: </span>
              {state.allowed_img_width} X {state.allowed_img_height}
            </div>
          )}

          <div className="mt-3 mb-3">
            <div>
              <span style={{ fontWeight: 'bold' }}>Image: </span>
              {edit ? (
                <input
                  style={{ display: 'inline-block' }}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  disabled={!edit}
                  onChange={handleChangeImage}
                />
              ) : (
                <span>{image.photo_name}</span>
              )}
            </div>
          </div>

          <div>
            <span style={{ fontWeight: 'bold' }}>Video src: </span>
            {edit ? (
              <input
                type="text"
                name="video_src"
                style={{ width: '50%' }}
                onChange={handleChange}
                placeholder="Enter video url"
                value={state.video_src || ''}
              />
            ) : (
              <a href={state.video_src || ''} target="_blank" rel="noopener noreferrer">
                {state?.video_src || ''}
              </a>
            )}
          </div>

          <Col md={{ size: 8, order: 4, offset: 8 }} className="form-group mt-4">
            {edit && (
              <>
                <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={handleSubmit}>
                  {loading ? <span class="spinner-border" /> : 'Save'}
                </Button>
                {!loading && (
                  <Button outline theme="danger" className="mb-2 mr-1" onClick={setInitialState}>
                    Cancel
                  </Button>
                )}
              </>
            )}
            {!edit && (
              <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={setEdit}>
                {loading ? <span class="spinner-border" /> : 'Edit'}
              </Button>
            )}
          </Col>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export default ContentRowImage;

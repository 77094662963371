import React from "react";
import {
  Button
} from "shards-react";

import { Redirect } from "react-router-dom";

class Navigate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            state: this.state.redirectData,
          }}
        />
      );
    }

    return (
      <>
        <Button theme="primary" className="mb-2 mr-1"
          outline
         theme="primary"
         className="mb-2 mr-1"
        
        // onClick={() =>
        //   this.setState({
        //     redirect: true,
        //     redirectPath: "/data-set",
        //   })
        // }

        onClick={() =>
          { window.location.href="/data-set"}
          
         }
        >
          House Type
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
        

          onClick={() =>
           { window.location.href="/time-slots"}
           
          }
        >
          Time Slots
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
      
          onClick={() =>
            // this.setState({
            //   redirect: true,
            //   redirectPath: "/quote-withdrawal-reasons",
            // })
            window.location.href="/quote-withdrawal-reasons"
          }
          
        >
          Quote withdrawal reason
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
          // onClick={() =>
          //   this.setState({
          //     redirect: true,
          //     redirectPath: "/quote-rejection-reasons",
          //   })
          // }
          onClick={() =>{
            window.location.href="/quote-rejection-reasons"
          }
           
            }
        >
          Quote rejection reasons
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
          onClick={() =>{
            window.location.href="/sr-cancel-reasons"
            }
          }

          
        >
          SR cancel reasons
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
        

          onClick={() =>{
            window.location.href="/dispute-reasons"
            }
          }

        >
          Dispute Reasons
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
          
          onClick={() =>{
            window.location.href="/revisit-reasons"
            }
          }
        >
          Revisit Reasons
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
         
          onClick={() =>{
            window.location.href="/sr-disable-reasons"
            }
          }
          
        >
          SR Disable Reasons
        </Button>
        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
       
          onClick={() =>{
            window.location.href="/quote-disable-reasons"
            }
          }
        >
          Quote Disable Reasons
        </Button>

        <Button
          outline
          theme="primary"
          className="mb-2 mr-1"
       

          onClick={() =>
            this.setState({
              redirect: true,
              redirectPath: "/house-type/new",
            })
          }
          
        >
          Add House Type
        </Button>
      </>
    );
  }
}

export default Navigate;


import React from 'react';
import Button from '@material-ui/core/Button';
import { Row } from 'shards-react';

export default function CustBotton(props) {
  const { state, setState, buttons, vertical } = props;

  function SingleButton({ btnKey, btnTxt }) {
    return (
      <>
        <Button
          variant="contained"
          color="green"
          className="ml-3 mr-3"
          style={{
            backgroundColor: state[btnKey] ? 'orange' : 'grey',
            color: 'white',
            margin: '10px',
            outline: 'none',
            textTransform: 'none',
          }}
          onClick={() => {
            const updatedState = {};

            Object.keys(buttons).forEach((btn) => {
              updatedState[btn] = btnKey === btn;
            });

            setState(updatedState);
          }}
        >
          {btnTxt}
        </Button>
      </>
    );
  }

  return (
    <div>
      {buttons &&
        Object.entries(buttons).map(([btnKey, btnTxt]) => (
          <>
            {vertical ? (
              <Row>
                <SingleButton btnKey={btnKey} btnTxt={btnTxt} />
              </Row>
            ) : (
              <SingleButton btnKey={btnKey} btnTxt={btnTxt} />
            )}
          </>
        ))}
    </div>
  );
}

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Container, ListGroupItem, Row, Col, Button, Form, FormInput, Alert, FormSelect } from 'shards-react';
import config from '../../../../data/config';
import { APIService } from '../../../../utils/APIService';

function ProfileInfo(props) {
  const [stateCounter, setStateCounter] = useState(0);
  const [stateChanged, setStateChanged] = useState({ category: 0, subcategory: 0, serviceState: 0, location: 0 });
  const [state, setState] = useState({
    checkedA: true,
    categories: [],
    subCategories: [],
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });
  const [selected, setSelected] = useState({
    category: [],
    subcategory: [],
    serviceState: [],
    location: [],
  });
  const [image, setImage] = useState({
    insurance_image: null,
    insurance_image_url: '',
    insurance_image_name: '',
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    states: [],
    serviceCategory: [],
    serviceSubCategory: [],
    serviceState: [],
    serviceLocation: [],
  });
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    getService();
  }, []);

  const getService = () => {
    setLoading(true);

    APIService.fetchSpProfile(props.userId)
      .then((resp) => {
        if (resp && resp.data) {
          setData({
            ...resp.data,
            insurance_expiry_date: resp.data.insurance_expiry_date?.split('T')[0],
          });

          setImage({
            ...image,
            insurance_image_name: resp.data.insurance_image,
            insurance_image_url: config.endpoints.image_path + resp.data.insurance_image,
          });

          setSelected({
            category:
              resp.data.serviceCategory?.map((obj) => ({
                value: obj.id,
                label: obj.category_name,
              })) || [],
            subcategory:
              resp.data.serviceSubCategory?.map((obj) => ({
                value: `subcatId:${obj.id}-catId:${obj.category_id}`,
                label: obj.sub_category_name,
              })) || [],
            serviceState:
              resp.data.serviceState?.map((obj) => ({
                value: obj.id,
                label: obj.name,
              })) || [],
            location:
              resp.data.serviceLocation?.map((obj) => ({
                value: `cityId:${obj.id}-stateId:${obj.state_id}`,
                label: obj.name,
              })) || [],
          });

          setStateCounter(stateCounter + 1);
          fetchCountryData();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (stateCounter) {
      fetchStateData();
      fetchAllCategories();
    }
  }, [stateCounter]);

  useEffect(() => {
    fetchSubCategory();
  }, [stateChanged.category]);

  useEffect(() => {
    fetchCityData();
  }, [stateChanged.serviceState]);

  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        setState((prevState) => ({ ...prevState, countries: units }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchSubCategory = (selectedCategories) => {
    const selectedCategoryIds = (selectedCategories || selected.category).map((obj) => parseInt(obj.value));
    let subCategories = [];

    state.categories.forEach((category) => {
      if (selectedCategoryIds.includes(parseInt(category.id)))
        subCategories = [...subCategories, ...category.subcategories];
    });

    setState((prevState) => ({ ...prevState, subCategories }));
  };

  const fetchStateData = () => {
    let user = window.localStorage.getItem('user');
    user = user ? JSON.parse(user) : {};

    return APIService.fetchState(user.country).then(
      (units) => {
        setState((prevState) => ({ ...prevState, states: units }));
        setStateChanged({ ...stateChanged, serviceState: stateChanged.category + 1 });
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    if (selected.serviceState && selected.serviceState.length) {
      const selectedStates = selected.serviceState.map((obj) => obj.value).join(',');

      return APIService.fetchCity(selectedStates).then(
        (units) => {
          setState((prevState) => ({ ...prevState, cities: units }));
        },
        (error) => setState({ internetConnected: false })
      );
    }
  };

  const fetchAllCategories = () => {
    return APIService.fetchAllCategories().then(
      (units) => {
        fetchSubCategory();

        setState((prevState) => ({ ...prevState, categories: units.data || [] }));
        setStateChanged({ ...stateChanged, category: stateChanged.category + 1 });
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    setData({ ...data, [target.name]: target.value });
    setUpdates({ ...updates, [target.name]: target.value });
  };

  const handleChangeMulti = (selections, name) => {
    selected[name] = selections;
    stateChanged[name] = stateChanged[name] + 1;

    switch (name) {
      case 'category':
        const selectedCategoryIds = selections.map((obj) => parseInt(obj.value));
        updates.category = JSON.stringify(selectedCategoryIds);
        selected.subcategory = selected.subcategory.filter((obj) =>
          selectedCategoryIds.includes(parseInt(obj.value.split('-')[1].split(':')[1]))
        );
        updates.subcategory = JSON.stringify(
          selected.subcategory.map((obj) => parseInt(obj.value.split('-')[0].split(':')[1]))
        );
        break;

      case 'subcategory':
        updates.subcategory = JSON.stringify(selections.map((obj) => parseInt(obj.value.split('-')[0].split(':')[1])));
        break;

      case 'serviceState':
        const selectedStateIds = selections.map((obj) => parseInt(obj.value));
        updates.serviceState = JSON.stringify(selectedStateIds);
        selected.location = selected.location.filter((obj) =>
          selectedStateIds.includes(parseInt(obj.value.split('-')[1].split(':')[1]))
        );
        updates.location = JSON.stringify(
          selected.location.map((obj) => parseInt(obj.value.split('-')[0].split(':')[1]))
        );
        break;

      case 'location':
        updates.location = JSON.stringify(selections.map((obj) => parseInt(obj.value.split('-')[0].split(':')[1])));
        break;

      default:
        break;
    }

    setSelected({ ...selected });
    setUpdates({ ...updates });
    setStateChanged({ ...stateChanged });
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      setImage({
        insurance_image: image,
        insurance_image_name: image.name,
        insurance_image_url: reader.result,
        image_updated: 'true',
      });
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    APIService.updateSpProfile(props.userId, {
      ...updates,
      insurance_image: image.insurance_image,
      image_updated: image.image_updated,
    })
      .then(
        (unit) => {
          setState({
            ...state,
            success: true,
            loading: false,
            redirect: true,
            redirectPath: '/users',
            redirectData: {
              visible: true,
              alertStyle: 'success',
              alertIcon: 'fa fa-check mx-2',
              alertMessage: 'Business license updated successfully.',
            },
          });
        },
        (error) => {
          setState({
            ...state,
            success: false,
            loading: false,
            visible: true,
            alertStyle: 'danger',
            alertIcon: 'fa fa-exclamation mx-2',
            alertMessage: error.message,
          });
        }
      )
      .finally(() => setLoading(false));
  };

  const dismissAlert = () => {
    setState({ ...state, visible: false });
  };

  function RadioButton({ name, value, onChange, disabled }) {
    return (
      <FormControl component="fieldset" disabled={disabled}>
        <RadioGroup name={name} value={value} row onChange={onChange}>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="YES" />
          <FormControlLabel value="0" control={<Radio color="primary" />} label="NO" />
        </RadioGroup>
      </FormControl>
    );
  }

  if (state.redirectPath) return <Redirect to={{ pathname: state.redirectPath, state: state.redirectData }} />;

  return (
    <>
      {state.visible && (
        <Container fluid className="px-0">
          <Alert theme={state.alertStyle || 'primary'} dismissible={dismissAlert} open={state.visible} className="mb-0">
            <i className={state.alertIcon} /> {state.alertMessage}
          </Alert>
        </Container>
      )}
      <ListGroupItem>
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label>
                Select Categories <i>(Multiple categories allowed)</i>
              </label>
              <Select
                name="category"
                placeholder="Select all service categories that apply"
                onChange={(selected) => handleChangeMulti(selected, 'category')}
                value={selected.category}
                options={state.categories.map((obj) => ({
                  value: obj.id,
                  label: obj.category_name,
                }))}
                isMulti
                isDisabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label>
                Select Sub-Categories <i>(Multiple sub-categories allowed)</i>
              </label>
              <Select
                name="subcategory"
                placeholder="Select all service sub categories that apply"
                onChange={(selected) => handleChangeMulti(selected, 'subcategory')}
                value={selected.subcategory}
                options={state.subCategories.map((obj) => ({
                  value: `subcatId:${obj.id}-catId:${obj.category_id}`,
                  label: obj.sub_category_name,
                }))}
                isMulti
                isDisabled={loading || !props.isEdit}
              />
            </Col>
          </Row>

          <Row form>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label>
                Select Service State <i>(Multiple states allowed)</i>
              </label>
              <Select
                name="serviceState"
                placeholder="Select all service states that apply"
                onChange={(selected) => handleChangeMulti(selected, 'serviceState')}
                value={selected.serviceState}
                options={state.states.map((obj) => ({
                  value: obj.id,
                  label: obj.name,
                }))}
                isMulti
                isDisabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label>
                Select Location <i>(Multiple locations allowed)</i>
              </label>
              <Select
                name="location"
                placeholder="Select all service locations that apply"
                onChange={(selected) => handleChangeMulti(selected, 'location')}
                value={selected.location}
                options={state.cities.map((obj) => ({
                  value: `cityId:${obj.id}-stateId:${obj.state_id}`,
                  label: obj.name,
                }))}
                isMulti
                isDisabled={loading || !props.isEdit}
              />
            </Col>
          </Row>

          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>Select Experience</label>
              <FormSelect
                name="year_experience"
                onChange={handleChange}
                required
                disabled={loading || !props.isEdit}
                value={data.year_experience}
              >
                <option>Years of experience</option>
                <option value={0}>No Exp</option>
                <option value={1}>1 Year</option>
                <option value={2}>2 Years</option>
                <option value={3}>3 Years</option>
                <option value={4}>4 Years</option>
              </FormSelect>
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>Select Warranty</label>
              <FormSelect
                name="warranty"
                placeholder="Labor warranty provided (Days)"
                value={data.warranty}
                disabled={loading || !props.isEdit}
              >
                <option>Labor warranty provided (Days)</option>
                {[0, 30, 45, 60, 90].map((val) => (
                  <option value={val}>{val}</option>
                ))}
              </FormSelect>
            </Col>
          </Row>

          <Row form>
            <Col>
              <label htmlFor="feMobile" className="mb-2 mt-2 mr-2">
                Can you provide professional phone consultation?
              </label>
              <RadioButton
                name="phone_consultation"
                value={String(data.phone_consultation)}
                onChange={handleChange}
                disabled={loading || !props.isEdit}
              />
            </Col>
          </Row>

          <Row form>
            <Col>
              <label htmlFor="feMobile" className="mb-2 mt-2 mr-2">
                Are you bonded insured?
              </label>
              <RadioButton
                name="bonded_insured"
                value={String(data.bonded_insured)}
                onChange={handleChange}
                disabled={loading || !props.isEdit}
              />
            </Col>
          </Row>

          {data.bonded_insured == 1 && (
            <>
              <Row form>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label>Provider Name</label>
                  <FormInput
                    placeholder="Provider Name"
                    type="text"
                    name="insurance_provider_name"
                    required
                    onChange={handleChange}
                    value={data.insurance_provider_name}
                    disabled={loading || !props.isEdit}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label>Expiry Date</label>
                  <FormInput
                    placeholder="Expiry Date"
                    type="date"
                    name="insurance_expiry_date"
                    onChange={handleChange}
                    value={data.insurance_expiry_date}
                    disabled={loading || !props.isEdit}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label>Policy Number</label>
                  <FormInput
                    type="number"
                    placeholder="Policy Number"
                    name="insurance_policy"
                    required
                    onChange={handleChange}
                    value={data.insurance_policy}
                    disabled={loading || !props.isEdit}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label>Insurer's Name and Full Address</label>
                  <FormInput
                    placeholder="Insurer's Name and Full Address"
                    type="text"
                    name="insured_name_address"
                    required
                    onChange={handleChange}
                    value={data.insured_name_address}
                    disabled={loading || !props.isEdit}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label htmlFor="feState">Issuing Country</label>
                  <FormSelect
                    name="insured_country"
                    onChange={handleChange}
                    required
                    disabled={loading || !props.isEdit}
                  >
                    <option value={0}>Issuing Country</option>
                    {state.countries.map((elem, i) => (
                      <option value={elem.id} key={i} selected={data.insured_country == elem.id}>
                        {elem.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label>Phone Number</label>
                  <FormInput
                    type="number"
                    placeholder="Phone Number"
                    name="insured_mobile_number"
                    required
                    onChange={handleChange}
                    value={data.insured_mobile_number}
                    disabled={loading || !props.isEdit}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={{ size: 4, order: 4 }} className="form-group mt-3">
                  <label>Insurance Image</label>
                  <div className="custom-file mb-3">
                    <input
                      type="file"
                      className="custom-file-input"
                      name="insurance_image"
                      accept="image/*"
                      onChange={handleChangeImage}
                      disabled={loading || !props.isEdit}
                    />
                    <label className="custom-file-label" htmlFor="profile_image">
                      {image.insurance_image_name || 'Choose Image...'}
                    </label>
                  </div>
                </Col>
                <Col
                  md={{ size: 2, order: 4 }}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {!!image.insurance_image_url && (
                    <img src={image.insurance_image_url} width="100%" id="profile_img_prev" alt="profile" />
                  )}
                </Col>
              </Row>
            </>
          )}

          <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group ">
            {props.isEdit && (
              <Button outline type="submit" theme="primary" className="mb-2 mr-1" disabled={loading}>
                {loading ? <span class="spinner-border" /> : 'Save'}
              </Button>
            )}
            {!props.isEdit && (
              <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={props.setEdit}>
                {loading ? <span class="spinner-border" /> : 'Edit'}
              </Button>
            )}
          </Col>
        </Form>
      </ListGroupItem>
    </>
  );
}

export default ProfileInfo;

import React from 'react';
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react';

function Appliances({ handleChange, values }) {
  return (
    <>
      <ListGroupItem>
        <Row className="px-3 mb-3">
          <Col md={{ size: 4, order: 3 }}>
            <label htmlFor="feFirstName">Make</label>
            <FormInput
              id="feMake"
              type="text"
              placeholder="Make"
              name="appliance_make"
              onChange={handleChange}
              value={values.appliance_make}
            />
          </Col>
          <Col md={{ size: 4, order: 3 }}>
            <label htmlFor="feFirstName">Modal</label>
            <FormInput
              id="feModal"
              type="text"
              placeholder="Modal"
              name="appliance_model"
              onChange={handleChange}
              value={values.appliance_model}
            />
          </Col>

          <Col md={{ size: 4, order: 4 }}>
            <label>Year of the Appliance*</label>
            <FormSelect
              id="feappliance"
              name="appliance_year"
              required
              onChange={handleChange}
              value={values.appliance_year}
            >
              <option>Year of the Appliance*</option>
              {Array(41)
                .fill()
                .map((_, index) => (
                  <option>{2010 + index}</option>
                ))}
            </FormSelect>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
}

function MovingService() {
  return (
    <>
      <ListGroupItem>
        <Row className="px-3 mb-3">
          <Col md={{ size: 4, order: 4 }}>
            <label>Type of House*</label>
            <FormSelect id="feHouse" name="house" required>
              <option>Type of House*</option>
              <option>Type 1</option>
              <option>Type 2</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 4, order: 4 }}>
            <label>No. of Beds*</label>
            <FormSelect id="feBeds" name="beds" required>
              <option>No. of Beds*</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5+</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 4, order: 4 }}>
            <label>No. of Baths*</label>
            <FormSelect id="feBaths" name="baths" required>
              <option>No. of Baths*</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4+</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 4, order: 4 }}>
            <label>No. of Level*</label>
            <FormSelect id="feLevel" name="level" required>
              <option>No. of Level*</option>
              <option>1</option>
              <option>2</option>
              <option>3+</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 4, order: 4 }}>
            <label>Flooring Type</label>
            <FormSelect id="feFlooring" name="flooring" required>
              <option>Flooring Type*</option>
              <option>Type 1</option>
              <option>Type 2</option>
            </FormSelect>
          </Col>

          <Col md={{ size: 4, order: 4 }}>
            <label htmlFor="fesq_ft">Sq. Ft</label>
            <FormInput id="fesq_ft" type="number" placeholder="Sq. Ft" name="sq_ft" />
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
}

function AutoService() {
  return (
    <>
      <ListGroupItem>
        <Row className="px-3 mb-3">
          <Col md={{ size: 4, order: 3 }}>
            <label>Auto Type</label>
            <FormSelect id="feAutoType" name="autoType" required>
              <option>Auto Type*</option>
              <option>Type 1</option>
              <option>Type 2</option>
            </FormSelect>
          </Col>

          <Col md={{ size: 4, order: 3 }}>
            <label htmlFor="feFirstName">Make</label>
            <FormInput id="feMake" type="text" placeholder="Make" name="make" />
          </Col>
          <Col md={{ size: 4, order: 3 }}>
            <label htmlFor="feModal">Modal</label>
            <FormInput id="feModal" type="text" placeholder="Modal" name="modal" />
          </Col>
          <Col md={{ size: 4, order: 3 }}>
            <label>Year of the Auto</label>
            <FormSelect id="feyoAuto" name="yearAuto" required>
              <option>Year of the Auto*</option>
              <option>1990</option>
              <option>1991</option>
              <option>1992</option>
              <option>1993</option>
              <option>1994</option>
              <option>1995</option>
              <option>1996</option>
              <option>1997</option>
              <option>1998</option>
              <option>1999</option>
              <option>2000</option>
              <option>2001</option>
              <option>2002</option>
              <option>2003</option>
              <option>2004</option>
              <option>2005</option>
              <option>2006</option>
              <option>2007</option>
              <option>2008</option>
              <option>2009</option>
              <option>2010</option>
              <option>2011</option>
              <option>2012</option>
              <option>2013</option>
              <option>2014</option>
              <option>2015</option>
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
              <option>2025</option>
              <option>2026</option>
              <option>2027</option>
              <option>2028</option>
              <option>2029</option>
              <option>2030</option>
              <option>2031</option>
              <option>2032</option>
              <option>2033</option>
              <option>2034</option>
              <option>2035</option>
              <option>2036</option>
              <option>2037</option>
              <option>2038</option>
              <option>2039</option>
              <option>2040</option>
              <option>2041</option>
              <option>2042</option>
              <option>2043</option>
              <option>2044</option>
              <option>2045</option>
              <option>2046</option>
              <option>2047</option>
              <option>2048</option>
              <option>2049</option>
              <option>2050</option>
            </FormSelect>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
}

function FoodService() {
  return (
    <>
      <ListGroupItem>
        <Row className="px-3 mb-3">
          <Col md={{ size: 4, order: 4 }}>
            <label>Delivery by the Service Provider*</label>
            <FormSelect id="feFlooring" name="flooring">
              <option>Type*</option>
              <option>Carry Out</option>
              <option>Delivery by the Service Provider</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 4, order: 3 }}>
            <label htmlFor="fePeople">No. of People</label>
            <FormInput id="fePeople" type="number" placeholder="No. of People" name="people" />
          </Col>
          <Col md={{ size: 4, order: 4 }}>
            <label>Spicy Level*</label>
            <FormSelect id="feFlooring" name="flooring">
              <option>Spicy Level*</option>
              <option>Hot</option>
              <option>Medium</option>
              <option>Mild</option>
            </FormSelect>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
}

function LegalService() {
  return (
    <>
      <ListGroupItem>
        <Row className="px-3 mb-3">
          <Col md={{ size: 6, order: 4 }}>
            <label>Phone Consultation*</label>
            <FormSelect id="feFlooring" name="flooring">
              <option>Phone Consultation*</option>
              <option>30 mins</option>
              <option>45 mins</option>
              <option>60 mins</option>
            </FormSelect>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
}

export default Appliances;
export { MovingService, AutoService, FoodService, LegalService };

import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import FaqTexts from './texts';

function Faq(props) {
  const [state, setState] = useState({ texts: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ texts: 'Texts' }} />
        </ListGroupItem>
      </ListGroup>

      {state.texts && (
        <FaqTexts isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default Faq;

import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { APIService } from '../../../utils/APIService';
import PeoplesVoiceRow from './PeopleVoiceRow';
import AddPeopleVoice from './AddPeopleVoice';
import { Button, ListGroupItem } from 'shards-react';

function PeoplesVoiceVideos() {
  const [state, setState] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setState();
    APIService.getPeopleVoices().then((resp) => {
      // console.log(resp)
      if (typeof resp === 'object' && Array.isArray(resp.data)) {
        setState({ ...state, data: resp.data });
      }
    });
  }

  const handleAdd = () => {
    setAddModalOpen(!addModalOpen);
  };

  if (!state) return <Loader />;

  return (
    <>
      <ListGroupItem className="text-md-right">
        <Button outline theme="primary" onClick={handleAdd}>
          Add New
        </Button>
      </ListGroupItem>

      <AddPeopleVoice modalOpen={addModalOpen} setModalOpen={setAddModalOpen} getService={getService} />
      {state.data.map((elem) =>
        elem.image_thumbnail ? <PeoplesVoiceRow key={elem.id} content={elem} getService={getService} /> : <></>
      )}
    </>
  );
}

export default PeoplesVoiceVideos;

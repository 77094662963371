import React, { useState, useEffect } from 'react';
import { APIService } from '../../utils/APIService';
import Calender from './Calender';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { USER_STATUS, SR_STATUSES } from '../../data/constants';
import { dateWithFormate } from '../../Utility/common';

import { ListGroupItem, Row, Col, FormInput, FormSelect, Container, Card, CardBody, Button } from 'shards-react';

const ServiceRequestMainSrc = (props) => {
  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });
  const [loading, setloading] = useState(false);
  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    fetchCountryData();

    APIService.fetchAllCategories()
      .then((res) => {
        setServiceCats(res.data);
      })
      .catch((err) => {});

    APIService.fetchAllSubCategories()
      .then((res) => {
        setServiceSubCats(res);
      })
      .catch((err) => {});

    APIService.fetchAllTimeSlots()
      .then((res) => setSlots(res))
      .catch((err) => {});
  }, []);

  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const [srRequest, setSrRequest] = useState([]);

  useEffect(() => {
    getService();
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0)

  //   console.log("changes")
  // },[])

  async function getService() {
    setloading(true);
    const response = await APIService.fetchServiceRequest({ queryParams: filterData });
    setSrRequest(
      response &&
        response.map((item) => {
          return {
            srId: item.id,
            // srCreationDate: item.created_at.split(" ")[0],
            srCreationDate: item?.created_at
              ? dateWithFormate(item?.created_at.split(' ')[0].split('-').reverse().join('-'))
              : '',
            // srScheduledDate: item.service_date,
            srScheduledDate: item?.service_date ? dateWithFormate(item?.service_date) : '',
            userFirstName: item.user.first_name,
            userLastName: item.user.last_name,
            userEmailId: item.user.email,
            userPhone: item.user.phone,
            customerUserId: item.user.id,
            // serviceProviderId: item.quote[0] && item.quote[0].provider_id,
            State: item.cityGet.states.name,
            city: item.cityGet.name,
            srStatus: item.srstatus?.name,
            userType: item.user.user_roles.name,
            status: USER_STATUS[parseInt(item.status)] || '',
          };
        })
    );
    setloading(false);
  }
  // const handleView = (event, data) => {
  //   console.log(data);
  //   console.log(event);
  //   props.history.push({
  //     pathname: "/view-service-request/" + data.srId,

  //     state: { sr: data },
  //   });
  // };

  const [filterData, setFilterData] = useState({});

  const FilterDataSet = (e) => {
    const { name, value } = e.currentTarget;

    if (name === 'categoryId') filterData.sub_category_id = '';

    setFilterData({ ...filterData, [name]: value });
  };

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Service Request Management" />
        <Row>
          <Col lg="12" className="mb-4">
            {/* <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/Create-service-request");
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Create Service Request
              </Button>
            </ContentHeader> */}

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser" name="role_type" onChange={(e) => FilterDataSet(e)}>
                    <option value="">Choose</option>
                    <option value="1">Customer</option>
                    <option value="3">Both</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile</label>
                  <FormInput
                    id="feMobile"
                    type="number"
                    placeholder="Mobile"
                    name="mobile"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput
                    id="feEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Customer Id"
                    name="user_id"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory" name="category_id" onChange={(e) => FilterDataSet(e)}>
                    <option value="">Select the service category *</option>
                    {serviceCats.map((sc) => (
                      <option value={sc.id}>{sc.category_name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect
                    id="feUser"
                    name="sub_category_id"
                    onChange={(e) => FilterDataSet(e)}
                    value={filterData.sub_category_id}
                  >
                    <option>Choose</option>
                    {serviceSubCats
                      .filter((sc) => sc.category_id == filterData.category_id)
                      .map((sc) => (
                        <option value={sc.id}>{sc.sub_category_name}</option>
                      ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID </label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Service Request ID"
                    name="request_id"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 2</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 2"
                    name="address_2"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label htmlFor="feCountry">Country</label>
                  <FormSelect
                    id="feCountry"
                    name="country_id"
                    required
                    onChange={(e) => {
                      FilterDataSet(e);
                      fetchStateData(e.target.value);
                    }}
                  >
                    <option value={0}>country</option>
                    {state.countries.map((country, i) => (
                      <option value={country.id} key={i} selected={state.country == country.id}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label htmlFor="feState"> State</label>
                  <FormSelect
                    id="feState"
                    // onChange={(e) => fetchCityData(e.target.value)}
                    required
                    onChange={(e) => {
                      FilterDataSet(e);
                      fetchCityData(e.target.value);
                    }}
                    name="state_id"
                  >
                    <option value={0}>state</option>
                    {state.states.map((selectedState, i) => (
                      <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                        {selectedState.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormSelect
                    id="feCity"
                    value={state.city}
                    // onChange={(e) => setState({ ...state, city: e.target.value })}
                    required
                    onChange={(e) => FilterDataSet(e)}
                    name="city_id"
                  >
                    <option value={0}>city</option>
                    {state.cities.map((city, i) => (
                      <option value={city.id} key={i} selected={state.city == city.id}>
                        {city.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                    onChange={(e) => FilterDataSet(e)}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SR Status</label>
                  <FormSelect id="feSrStatus" onChange={(e) => FilterDataSet(e)} name="request_status">
                    <option>Choose</option>
                    {Object.entries(SR_STATUSES).map(([key, val]) => (
                      <option value={val}>{key}</option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label>Time Slots</label>
                  <FormSelect id="timeSlot" name="time_slot" onChange={(e) => FilterDataSet(e)}>
                    <option value="">Select Time *</option>
                    {slots.map((slot) => (
                      <option value={slot.id}>
                        {dateWithFormate(slot.start_time, 'hA', 'HH:mm:ss')} -{' '}
                        {dateWithFormate(slot.end_time, 'hA', 'HH:mm:ss')}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/* <Col
                  md={{ size: 4, order: 4 }}
                  className="form-group p-3"
                ></Col> */}
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Creation Date Range</label>
                  <Calender onChange={(e) => FilterDataSet(e)} name="created_at" />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Scheduled Date Range</label>
                  <Calender onChange={(e) => FilterDataSet(e)} name="service_date" />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button
                    outline
                    theme="primary"
                    className="mb-2 mr-4"
                    onClick={getService}
                    disabled={!Object.keys(filterData).length}
                  >
                    Apply Filter
                  </Button>
                  {!!Object.keys(filterData).length && (
                    <Button
                      outline
                      theme="danger"
                      className="mb-2 mr-1"
                      onClick={() => {
                        window.location.reload();
                      }}
                      disabled={!Object.keys(filterData).length}
                    >
                      Clear Filter
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              <b>Service Request List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  isLoading={loading}
                  title=""
                  columns={[
                    { title: 'SR ID', field: 'srId' },
                    { title: 'SR Creation Date', field: 'srCreationDate' },
                    { title: 'SR Scheduled Date', field: 'srScheduledDate' },
                    { title: 'First Name', field: 'userFirstName' },
                    { title: 'Last Name', field: 'userLastName' },
                    { title: 'Email ID', field: 'userEmailId' },
                    { title: 'Mobile Phone', field: 'userPhone' },
                    { title: 'Customer User ID', field: 'customerUserId' },
                    // {
                    //   title: "Service Provider ID",
                    //   field: "serviceProviderId",
                    // },
                    { title: 'State', field: 'State' },
                    { title: 'City', field: 'city' },
                    { title: 'SR Status', field: 'srStatus' },
                    { title: 'Status', field: 'status' },
                    { title: 'User Type', field: 'userType' },
                  ]}
                  data={srRequest}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    pageSizeOptions: [20],
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                  }}
                  actions={[
                    {
                      icon: 'assignment',
                      tooltip: 'Invoice',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push({
                          pathname: '/invoice-and-sp-payout/' + data.srId,
                          state: { isInvoice: true },
                        });
                      },
                    },
                    {
                      icon: 'assessment',
                      tooltip: 'SP Payout',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/invoice-and-sp-payout/' + data.srId);
                      },
                    },
                    {
                      icon: 'visibility',
                      tooltip: 'Request Details',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/view-service-request/' + data.srId);
                      },
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Request Edit',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/edit-service-request/' + data.srId);
                      },
                    },
                  ]}
                  // localization={{
                  //   body: { editRow: { deleteText: `Are you sure you want to activate/deactivate this SR?` } },
                  // }}
                  // editable={{
                  //   onRowDelete: (oldData) =>
                  //     new Promise((resolve) => {
                  //       APIService.srActivateDeactivate(oldData.srId).then(getService).then(resolve);
                  //     }),
                  // }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ServiceRequestMainSrc;

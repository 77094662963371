import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentHeader from '../../components/common/ContentHeader';
import TextField from '@material-ui/core/TextField';
import MainTitle from '../../components/common/MainTitle';
import CustBotton, { ButtonClick } from './CustButton';
import { APIService } from '../../utils/APIService';
import RadioButton, { RadioButtonDis } from '../ServiceRequest/RadioButton';
import ViewQuotesList from '../ViewList/ViewQuotesList';
import ViewWarrantyRequestList from '../ViewList/ViewWarrantyRequestList';
import ViewDisputeRequestList from '../ViewList/ViewDisputeRequestList';
import PhotoUploads, { PhotoUploadView } from '../Uploads/PhotoUploads';
import VideoUploads from '../Uploads/VideoUploads';

import { dateWithFormate } from '../../Utility/common';

import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from 'shards-react';
import { useHistory, useParams } from 'react-router-dom';
import { ViewPhotoImageUpload } from './CreateServiceRequest';
import config from '../../data/config';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ViewServiceRequest = (props) => {
  const [viewImagesUrl, setViewImagesUrl] = useState({});
  const { srId } = useParams();
  const [state, setState] = useState({
    viewQuotesList: true,
    ViewWarrantyRequestList: false,
    ViewDisputeRequestList: false,
  });

  console.log(props.location.state);
  const [sr, setSr] = useState({ files: [] });

  useEffect(() => {
    getSr();
  }, []);
  function getSr() {
    APIService.fetchServiceRequestById(srId).then((response) => {
      console.log(response[0]);
      setSr(response[0]);
      var arr = response[0].service_date.split('-');
      const month = arr.splice(1, 1);
      arr.unshift(month);
      const date = arr.join('-');
      // setSr({ ...sr, service_date: date })

      const fetchedImageUrls = {};
      response[0].files.forEach((elem) => {
        fetchedImageUrls[elem.file] = { id: elem.id, url: config.endpoints.image_path + elem.file };
      });

      setViewImagesUrl({ ...fetchedImageUrls });
    });
  }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="View Service Request Details" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push('/service-request-mgt');
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>Customer ID</label>
                  <FormInput
                    id="feCustomerID"
                    placeholder="Customer ID"
                    type="text"
                    name="customerID"
                    readonly="true"
                    value={sr.user_id}
                  />
                </Col>
                <Col md={{ size: 2, order: 2 }}>
                  <label>SR ID</label>
                  <FormInput id="SrID" placeholder="SR ID" type="text" name="SrID" readonly="true" value={sr.id} />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    readonly="true"
                    value={sr.user && sr.user.last_name}
                  />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                    readonly="true"
                    value={sr.user && sr.user.first_name}
                  />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label>SR Status</label>
                  <FormInput
                    id="SrStatus"
                    placeholder="SR Status"
                    type="text"
                    name="SrStatus"
                    readonly="true"
                    value={sr.srstatus && sr.srstatus.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="8" className="mb-4">
                  <Row>
                    <Col md={{ size: 8, order: 4 }}>
                      <h4>Service Address</h4>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <RadioButtonDis />
                    </Col>

                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line 1</label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 1"
                        name="address_1"
                        readonly="true"
                        value={sr.address_1}
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line 2 </label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 2"
                        name="address_2"
                        readonly="true"
                        value={sr.address_2}
                      />
                    </Col>

                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feCountry">Country</label>
                      <FormInput
                        id="feCountry"
                        type="text"
                        placeholder="Country"
                        name="country"
                        readonly="true"
                        value={sr.cityGet && sr.cityGet.countries.name}
                      />
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feState">State</label>
                      <FormInput
                        id="feState"
                        type="text"
                        placeholder="State"
                        name="state"
                        readonly="true"
                        value={sr.cityGet && sr.cityGet.states.name}
                      />
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feCity">City</label>
                      <FormInput
                        id="feCitys"
                        type="text"
                        placeholder="City"
                        name="city"
                        readonly="true"
                        value={sr.cityGet && sr.cityGet.name}
                      />
                    </Col>

                    <Col md={{ size: 3, order: 4 }} readonly="true">
                      <label htmlFor="feZip">Zip Code</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Zip Code"
                        name="zip_code"
                        readonly="true"
                        value={sr.zip_code}
                      />
                    </Col>

                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Expected Date and Time</h4>
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feDate" style={{ marginRight: '10px' }}>
                        Service Date
                      </label>
                      <br></br>
                      <TextField
                        id="feDate"
                        type="text"
                        name="date"
                        variant="outlined"
                        value={sr.service_date}
                        disabled={true}
                        required
                        // defaultValue="mm-dd-yyyy"
                        // className={useStyles.textField}
                        inputProps={{
                          style: {
                            padding: '5px 10px',
                            fontSize: '14px',
                            height: '22px',
                            backgroundColor: '#f5f6f7',
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feTime">Service Time</label>
                      <FormInput
                        id="feTime"
                        type="text"
                        name="time"
                        // value={sr.timeGet && sr.timeGet.start_time}
                        value={`${dateWithFormate(sr?.timeGet?.start_time, 'hA', 'HH:mm:ss')}
                        -
                        ${dateWithFormate(sr?.timeGet?.end_time, 'hA', 'HH:mm:ss')}`}
                        required
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Service</h4>
                    </Col>

                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Category</label>
                      <FormInput
                        id="feTime"
                        type="text"
                        name="time"
                        value={sr.category && sr.category.category_name}
                        required
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Sub-Category</label>
                      <FormInput
                        id="feTime"
                        type="text"
                        name="time"
                        value={sr.sub_category && sr.sub_category.sub_category_name}
                        required
                        readonly="true"
                      />
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label htmlFor="feServiceType">Service Type </label>
                      <FormInput
                        id="feTime"
                        type="text"
                        name="time"
                        // value={sr.serviceType}
                        required
                        readonly="true"
                        value={sr.serviceType && sr.serviceType.name}
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Title </h4>
                      <FormTextarea
                        id="feTitle"
                        type="textarea"
                        placeholder="Provide high level summary of your request.
                      Max 50 Characters"
                        name="title"
                        maxLength="50"
                        readonly="true"
                        value={sr.title}
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Description</h4>

                      <FormTextarea
                        style={{ height: '110px' }}
                        id="feDescription"
                        type="text"
                        placeholder="Provide a brief description of the request and all the relevent details to get the most accurate quote.   
                      Max 1000 Characters"
                        name="description"
                        maxLength="1000"
                        readonly="true"
                        value={sr.description}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg="4"
                  // className="mb-4"
                  className="mb-2 mt-5"
                >
                  <Row>
                    <Col md={{ size: 12, order: 1 }} className="mb-2 mb-5 mt-5">
                      <h4>Uploaded Photo</h4>
                      <ViewPhotoImageUpload sources={viewImagesUrl} />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={{ size: 12, order: 2 }} className="mb-2 mt-5">
                      <h4>Uploaded Videos</h4>
                      <VideoUploads />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={{ size: 12, order: 3 }} className="mb-2 mt-5">
                      <label htmlFor="feZip">Total # of Quotes Received</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Quotes Received"
                        name="quotes_received"
                        readonly="true"
                        value={sr.quote && sr.quote.length}
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }} className="mb-2 mb-2">
                      <label htmlFor="feZip">Total # of Warranty Requests</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Warranty Received"
                        name="warranty_received"
                        readonly="true"
                        value={sr.warranty && sr.warranty.length}
                      />
                    </Col>
                    <Col md={{ size: 12, order: 5 }} className="mb-2 mb-2">
                      <label htmlFor="feZip">Total # of Dispute Requests</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Dispute Received"
                        name="zip_code"
                        readonly="true"
                        value={''}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col sm={{ size: 6, order: 6, offset: 10 }} className="mb-5">
                <Button
                  onClick={() => {
                    props.history.push('/edit-service-request/' + srId);
                  }}
                  outline
                  theme="primary"
                >
                  Edit
                </Button>
              </Col>
            </ListGroupItem>
          </Col>
          <Container fluid className="main-content-container">
            <Row>
              <Col sm={{ size: 12, order: 2 }}>
                <CustBotton
                  state={state}
                  setState={setState}
                  buttons={{
                    viewQuotesList: 'View Quotes List',
                    ViewWarrantyRequestList: 'View Warranty Request',
                    ViewDisputeRequestList: 'View Dispute Request',
                  }}
                />
              </Col>
            </Row>
            {state.viewQuotesList ? <ViewQuotesList srId={srId} history={props.history} /> : null}
            {state.ViewWarrantyRequestList ? <ViewWarrantyRequestList srId={srId} history={props.history} /> : null}
            {state.ViewDisputeRequestList ? <ViewDisputeRequestList srId={srId} history={props.history} /> : null}
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default ViewServiceRequest;

import React, { useState } from 'react';
import { CardHeader, ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import HomePageHerography from './herography';
import HomePageAdvBanner from './advBanner';
import HomePageAbout from './about';

function HomePage(props) {
  const [state, setState] = useState({ herography: true, advBanner: false, about: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          {/* <CardHeader> */}
          <CustBotton
            state={state}
            setState={setState}
            buttons={{ herography: 'Herography', advBanner: 'Adv Banners', about: 'About eZunction' }}
          />
          {/* </CardHeader> */}
        </ListGroupItem>
      </ListGroup>

      {state.herography && (
        <HomePageHerography
          isEdit={props.isEdit}
          setEdit={props.setEdit}
          userId={props.userId}
          history={props.history}
        />
      )}
      {state.advBanner && (
        <HomePageAdvBanner
          isEdit={props.isEdit}
          setEdit={props.setEdit}
          userId={props.userId}
          history={props.history}
        />
      )}
      {state.about && (
        <HomePageAbout isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default HomePage;

import { authHeader } from './authHeader';
import { authHeaderStore } from './authHeaderStore';
import config from './../data/config';
import base64 from 'base-64';
// import queryString from 'query-string';
// import axios from 'axios';

export const APIService = {
  login,
  logout,
  fetchAllCategories,
  addCategory,
  updateCategory,
  deleteCategory,
  fetchAllSubCategories,
  addSubCategory,
  updateSubCategory,
  fetchSrInvoice,
  fetchSrSpPayout,
  adminProfile,
  fetchAllUsers,
  fetchAllUsersCount,
  fetchAllProviders,
  deleteSubCategory,
  addUser,
  updateUser,
  deleteUser,
  statusUser,
  addProvider,
  updateProvider,
  deleteProvider,
  fetchCountry,
  fetchState,
  fetchCity,
  fetchAllServiceType,
  fetchSubCategory,
  addServiceType,
  updateServiceType,
  deleteServiceType,
  fetchAllSubAdmins,
  addSubAdmin,
  updateSubAdmin,
  deleteSubAdmin,
  fetchAllAdminRoleType,
  statusAdmin,
  statusCategory,
  statusSubCategory,
  statusServiceType,
  fetchAllHouseType,
  statusHouseType,
  deleteHouseType,
  addHoustType,
  updateHoustType,
  fetchAllTimeSlots,
  statusTimeSlots,
  deleteTimeSlots,
  addTimeSlots,
  updateTimeSlots,

  fetchAllWithdrwalReason,
  statusWithdrwalReason,
  deleteWithdrwalReason,
  addWithdrwalReason,
  updateWithdrwalReason,

  fetchAllQuoteRejection,
  statusQuoteRejection,
  deleteQuoteRejection,
  addQuoteRejection,
  updateQuoteRejection,

  fetchAllSrCancelReason,
  statusSrCancelReason,
  deleteSrCancelReason,
  addSrCancelReason,
  updateSrCancelReason,

  fetchAllSrStatus,
  statusSrStatus,
  deleteSrStatus,
  addSrStatus,
  updateSrStatus,

  fetchAllquoteStatus,
  statusquoteStatus,
  deletequoteStatus,
  addquoteStatus,
  updatequoteStatus,

  fetchAllcommissionTax,
  statuscommissionTax,
  deletecommissionTax,
  addcommissionTax,
  updatecommissionTax,

  fetchServiceType,

  fetchAllServiceRequest,
  addServiceRequest,
  updateServiceRequest,
  statusServiceRequest,
  deleteServiceRequest,

  userDetails,
  fetchServiceRequest,
  fetchDispute,
  addDisputeReason,
  updateDisputeReason,
  deleteDisputeReason,
  fetchRevisitReasons,
  addRevisitReason,
  updateRevisitReason,
  deleteRevisitReason,
  fetchQuoteList,
  fetchQuoteById,
  updateQuote,
  fetchQuoteComments,
  updateQuoteComment,
  deleteQuoteComment,
  fetchWarrantyService,
  fetchWarrantyServiceForSr,
  updateWarrantyRequest,
  deleteWarrantyRequest,
  fetchDisputeService,
  fetchDisputeServiceForSr,
  updateDisputeRequest,
  deleteDisputeRequest,
  fetchCouponManagement,
  addCoupon,
  updateCoupon,
  deleteCoupon,
  fetchServiceRequestById,
  fetchServiceRequestUpdate,
  fetchServiceRequestDelete,
  srFileDelete,
  srActivateDeactivate,
  quoteActivateDeactivate,
  userActivateDeactivate,
  fetchQuoteListBySrId,
  fetchUserMgt,
  fetchUserMgtById,
  fetchUserDl,
  updateUserDl,
  fetchSpBl,
  updateSpBl,
  fetchCompanyInfo,
  updateCompanyInfo,
  fetchSpProfile,
  updateSpProfile,
  fetchAllSubAdminsById,
  currentAdminRoles,
  fetchViewQuoteId,
  quoteDisableReason,
  addQuoteDisableReason,
  updateQuoteDisableReason,
  deleteQuoteDisableReason,
  fetchSrDisableReason,
  addSrDisableReason,
  updateSrDisableReason,
  deleteSrDisableReason,
  fetchReport,
  updateReport,
  generateReport,
  sendPassword,
  AdminRole,
  downloadImage,
  getCMSSubSection,
  updateCmsContent,
  getPeopleVoices,
  createPeopleVoice,
  updatePeopleVoice,
};

const baseUrl =
  config.url === 'baseurl'
    ? config.baseurl
    : config.url === 'localurl'
    ? config.localurl
    : config.url === 'ngrokurl'
    ? config.ngrokurl
    : config.url === 'sandbox'
    ? config.sandbox
    : window.location.hostname;

function downloadImage(imageKey) {
  return baseUrl + config.endpoints.image_download_path + imageKey;
}

// const instance = axios.create({ baseURL: baseUrl });

function login(user, password) {
  // console.log(user, password);
  const basicAuthString = base64.encode(user + ':' + password);
  const requestOptions = {
    method: 'POST',
    headers: authHeader(user, password),
    // body: JSON.stringify({ email: user, password }),
  };

  // return instance
  //   .get(config.endpoints.login, { headers: authHeader(user, password) })
  //   .then(({ data: user }) => {
  //     if (user) {
  //       user.auth_data = basicAuthString;
  //       localStorage.setItem('user', JSON.stringify(user));
  //     }
  //     return user;
  //   });

  // return fetch(baseUrl + config.endpoints.login, requestOptions)
  //   .then(_handleResponse)
  //   .then(async (user) => {
  //     // console.log(user)
  //     if (user) {
  //       await localStorage.setItem("user", JSON.stringify(user));
  //       await localStorage.setItem("token", user.token);
  //     }
  //     return user;
  //   });
  // console.log(baseUrl + config.endpoints.login);
  return fetch(baseUrl + config.endpoints.login, requestOptions)
    .then(_handleResponse)
    .then(async (user) => {
      if (user) {
        user.auth_data = basicAuthString;
        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('token', basicAuthString);
      }
      return user;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('units');
  localStorage.removeItem('categories');
  localStorage.removeItem('orderStatuses');
  localStorage.removeItem('roles');
}

async function fetchAllSubAdmins() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sub_admins, requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addSubAdmin(body) {
  var formData = new FormData();
  formData.append('first_name', body.firstName);
  formData.append('last_name', body.lastName);
  formData.append('middle_name', body.middleName);
  formData.append('email', body.email);
  formData.append('password', body.password);
  formData.append('role_type', body.roleType);
  formData.append('country', body.country);
  formData.append('state', body.state);
  formData.append('city', body.city);
  formData.append('mobile', body.mobilePhone);
  formData.append('phone', body.homePhone);
  formData.append('zip_code', body.zipCode);
  formData.append('address_1', body.address1);
  formData.append('address_2', body.address2);

  if (body.profile_photo) {
    formData.append('profile_change', 'true');
    formData.append('profile_image', body.profile_photo);
  }

  if (body.driving_license_photo) {
    formData.append('driving_change', 'true');
    formData.append('driving_image', body.driving_license_photo);
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.sub_admins, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateSubAdmin(id, body) {
  const formData = new FormData();

  Object.entries({
    first_name: 'firstName',
    last_name: 'lastName',
    middle_name: 'middleName',
    email: 'email',
    role_type: 'roleType',
    country: 'country',
    state: 'state',
    city: 'city',
    phone: 'mobilePhone',
    home_phone: 'homePhone',
    zip_code: 'zipCode',
    address_1: 'address1',
    address_2: 'address2',
  }).forEach(([apikey, key]) => {
    if (body[key]) {
      formData.append(apikey, body[key]);
    }
  });

  if (body.status == 0 || body.status == 1) formData.append('status', body.status);

  if (body.profile_photo) {
    formData.append('profile_change', 'true');
    formData.append('profile_image', body.profile_photo);
  }

  if (body.driving_license_photo) {
    formData.append('driving_change', 'true');
    formData.append('driving_image', body.driving_license_photo);
  }

  if (body.password) {
    formData.append('password', body.password);
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.sub_admins + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusAdmin(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sub_admins + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteSubAdmin(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sub_admins + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchAllAdminRoleType(token) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sub_admins + 'roles-type', requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchAllUsers(token) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users, requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchAllUsersCount() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users + 'count', requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchAllProviders(token) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.providers, requestOptions)
    .then(_handleResponse)
    .then((providers) => {
      return providers;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchAllCategories() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.categories, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addCategory(body) {
  // console.log(body);

  var formData = new FormData();
  formData.append('category_image', body.category_image);
  formData.append('description', body.description);
  formData.append('category_name', body.name);
  formData.append('status', body.status);

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.add_categories, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateCategory(id, body) {
  // const categoryBody = { category_name: body };

  var formData = new FormData();

  formData.append('description', body.description);
  formData.append('category_name', body.name);
  formData.append('status', body.status);

  if (body.category_image_change) {
    formData.append('category_image', body.category_image);
  }
  formData.append('category_image_change', body.category_image_change);

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.categories + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteCategory(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.categories + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchAllSubCategories() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.subcategories, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // console.log('categories :: ' + categories);

      await localStorage.setItem('subcategories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addSubCategory(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.subcategories, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateSubCategory(id, body) {
  // console.log(body);
  // const categoryBody = { name: body };
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.subcategories + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteSubCategory(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.subcategories + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchSrInvoice(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srInvoice + srId, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchSrSpPayout(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srSpPayout + srId, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function adminProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.profile, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchServiceType(sub_category_id) {
  let body = { sub_category_id: sub_category_id };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.subcategories + 'service-type', requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addUser(body) {
  var formData = new FormData();

  [
    'profile_photo',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'password',
    'address_1',
    'address_2',
    'city',
    'state',
    'country',
    'zip_code',
    'phone',
    'role_type',
    'status',
  ].forEach((key) => {
    if (body[key]) formData.append(key, body[key]);
  });

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.registerUser, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateUser(id, body) {
  var formData = new FormData();

  [
    'profile_photo',
    'profile_change',
    'first_name',
    'middle_name',
    'last_name',
    'address_1',
    'address_2',
    'password',
    'city',
    'state',
    'country',
    'zip_code',
    'phone',
    'role_type',
    'status',
  ].forEach((key) => {
    if (body[key]) formData.append(key, body[key]);
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.users + id, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteUser(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users + id, requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusUser(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchCountry(token) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.location + 'country', requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchState(country_id) {
  let body = { country_id: country_id };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.location + 'state', requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchCity(state_id) {
  let body = { state_id: state_id };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.location + 'city', requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addProvider(body) {
  var formData = new FormData();
  formData.append('profile_photo', body.profile_photo);
  formData.append('first_name', body.first_name);
  formData.append('last_name', body.last_name);
  formData.append('email', body.email);
  formData.append('password', body.password);
  formData.append('address_1', body.address_1);
  formData.append('city', body.city);
  formData.append('state', body.state);
  formData.append('country', body.country);
  formData.append('zip_code', body.zip_code);
  formData.append('phone', body.phone);

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.providers, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateProvider(id, body) {
  var formData = new FormData();

  if (body.profile_change) {
    formData.append('profile_photo', body.profile_photo);
  }

  formData.append('first_name', body.first_name);
  formData.append('last_name', body.last_name);
  formData.append('email', body.email);
  formData.append('address_1', body.address_1);
  formData.append('city', body.city);
  formData.append('state', body.state);
  formData.append('country', body.country);
  formData.append('zip_code', body.zip_code);
  formData.append('phone', body.phone);
  formData.append('profile_change', body.profile_change);

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.providers + id, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteProvider(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.providers + id, requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchAllServiceType() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceType, requestOptions)
    .then(_handleResponse)
    .then(async (serviceType) => {
      await localStorage.setItem('serviceType', JSON.stringify(serviceType));
      return serviceType;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addServiceType(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.serviceType, requestOptions)
    .then(_handleResponse)
    .then((serviceType) => {
      return serviceType;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateServiceType(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.serviceType + id, requestOptions)
    .then(_handleResponse)
    .then((serviceType) => {
      return serviceType;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteServiceType(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceType + id, requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchSubCategory(category_id) {
  let body = { category_id: category_id };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.categories + 'sub-category', requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusCategory(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.categories + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusSubCategory(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.subcategories + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusServiceType(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceType + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function _handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    data.statusText = response.statusText;
    if (!response.ok) {
      if (response.status === 401) {
        // console.log(response);
        // logout();
        // if (new URL(response.url).pathname !== "/api/dashboard/admins/login") {
        //   window.location.reload(true);
        // }
      }
      return Promise.reject(data);
    }

    return data;
  });
}
function _handleResponseJson(response) {
  return response.text().then((text) => {
    let data = text || JSON.parse(text);
    // data.statusText = response.statusText;
    if (!response.ok) {
      if (response.status === 401) {
        // console.log(response);
        // logout();
        // if (new URL(response.url).pathname !== "/api/dashboard/admins/login") {
        //   window.location.reload(true);
        // }
      }
      return Promise.reject(data);
    }

    return data;
  });
}

function fetchAllHouseType() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.housetype, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addHoustType(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.housetype, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateHoustType(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.housetype + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusHouseType(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.housetype + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteHouseType(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.housetype + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function fetchAllTimeSlots() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.timeslots, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addTimeSlots(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.timeslots, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateTimeSlots(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.timeslots + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusTimeSlots(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.timeslots + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteTimeSlots(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.timeslots + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// withdrawal reason

function fetchAllWithdrwalReason() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.withdrawalReason, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addWithdrwalReason(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.withdrawalReason, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateWithdrwalReason(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.withdrawalReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusWithdrwalReason(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.withdrawalReason + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteWithdrwalReason(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.withdrawalReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// rejection reason

function fetchAllQuoteRejection() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.rejectionReason, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addQuoteRejection(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.rejectionReason, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateQuoteRejection(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.rejectionReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusQuoteRejection(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.rejectionReason + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteQuoteRejection(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.rejectionReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// sr cancel reason

function fetchAllSrCancelReason() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srCancelReason, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addSrCancelReason(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.srCancelReason, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateSrCancelReason(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.srCancelReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusSrCancelReason(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srCancelReason + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteSrCancelReason(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srCancelReason + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// Sr Status

function fetchAllSrStatus() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srStatus, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addSrStatus(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.srStatus, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateSrStatus(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.srStatus + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusSrStatus(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srStatus + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteSrStatus(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srStatus + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// Quote Status

function fetchAllquoteStatus() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addquoteStatus(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    });
}

function updatequoteStatus(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusquoteStatus(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deletequoteStatus(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// Charge Factor

function fetchAllcommissionTax() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.commissionTax, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addcommissionTax(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.commissionTax, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updatecommissionTax(id, body) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.commissionTax + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statuscommissionTax(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.commissionTax + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deletecommissionTax(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.commissionTax + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

// service request

function fetchAllServiceRequest() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceRequest, requestOptions)
    .then(_handleResponse)
    .then(async (categories) => {
      // await localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function addServiceRequest(body) {
  var formData = new FormData();

  formData.append('first_name', body.fistName);
  formData.append('last_name', body.lastName);
  formData.append('user_id', body.customerID);
  formData.append('title', body.title);
  formData.append('address_type', body.addressType);
  formData.append('description', body.description);
  formData.append('time_slot', body.service_time);
  formData.append('service_date', body.date);
  formData.append('country', body.country_id);
  formData.append('state', body.state_id);
  formData.append('city', body.city);
  formData.append('category_id', body.category);
  formData.append('sub_category_id', body.subCategory);
  formData.append('service_type_id', body.serviceType);
  formData.append('zip_code', body.zip_code);
  formData.append('address_1', body.address_1);
  formData.append('address_2', body.address_2);
  for (let i = 0; i < body.profile_image.length; i++) {
    formData.append('images[]', body.profile_image[i]);
  }
  // return formData;

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.addServiceRequest, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function updateServiceRequest(id, body) {
  var formData = new FormData();

  if (body.profile_change) {
    body.images.forEach((file) => formData.append('images[]', file));
  }

  // formData.append('first_name', body.first_name);
  formData.append('profile_change', body.profile_change);
  // formData.append('last_name', body.last_name);
  formData.append('user_id', body.user_id);
  formData.append('title', body.title);
  formData.append('address_type', body.address_type);
  formData.append('description', body.description);
  formData.append('time_slot', body.time);
  formData.append('service_date', body.date);
  formData.append('country', body.country);
  formData.append('state', body.state);
  formData.append('city', body.city);
  formData.append('category_id', body.category_id);
  formData.append('sub_category_id', body.sub_category_id);
  formData.append('service_type_id', body.service_type);
  formData.append('zip_code', body.zip_code);
  formData.append('address_1', body.address_1);
  formData.append('address_2', body.address_2);

  // return formData;

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function statusServiceRequest(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + id + '/status', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function deleteServiceRequest(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function userDetails(id) {
  // console.log(id)
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users + id + '/details', requestOptions)
    .then(_handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchServiceRequest(options = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const url = new URL(baseUrl + config.endpoints.serviceRequest);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchServiceRequestById(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}
async function fetchServiceRequestUpdate(sr) {
  const formData = new FormData();

  Object.entries({
    addressType: 'address_type',
    address_1: 'address_1',
    address_2: 'address_2',
    city: 'city',
    state: 'state',
    country: 'country',
    description: 'description',
    zip_code: 'zip_code',
    service_date: 'service_date',
    service_time: 'time_slot',
    category: 'category_id',
    subCategory: 'sub_category_id',
    serviceType: 'service_type_id',
    title: 'title',
    srStatus: 'sr_status',
    appliance_make: 'appliance_make',
    appliance_model: 'appliance_model',
    appliance_year: 'appliance_year',
    cust_noshow_comments: 'cust_noshow_comments',
  }).forEach(([payloadKey, requestKey]) => {
    if (sr[payloadKey]) formData.append(requestKey, sr[payloadKey]);
  });

  if (sr.images) {
    Object.entries(sr.images).forEach(([filename, source]) => {
      if (source.file) formData.append('images[]', source.file);
    });
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + sr.SrID, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchServiceRequestDelete(srId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.serviceRequest + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function srFileDelete(fileId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srFileById + fileId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function srActivateDeactivate(srId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srActDeactivate + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function quoteActivateDeactivate(quoteId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteActDeactivate + quoteId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function userActivateDeactivate(userId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.userActDeactivate + userId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchDispute() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.dispute, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function addDisputeReason(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(baseUrl + config.endpoints.dispute, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateDisputeReason(reasonId, updates) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(updates),
  };

  return fetch(baseUrl + config.endpoints.dispute + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteDisputeReason(reasonId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.dispute + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchRevisitReasons() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.revisitReason, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function addRevisitReason(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(baseUrl + config.endpoints.revisitReason, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateRevisitReason(reasonId, updates) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(updates),
  };

  return fetch(baseUrl + config.endpoints.revisitReason + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteRevisitReason(reasonId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.revisitReason + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchQuoteListBySrId(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteService + '/sr/' + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchQuoteList(options = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const url = new URL(baseUrl + config.endpoints.quoteService);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchQuoteById(quoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteService + '/' + quoteId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}
async function updateQuote(quoteId, updates) {
  const body = {};
  Object.entries({
    serviceIncluded: 'services_included',
    labourQuoteAmount: 'labor_quote_amount',
    materialQuoteAmount: 'material_quote_amount',
    materialIncluded: 'material_included_flag',
    additionalComments: 'additional_comments',
    quoteServiceDate: 'quote_service_date',
    quoteStatus: 'quote_status',
    actionComment: 'action_comment',
  }).forEach(([key, apiKey]) => {
    if (key === 'materialIncluded') updates.materialIncluded = updates.materialIncluded === 'yes' ? 1 : 0;
    // if (key === 'quoteServiceDate') updates.quoteServiceDate = updates.quoteServiceDate + 'T18:30:00.000Z';
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.quoteService + '/' + quoteId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchQuoteComments(quoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteComments + quoteId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateQuoteComment(commentId, updates) {
  const body = {};
  Object.entries({
    commentFrom: 'user',
    description: 'comment',
  }).forEach(([key, apiKey]) => {
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.quoteComments + commentId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteQuoteComment(commentId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteComments + commentId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchWarrantyService(options = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const url = new URL(baseUrl + config.endpoints.warrantyService);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchWarrantyServiceForSr(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.warrantyServiceSr + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateWarrantyRequest(warrantyId, updates) {
  const body = {};
  Object.entries({
    warrantyDetails: 'details',
    warrantyClosureDate: 'warranty_closure_date',
    warrantyExpiryDate: 'warranty_expiration_date',
    warrantyClosureComments: 'closure_comments',
  }).forEach(([key, apiKey]) => {
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.warrantyService + warrantyId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteWarrantyRequest(warrantyId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.warrantyService + warrantyId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchDisputeService(options = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const url = new URL(baseUrl + config.endpoints.disputeService);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchDisputeServiceForSr(srId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.disputeServiceSr + srId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateDisputeRequest(disputeId, updates) {
  const body = {};
  Object.entries({
    disputeDetails: 'dispute_details',
    disputeReason: 'dispute_reason',
    closureDate: 'dispute_closure_date',
    closureComments: 'closure_comments',
  }).forEach(([key, apiKey]) => {
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.disputeService + disputeId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteDisputeRequest(disputeId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.disputeService + disputeId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchCouponManagement(options = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const url = new URL(baseUrl + config.endpoints.coupon);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function addCoupon(updates) {
  const body = {};
  Object.entries({
    couponName: 'name',
    couponCode: 'code',
    couponDescription: 'description',
    couponDiscount: 'discount',
    couponValue: 'value',
    validityStartDate: 'start_date',
    validityEndDate: 'end_date',
    couponLimit: 'limit',
  }).forEach(([key, apiKey]) => {
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.coupon, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateCoupon(couponId, updates) {
  const body = {};
  Object.entries({
    couponName: 'name',
    couponCode: 'code',
    couponDescription: 'description',
    couponDiscount: 'discount',
    couponValue: 'value',
    validityStartDate: 'start_date',
    validityEndDate: 'end_date',
    couponLimit: 'limit',
  }).forEach(([key, apiKey]) => {
    if (updates[key]) body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.coupon + couponId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteCoupon(couponId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.coupon + couponId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchUserMgt(options = {}) {
  // const count = await fetchAllUsersCount();
  // console.log('countx', count)

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const url = new URL(baseUrl + config.endpoints.users);

  if (options.queryParams) url.search = new URLSearchParams(options.queryParams);

  return fetch(url, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchUserMgtById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.users + id + '/details', requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchUserDl(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.userDl + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateUserDl(id, updates) {
  const formData = new FormData();

  [
    'image',
    'dl_id',
    'dl_full_name',
    'dl_state',
    'dl_issue_date',
    'dl_expiry_date',
    'user_dob',
    'image_updated',
    'ssn_last_four',
    'ssn_full',
  ].forEach((key) => {
    if (updates[key]) formData.append(key, updates[key]);
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.userDl + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchSpBl(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.spBl + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateSpBl(id, updates) {
  const formData = new FormData();

  [
    'bl_image',
    'bl_id',
    'bl_issue_authority',
    'dl_state',
    'bl_issue_state',
    'bl_issue_date',
    'bl_expiry_date',
    'image_updated',
  ].forEach((key) => {
    if (updates[key]) formData.append(key, updates[key]);
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.spBl + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchCompanyInfo(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.spCompany + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateCompanyInfo(id, updates) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(updates),
  };

  return fetch(baseUrl + config.endpoints.spCompany + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchSpProfile(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.spProfile + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateSpProfile(id, updates) {
  const formData = new FormData();

  [
    'category',
    'subcategory',
    'location',
    'serviceState',
    'year_experience',
    'warranty',
    'phone_consultation',
    'bonded_insured',
    'insurance_provider_name',
    'insurance_expiry_date',
    'insurance_policy',
    'insured_name_address',
    'insured_mobile_number',
    'insured_country',
    'insurance_image',
    'image_updated',
  ].forEach((key) => {
    if (updates[key]) formData.append(key, updates[key]);
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.spProfile + id, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchAllSubAdminsById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sub_admins + id + '/details', requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function currentAdminRoles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.currentAdminRoles, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      localStorage.setItem('roles', JSON.stringify(data.map((role) => role.role_code)));

      return data;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchViewQuoteId(quoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteStatus + quoteId + '/details', requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function quoteDisableReason() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteDisable, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function addQuoteDisableReason(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.quoteDisable, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateQuoteDisableReason(reasonId, updates) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(updates),
  };

  return fetch(baseUrl + config.endpoints.quoteDisable + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteQuoteDisableReason(reasonId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.quoteDisable + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchSrDisableReason() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srDisableReason, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function addSrDisableReason(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.srDisableReason, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateSrDisableReason(reasonId, updates) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(updates),
  };

  return fetch(baseUrl + config.endpoints.srDisableReason + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function deleteSrDisableReason(reasonId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.srDisableReason + reasonId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function fetchReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.report, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateReport(reportId, updates) {
  const body = {};

  Object.entries({
    email: 'email_to',
  }).forEach(([key, apiKey]) => {
    body[apiKey] = updates[key];
  });

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.report + reportId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function generateReport(reportId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.report + reportId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

function sendPassword(data) {
  const formData = new FormData();
  formData.append('email', data.email);
  formData.append('password', data.password);
  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };
  return fetch(baseUrl + config.endpoints.sendPassword, requestOptions)
    .then(_handleResponseJson)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function AdminRole() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.adminRole, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function getCMSSubSection(section, sub_section) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.cms + section + '/' + sub_section, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updateCmsContent(contentId, updates) {
  const formData = new FormData();

  if (updates.hasOwnProperty('video_src')) formData.append('video_src', updates.video_src);

  if (updates.image) {
    formData.append('image', updates.image);
    formData.append('image_updated', 'true');
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.cmsContent + contentId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function getPeopleVoices() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.peopleVoices, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function createPeopleVoice(payload) {
  const formData = new FormData();

  formData.append('image_src', payload.image_src);
  formData.append('image_name', payload.image_name);
  formData.append('image', payload.image);

  const requestOptions = {
    method: 'POST',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.peopleVoices, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

async function updatePeopleVoice(contentId, updates) {
  const formData = new FormData();

  if (updates.image_name) formData.append('image_name', updates.image_name);
  if (updates.image_src) formData.append('image_src', updates.image_src);
  if (updates.status || updates.status === 0) formData.append('status', updates.status);

  if (updates.image) {
    formData.append('image', updates.image);
    formData.append('image_updated', 'true');
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeaderStore(),
    body: formData,
  };

  return fetch(baseUrl + config.endpoints.peopleVoices + contentId, requestOptions)
    .then(_handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error.errorMessage || error.message);
      throw error;
    });
}

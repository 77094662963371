import React, { useState } from 'react';
import { Button, FormInput, Row, Col, Modal, ModalBody, ModalHeader, Form } from 'shards-react';
import { APIService } from '../../../utils/APIService';

const allowedDimensions = { width: 533, height: 324 };

function AddPeopleVoice({ modalOpen, setModalOpen, getService }) {
  const [image, setImage] = useState({
    photo: null,
    photo_url: '',
    photo_name: '',
  });
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    const newImg = new Image();

    image && reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      newImg.src = reader.result;
      newImg.onload = () => {
        if (allowedDimensions.height !== newImg.naturalHeight || allowedDimensions.width !== newImg.naturalWidth) {
          alert(`Please select image of dimension ${allowedDimensions.width} X ${allowedDimensions.height} only`);
        } else {
          setImage({ photo: image, photo_name: image.name, photo_url: reader.result });
        }
      };
    };
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    setState({ ...state, [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let url;
    let isValidVideoSrc = true;

    try {
      url = new URL(state.image_src);
    } catch (_) {
      isValidVideoSrc = false;
    } finally {
      isValidVideoSrc = isValidVideoSrc && (url.protocol === 'http:' || url.protocol === 'https:');

      if (!isValidVideoSrc) return alert('Video src is not valid');
    }

    setLoading(true);
    APIService.createPeopleVoice({ ...state, image: image.photo }).finally(() => {
      setLoading(false);
      setModalOpen();
      getService();
    });
  };

  return (
    <div>
      {/* <Button onClick={toggle}>Click Me!</Button> */}
      <Modal open={modalOpen} toggle={toggle} centered size="lg">
        <ModalHeader>Add People Voice</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={{ size: 8, order: 4 }} className="form-group mt-3">
                <label>Image*</label>
                <div className="custom-file mb-3">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="image"
                    name="photo"
                    accept="image/*"
                    onChange={handleChangeImage}
                    disabled={loading}
                    required
                  />
                  <label className="custom-file-label" htmlFor="image">
                    {image.photo_name || 'Choose Profile Image...'}
                  </label>
                </div>
              </Col>

              <Col
                md={{ size: 3, order: 4 }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {!!image.photo_url && <img src={image.photo_url} width="100%" id="img_prev" alt="profile" />}
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 6, order: 4 }} className="form-group ">
                <label htmlFor="feAddress2">Name</label>
                <FormInput
                  type="text"
                  placeholder="People voice's name"
                  name="image_name"
                  onChange={handleChange}
                  value={state.image_name}
                  disabled={loading}
                  required
                />
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group ">
                <label htmlFor="feAddress2">Video src</label>
                <FormInput
                  type="text"
                  placeholder="People voice's video url"
                  name="image_src"
                  onChange={handleChange}
                  value={state.image_src}
                  disabled={loading}
                  required
                />
              </Col>
            </Row>
            <Col></Col>
            <Col md={{ size: 12, order: 4, offset: 10 }} className="form-group mt-3">
              <Button outline type="submit" theme="primary" className="mb-2 mr-1">
                {loading ? <span class="spinner-border" /> : 'Save'}
              </Button>
            </Col>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AddPeopleVoice;

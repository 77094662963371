import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
import { Container, ListGroupItem, Row, Col, FormInput, Button } from 'shards-react';
import { APIService } from '../../utils/APIService';
import { useParams } from 'react-router-dom';

function SubAdminView(props) {
  const { id } = useParams();
  const [state, setState] = useState({ checkedA: true });
  const [subAdminView, setSubAdminView] = useState({ roleType: '' });
  const [adminRole, setAdminRole] = useState([]);

  useEffect(() => {
    getService();
    fetchAdminRole();
  }, []);

  function getService() {
    const serviceReq = async () => {
      const response = await APIService.fetchAllSubAdminsById(id);
      console.log(response[0]);

      setSubAdminView({ ...response[0], roleType: response[0].role_types?.map((role) => role.role_id).join(',') });
      if (response[0]) {
        if (response[0].profile_image) {
          state.profile_photo_name = response[0].profile_image;
          state.profile_photo_url = APIService.downloadImage(response[0].profile_image);
        }
        if (response[0].driving_image) {
          state.driving_license_photo_name = response[0].driving_image;
          state.driving_license_photo_url = APIService.downloadImage(response[0].driving_image);
        }

        setState({ ...state });
      }
    };

    serviceReq();
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const fetchAdminRole = () => {
    APIService.AdminRole().then((res) => {
      setAdminRole(res);
    });
  };
  return (
    <>
      <Container fluid className="main-content-container">
        <MainTitle title="View-Sub-Admin" />
        <ContentHeader>
          <Button
            onClick={() => {
              props.history.push('/sub-admins');
            }}
            outline
            theme="primary"
            className="mb-2 mr-1"
          >
            Back
          </Button>
        </ContentHeader>
        <ListGroupItem>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feFirstName">First Name*</label>
              <FormInput
                id="feFirstName"
                type="text"
                placeholder="First Name"
                name="fistName"
                readonly="true"
                value={subAdminView.first_name}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feMiddleName">Middle Name</label>
              <FormInput
                id="feMiddleName"
                placeholder="Middle Name"
                type="text"
                name="middleName"
                readonly="true"
                value={subAdminView.middle_name}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feLastName">Last Name*</label>
              <FormInput
                id="feLastName"
                placeholder="Last Name"
                type="text"
                name="lastName"
                readonly="true"
                value={subAdminView.last_name}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feEmail">Email ID*</label>
              <FormInput
                id="feEmail"
                type="email"
                placeholder="Email"
                name="email"
                readonly="true"
                value={subAdminView.email}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feMobile">Mobile Phone*</label>
              <FormInput
                id="feMobile"
                type="number"
                placeholder="Mobile"
                name="mobile"
                readonly="true"
                value={subAdminView.mobile}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="feMobile">Home Phone*</label>
              <FormInput
                id="feMobile"
                type="number"
                placeholder="Phone"
                name="mobile"
                readonly="true"
                value={subAdminView.home_phone}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress">Address 1*</label>
              <FormInput
                id="feAddress"
                type="text"
                placeholder="Address Line 1"
                name="address_1"
                readonly="true"
                value={subAdminView.address_1}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">Address 2</label>
              <FormInput
                id="feAddress2"
                type="text"
                placeholder="Address Line 2"
                name="address_2"
                readonly="true"
                value={subAdminView.address_2}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">Country*</label>
              <FormInput
                id="fecountries"
                type="text"
                placeholder="countries"
                name="countries"
                readonly="true"
                value={subAdminView.countries && subAdminView.countries.name}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">State*</label>
              <FormInput
                id="festates"
                type="text"
                placeholder="states"
                name="states"
                readonly="true"
                value={subAdminView.states && subAdminView.states.name}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">City*</label>
              <FormInput
                id="fecities"
                type="text"
                placeholder="cities"
                name="cities"
                readonly="true"
                value={subAdminView.cities && subAdminView.cities.name}
              />
            </Col>

            <Col md={{ size: 2, order: 4 }} className="form-group ">
              <label htmlFor="feZip">Zip Code*</label>
              <FormInput
                id="feZip"
                type="text"
                placeholder="Zip Code"
                name="zip_code"
                readonly="true"
                value={subAdminView.zip_code}
              />
            </Col>
            <Col md={{ size: 2, order: 4 }} className="form-group ">
              <label htmlFor="feZip">Status*</label>
              <FormInput
                id="feStatus"
                type="text"
                placeholder="Status"
                readonly="true"
                name="Status"
                readonly="true"
                value={subAdminView.status == 1 ? 'Active' : 'Inactive'}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="fepassword">Password*</label>
            </Col>
            <Col md={{ size: 3, order: 4, offset: 4 }} className="form-group mt-4 "></Col>

            <Col md={{ size: 4, order: 4 }} className="form-group mt-3 mb-3">
              <h4>Profile Image</h4>
              <Col
                md={{ size: 6, order: 4 }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {!!state.profile_photo_url && (
                  <img src={state.profile_photo_url} width="100%" id="profile_img_prev" alt="Profile" />
                )}
              </Col>
            </Col>

            <Col md={{ size: 4, order: 4 }} className="form-group mt-3 ml-3 mb-3">
              <h4>Drivers License Image</h4>
              <Col
                md={{ size: 6, order: 4 }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {!!state.driving_license_photo_url && (
                  <img
                    src={state.driving_license_photo_url}
                    width="100%"
                    id="driver_license_img_prev"
                    alt="Drivers License"
                  />
                )}
              </Col>
            </Col>
          </Row>

          <div>
            <Row>
              <Col lg="12" md={{ size: 6, order: 4 }} className="mb-6 p-5 mt-4" style={{ textAlign: 'center' }}>
                <h4>
                  <b>GRANT MODULE ACCESS </b>
                </h4>
              </Col>
            </Row>

            <Col>
              <FormGroup row>
                {adminRole.map((item, index) => {
                  return (
                    <Col key={index} md={{ size: 4, order: 4 }} className="form-group">
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            value={item.id}
                            checked={subAdminView.roleType.split(',').some((role) => role == item.id)}
                            onChange={handleChange}
                            name="roleType"
                            color="primary"
                          />
                        }
                        label={`${item.role_type}(${item.role_code})`}
                      />
                    </Col>
                  );
                })}
              </FormGroup>
            </Col>
          </div>

          <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group ">
            <Button
              outline
              theme="primary"
              className="mb-2 mr-1"
              onClick={() => {
                props.history.push('/sub-admin-edit/' + id);
              }}
            >
              Edit
            </Button>
          </Col>
        </ListGroupItem>
      </Container>
    </>
  );
}
export default SubAdminView;

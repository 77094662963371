import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function Calender({ onChange, name }) {
  name = name ? name + '_' : '';
  return (
    <form className={useStyles.container} className="mb-2">
      <TextField
        id="date"
        label="Start"
        type="date"
        defaultValue="mm-dd-yyyy"
        className={useStyles.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange || null}
        name={name + 'start'}
      />
      <TextField
        id="date"
        label="End"
        type="date"
        defaultValue="mm-dd-yyyy"
        className={useStyles.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange || null}
        name={name + 'end'}
      />
    </form>
  );
}

export default Calender;

function SelectDate(props) {
  const { handleChange, selectedDate, name } = props;

  return (
    <form className={useStyles.container} className="mb-2">
      <TextField
        id="date"
        label={props.label}
        type="date"
        defaultValue="mm-dd-yyyy"
        className={useStyles.textField}
        InputLabelProps={{
          shrink: true,
        }}
        name={name}
        value={selectedDate}
        onChange={(e) => handleChange(e)}
      />
    </form>
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <Grid container justifyContent="space-around">

    //     <KeyboardDatePicker
    //       margin="normal"
    //       id="date-picker-dialog"
    //       label="Service Date"
    //       format="MM/dd/yyyy"
    //       value={selectedDate}
    //       onChange={(e) => handleChange(e)}
    //       name={name}
    //       KeyboardButtonProps={{
    //         'aria-label': 'change date',
    //       }}
    //     />

    //   </Grid>
    // </MuiPickersUtilsProvider>
  );
}

export { SelectDate };

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert, FormSelect } from 'shards-react';
import MainTitle from '../../../components/common/MainTitle';
import ContentHeader from '../../../components/common/ContentHeader';
import { APIService } from '../../../utils/APIService';
import MaterialTable from 'material-table';
import { REVERSE_USER_STATUS } from '../../../data/constants';

function QuoteDisableReason(props) {
  const [state, setState] = useState({
    visible: props.location.state ? props.location.state.visible : false,
    alertStyle: props.location.state ? props.location.state.alertStyle : '',
    alertIcon: props.location.state ? props.location.state.alertIcon : '',
    alertMessage: props.location.state ? props.location.state.alertMessage : '',
  });
  const [data, setData] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setData();
    const serviceReq = async () => {
      const response = await APIService.quoteDisableReason();

      setData(
        response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            status: item.status == 1 ? 'Active' : 'Inactive',
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <>
      <Container fluid className="px-0">
        <Alert
          theme={state.alertStyle || 'primary'}
          dismissible={() => setState({ ...state, visible: false })}
          open={state.visible}
          className="mb-0"
        >
          <i className={state.alertIcon} /> {state.alertMessage}
        </Alert>
      </Container>
      <Container fluid className="main-content-container px-4">
        <MainTitle title="Data Set Management" />
        <Row>
          <Col>
            <Card small className="mb-4">
              <ContentHeader>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/data-set');
                  }}
                >
                  House Type
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/time-slots');
                  }}
                >
                  Time Slots
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/quote-withdrawal-reasons');
                  }}
                >
                  Quote withdrawal reason
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/quote-rejection-reasons');
                  }}
                >
                  Quote rejection reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/sr-cancel-reasons');
                  }}
                >
                  SR cancel reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/dispute-reasons');
                  }}
                >
                  Dispute Reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/revisit-reasons');
                  }}
                >
                  Revisit Reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/sr-disable-reasons');
                  }}
                >
                  SR Disable Reasons
                </Button>
                <Button theme="primary" className="mb-2 mr-1">
                  Quote Disable Reasons
                </Button>

                <Button
                  onClick={() => {
                    props.history.push('/add-quote-Disable-list');
                  }}
                  outline
                  theme="success"
                  className="mb-2 mr-1"
                >
                  Add Quote Disable Reason
                </Button>
              </ContentHeader>
              <CardBody className="p-0 pb-3">
                <CardBody className="p-0 pb-3">
                  <MaterialTable
                    isLoading={!data}
                    title="Quote Disable Reason"
                    columns={[
                      { title: 'ID', field: 'id', editable: 'never' },
                      { title: 'Quote Disable Reason', field: 'name' },
                      {
                        title: 'Quote Disable Reason Status(Active/Inactive)',
                        field: 'status',
                        editComponent: (props) => (
                          <FormSelect
                            name="status"
                            onChange={(e) => props.onChange(e.currentTarget.value)}
                            value={REVERSE_USER_STATUS[props.value]}
                          >
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </FormSelect>
                        ),
                      },
                    ]}
                    data={data}
                    options={{
                      search: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                    }}
                    actions={[]}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          APIService.updateQuoteDisableReason(oldData.id, {
                            ...newData,
                            status: REVERSE_USER_STATUS[newData.status] || newData.status,
                          })
                            .then(getService)
                            .then(resolve)
                            .catch((error) => alert(error.errorMessage || error.message));
                        }),

                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          APIService.deleteQuoteDisableReason(oldData.id)
                            .then(getService)
                            .then(resolve)
                            .catch((error) => alert(error.errorMessage || error.message));
                        }),
                    }}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default QuoteDisableReason;

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  FormInput,
  FormSelect,
  Alert,
} from "shards-react";

import MainTitle from "../../../components/common/MainTitle";

import ContentHeader from "../../../components/common/ContentHeader";


function AddWarrantyClosureReason(props) {
  return (
    <>
      <Container fluid className="main-content-container px-4">
        <MainTitle title="Add Warranty Closure Reason" />
        <Row>
          <Col>
            <Card small className="mb-4">
              <ContentHeader
                title=''
              >
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {	if (
                    window.confirm(
                      'All your changes will be lost. Do you still want continue?'
                    )
                  ){
                    props.history.push("/warranty-closure-reasons");
                  }}}
                >
                  Back
                </Button>
              </ContentHeader>
              <CardBody className="p-0 pb-3">
              <Row form>
					<Col sm={{ size: 4, order: 4, offset: 4 }}>
					<label>Warranty Closure Reason</label>
						<FormInput
							id="WarrantyClosureReason"
							type="text"
							placeholder="Warranty Closure Reason"
							name="WarrantyClosureReason"
							// value={this.state.name}
							// onChange={this._handleChange}
						/>
					</Col>
                    <Col sm={{ size: 4, order: 4, offset: 4 }}>
					<label>Warranty Closure Reason Description</label>
						<FormInput
							id="description"
							type="text"
							placeholder="Warranty Closure Reason Description"
							name="description"
							// value={this.state.name}
							// onChange={this._handleChange}
						/>
					</Col>
                   
				</Row>
				<Row form>
					<Col md={{ size: 4, order: 4, offset: 4 }} className="mt-3">
						<label htmlFor="feStatus"> Warranty Closure Reason Status</label>
						<FormSelect id="feStatus"  name="status">
							<option  value=''>Choose Status</option>
							<option value="1" >Active</option>
							<option value="0" >Inactive</option>
						</FormSelect>
					</Col>
				</Row>
				<Row>
                <Col sm={{ size: 6, order: 4, offset: 10 }}></Col>
					<Col sm={{ size: 6, order: 4, offset: 10 }}>
						{/* {state.update ? <Button type="submit">Update</Button> : <Button type="submit">Add</Button>} */}

            <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  // onClick={() => {
                  //   props.history.push("/dispute-reasons");
                  // }}
                >
                  Add
                </Button>
					</Col>
          
				</Row>
			
              </CardBody>
            </Card>
          </Col>
        </Row>
    </Container> 
    </>
  );
}




export default AddWarrantyClosureReason;

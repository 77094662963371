import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import ServiceRequest from "../ServiceType/list";
import MaterialTable from "material-table";
import CreateServiceRequest from "../ServiceRequest/CreateServiceRequest";
import ViewServiceReques from "../ServiceRequest/ViewServiceRequest"
import EditServiceRequest from "../ServiceRequest/EditServiceRequest"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";


import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const ViewChats = (props) => {
   
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="View Chat History" />
        <Row>
          <Col lg="12" className="mb-4">
          <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/view-quote-details");
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
              <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                    readonly="true"
                  />
                  
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Id"
                    name="srId"
                    readonly="true"
                  />
                  </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Status"
                    name="srStatus"
                    readonly="true"
                  />
                  </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Service Provider ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Provider ID"
                    name="serviceProviderId"
                    readonly="true"
                  />
                  </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Quote ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote ID"
                    name="quoteID"
                    readonly="true"
                  />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Service Provider Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Service Provider Last Name"
                    type="text"
                    name="SPlastName"
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Service Provider First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="Service Provider First Name"
                    name="SPfistName"
                    readonly="true"
                  />
                </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Quote Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote Status"
                    name="quoteStatus"
                    readonly="true"
                  />
                   </Col>
                 
                
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
            
              <b>Chats List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Message", field: "srId" },
                    { title: "Massage Date and Time", field: "srCreationDate" },
                    { title: "Sent by (Customer or Service Provider ID)", field: "srScheduledDate" }
                   
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                  
                  }}
                  actions={[
                    // {
                    //   icon: "check",
                    //   tooltip: "Save Chats",
                    // },
                    
                    // {
                    //   icon: "edit",
                    //   tooltip: "Edit Chats",
                    // },

                    // {
                    //   icon: "delete",
                    //   tooltip: "Delete Chats",
                    // },
                  ]}
                  editable={{
                    onRowAddCancelled: (rowData) =>
                      console.log("Row adding cancelled"),
                    onRowUpdateCancelled: (rowData) =>
                      console.log("Row editing cancelled"),
                    // onRowAdd: (newData) => new Promise((resolve, reject) => {}),
                    onRowUpdate: (newData, oldData) =>{},
                      // APIService.updateServiceRequest(newData)

                    onRowDelete: (oldData) =>{}
                      // APIService.fetchServiceRequestDelete(oldData.srId),
                  }}
                />
              
              </CardBody>
            </Card>
          </Col>

         

        </Row>
      </Container>
    </div>
  );
};
export default ViewChats;

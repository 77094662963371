import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
import {
  Container,
  ListGroupItem,
  Row,
  Col,
  Button,
  Form,
  FormInput,
  FormSelect,
  InputGroupAddon,
  InputGroup,
} from 'shards-react';
import { APIService } from '../../utils/APIService';
import ImageHandle from './ImageHandle';

function SubAdminAdd(props) {
  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
  });
  const [adminRole, setAdminRole] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    homePhone: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
    profileImage: null,
    drivingLicense: null,
    roleType: '',
    password: '',
  });
  // const handleChangeCountry = (e) => {
  //   setState({ ...state, country: e.target.value });
  //   fetchStateData(e.target.value);
  // }

  useEffect(() => {
    fetchCountryData();
    fetchAdminRole();
  }, []);
  useEffect(() => {
    if (formData.country) {
      fetchStateData(formData.country);
    }
  }, [formData.country]);
  useEffect(() => {
    if (formData.state) {
      fetchCityData(formData.state);
    }
  }, [formData.state]);

  const fetchAdminRole = () => {
    APIService.AdminRole().then((res) => {
      setAdminRole(res);
    });
  };

  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState({
          ...state,
          states: units,
        });
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState({
          ...state,
          cities: units,
        });
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };

  const handleChange = (event) => {
    if (event.target.type === 'file') {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else if (event.target.name === 'roleType') {
      var roleSelected = [];
      if (formData.roleType) {
        roleSelected = formData.roleType.split(',');
      }
      const value = event.target.value;
      if (roleSelected.indexOf(value) !== -1) {
        var index = roleSelected.indexOf(value);
        roleSelected.splice(index, 1);
      } else {
        roleSelected.push(value);
      }
      const roleType = roleSelected.join(',');
      setFormData({ ...formData, roleType: roleType });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleChangeImage = (event) => {
    const name = event.currentTarget.name;
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      setState({ ...state, [name]: image, [name + '_name']: image.name, [name + '_url']: reader.result });
    };
  };

  const handleSendPassword = () => {
    setState({ ...state, passwordSendingLoading: true });
    APIService.sendPassword({ email: formData.email, password: formData.password })
      .then(() => {
        state.passwordSent = true;
      })
      .finally(() => {
        setState({ ...state, passwordSendingLoading: false });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    APIService.addSubAdmin({
      ...formData,
      profile_photo: state.profile_photo,
      driving_license_photo: state.driving_license_photo,
    })
      .then((res) => {
        alert('Created Successfully');
        props.history.push('/sub-admins');
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <>
      <Container fluid className="main-content-container">
        <MainTitle title="Add-Sub-Admin" />
        <ContentHeader>
          <Button
            onClick={() => {
              if (window.confirm('All your changes will be lost. Do you still want to continue?')) {
                props.history.push('/sub-admins');
              }
            }}
            outline
            theme="primary"
            className="mb-2 mr-1"
          >
            Back
          </Button>
        </ContentHeader>
        <ListGroupItem>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col md={{ size: 4, order: 4 }} className="form-group">
                <label htmlFor="feFirstName">First Name*</label>
                <FormInput
                  id="feFirstName"
                  type="text"
                  value={formData.firstName}
                  placeholder="First Name"
                  name="firstName"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 4, order: 4 }} className="form-group">
                <label htmlFor="feMiddleName">Middle Name</label>
                <FormInput
                  id="feMiddleName"
                  placeholder="Middle Name"
                  type="text"
                  value={formData.middleName}
                  name="middleName"
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 4, order: 4 }} className="form-group">
                <label htmlFor="feLastName">Last Name*</label>
                <FormInput
                  id="feLastName"
                  placeholder="Last Name"
                  type="text"
                  value={formData.lastName}
                  name="lastName"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={{ size: 4, order: 4 }} className="form-group">
                <label htmlFor="feEmail">Email ID*</label>
                <FormInput
                  id="feEmail"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  name="email"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 4, order: 4 }} className="form-group">
                <label htmlFor="feMobile">Mobile Phone*</label>
                <FormInput
                  id="feMobile"
                  type="tel"
                  value={formData.mobilePhone}
                  placeholder="Mobile Phone"
                  name="mobilePhone"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 4, order: 4 }} className="form-group ">
                <label htmlFor="feMobile">Home Phone*</label>
                <FormInput
                  id="feMobile"
                  value={formData.homePhone}
                  type="text"
                  placeholder="Home Phone"
                  name="homePhone"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group ">
                <label htmlFor="feAddress">Address 1*</label>
                <FormInput
                  id="feAddress"
                  value={formData.address1}
                  type="text"
                  placeholder="Address Line 1"
                  name="address1"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 6, order: 4 }} className="form-group ">
                <label htmlFor="feAddress2">Address 2</label>
                <FormInput
                  id="feAddress2"
                  type="text"
                  value={formData.address2}
                  placeholder="Address Line 2"
                  name="address2"
                  onChange={handleChange}
                />
              </Col>
              <Col md={{ size: 3, order: 4 }} className="form-group">
                <label htmlFor="feCountry">Country</label>
                <FormSelect id="feCountry" name="country" value={formData.country} onChange={handleChange} required>
                  <option value={0}>country</option>
                  {state.countries.map((country, i) => (
                    <option value={country.id} key={i} selected={state.country == country.id}>
                      {country.name}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col md={{ size: 3, order: 4 }} className="form-group">
                <label htmlFor="feState"> Select State</label>
                <FormSelect id="feState" name="state" value={formData.state} onChange={handleChange} required>
                  <option value={0}>state</option>
                  {state.states.map((selectedState, i) => (
                    <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                      {selectedState.name}
                    </option>
                  ))}
                </FormSelect>
              </Col>
              <Col md={{ size: 3, order: 4 }} className="form-group">
                <label htmlFor="feCity">City</label>
                <FormSelect id="feCity" name="city" value={formData.city} onChange={handleChange} required>
                  <option value={0}>city</option>
                  {state.cities.map((city, i) => (
                    <option value={city.id} key={i} selected={state.city == city.id}>
                      {city.name}
                    </option>
                  ))}
                </FormSelect>
              </Col>
              <Col md={{ size: 3, order: 4 }} className="form-group ">
                <label htmlFor="feZip">Zip Code*</label>
                <FormInput
                  id="feZip"
                  type="text"
                  placeholder="Zip Code"
                  name="zipCode"
                  value={formData.zipCode}
                  required
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={{ size: 4, order: 4 }} className="form-group ">
                <label htmlFor="fepassword">Password*</label>
                <InputGroup>
                  <FormInput
                    id="fepassword"
                    type="text"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                    }}
                    value={formData.password}
                    disabled={state.passwordSent}
                    required
                  />
                  <InputGroupAddon type="append">
                    <Button
                      type="button"
                      disabled={state.passwordSent}
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          password: Math.random().toString(36).substring(2),
                        });
                      }}
                    >
                      {state.passwordSent ? 'Password Sent!' : 'Generate Password'}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={{ size: 3, order: 4, offset: 4 }} className="form-group mt-4 ">
                {!state.passwordSent && (
                  <Button
                    outline
                    theme="primary"
                    onClick={handleSendPassword}
                    disabled={!formData.password || !formData.email || state.passwordSendingLoading}
                  >
                    {state.passwordSendingLoading ? <span class="spinner-border" /> : 'Send Password'}
                  </Button>
                )}
              </Col>

              <ImageHandle name="profile" title="Profile Image" state={state} handleChange={handleChangeImage} />

              <Col md={{ size: 10, order: 4 }} className="form-group mt-3"></Col>

              <ImageHandle
                name="driving_license"
                title="Driving License Image"
                state={state}
                handleChange={handleChangeImage}
              />
            </Row>

            <div>
              <Row>
                <Col lg="12" md={{ size: 6, order: 4 }} className="mb-6 p-5" style={{ textAlign: 'center' }}>
                  <h4>
                    <b>GRANT MODULE ACCESS </b>
                  </h4>
                </Col>
              </Row>

              <Col>
                <FormGroup row>
                  {adminRole.map((item, index) => {
                    return (
                      <Col key={index} md={{ size: 4, order: 4 }} className="form-group">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.id}
                              // checked={state.checkedB}
                              onChange={handleChange}
                              name="roleType"
                              color="primary"
                            />
                          }
                          label={`${item.role_type}(${item.role_code})`}
                        />
                      </Col>
                    );
                  })}
                </FormGroup>
              </Col>
            </div>

            <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group ">
              <Button outline theme="primary" className="mb-2 mr-1" disabled={state.loading}>
                {state.loading ? <span class="spinner-border" /> : 'Add'}
              </Button>
            </Col>
          </Form>
        </ListGroupItem>
      </Container>
    </>
  );
}
export default SubAdminAdd;

import React, { useState } from 'react';
import { Container, ListGroup, ListGroupItem, Row } from 'shards-react';
import CustBotton from '../../../ServiceRequest/CustButton';
import BusinessLicense from './BusinessLicense';
import CompanyInfo from './CompanyInfo';
import DriverLicense from './DriverLicense';
import ProfileInfo from './ProfileInfo';

function ViewEditAdditional(props) {
  const [state, setState] = useState({
    viewDriverLicense: true,
    viewProfileInfo: false,
    viewBusinessLicense: false,
    viewCompanyInfo: false,
  });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton
            state={state}
            setState={setState}
            buttons={{
              viewDriverLicense: "Driver's License",
              viewProfileInfo: 'Profile Information',
              viewBusinessLicense: 'Business License',
              viewCompanyInfo: 'Company Information',
            }}
          />
        </ListGroupItem>
      </ListGroup>

      {state.viewDriverLicense && (
        <DriverLicense isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.viewProfileInfo && (
        <ProfileInfo isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.viewBusinessLicense && (
        <BusinessLicense isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.viewCompanyInfo && (
        <CompanyInfo isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default ViewEditAdditional;

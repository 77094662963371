import React, { useState, useEffect } from "react";

import CustBotton, { ButtonClick } from "../ServiceRequest/CustButton";
import { APIService } from "../../utils/APIService";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from "shards-react";

function VideoUploads() {
    const useStyles = makeStyles((theme) => ({
      root: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
          margin: theme.spacing(1),
          width: theme.spacing(14),
          height: theme.spacing(14),
          boxShadow: "5px 5px 5px #aaaaaa",
        },
      },
    }));
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <CardMedia
          component="iframe"
          title="test"
          src="https://www.youtube.com/embed/bqzlm565CnA"
        />
        <CardMedia
          component="iframe"
          title="test"
          src="https://www.youtube.com/embed/VIDEO_ID"
        />
        <CardMedia
          component="iframe"
          title="test"
          src="https://www.youtube.com/embed/VIDEO_ID"
        />
  
        <CardMedia
          component="iframe"
          title="test"
          src="https://www.youtube.com/embed/VIDEO_ID"
        />
      </div>
    );
  }
  
  
  export default VideoUploads;
import React, { useState,useEffect } from "react";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";



import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";


import ViewComments from "../QuoteManagement/ViewComments";

function WarrantyComment(props){
    return(
        <>
          <Container fluid className="main-content-container">
        <MainTitle title="Warranty Comment" />
        <Row>
          <Col lg="12">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/warranty-dispute-mgt");
                }}
                outline
                theme="primary"
                className="mb-2 mr-1" >
                Back
              </Button>
            
            </ContentHeader>
          
       
            </Col>
            <ViewComments/>
        </Row>
      </Container>

        </>
      
        
    )
}
export default WarrantyComment;
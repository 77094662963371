import React, { useState, useEffect } from 'react';
import { APIService } from '../../utils/APIService';
import Calender, { SelectDate } from './Calender';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import ServiceRequest from '../ServiceType/list';
import MaterialTable from 'material-table';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Applianes, { MovingService, AutoService, FoodService, LegalService } from '../UserManagemnet/Additional';
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from 'shards-react';

const CreateServiceRequest = (props) => {
  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [formData, setFormData] = useState({
    customerID: '',
    lastName: '',
    fistName: '',
    addressType: 'home',
    address_1: '',
    address_2: '',
    country_id: '',
    state_id: '',
    city: '',
    zip_code: '',
    date: null,
    service_time: '',
    category: '',
    subCategory: '',
    serviceType: '',
    title: '',
    description: '',
    profile_image: null,
  });
  useEffect(() => {
    fetchCountryData();
    _fetchListData();
    _fetchCategoryData();
  }, []);
  useEffect(() => {
    if (formData.category) {
      _fetchSubCategoryData();
    }
  }, [formData.category]);
  useEffect(() => {
    if (formData.subCategory) {
      _fetchServiceType();
    }
  }, [formData.subCategory]);
  useEffect(() => {
    console.log(formData, 'formDatta');
  }, [formData]);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const handleSubmit = () => {
    APIService.addServiceRequest(formData)
      .then((res) => {
        console.log(res, 'result');
      })
      .catch((error) => alert(error.errorMessage || error.message));
  };
  const _fetchServiceType = (subCategory) => {
    APIService.fetchServiceType(subCategory).then(
      (units) => {
        setServiceTypes(units);
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };
  const _fetchSubCategoryData = (catId) => {
    APIService.fetchSubCategory(catId).then((res) => setSubCategories(res));
  };
  const _fetchListData = () => {
    APIService.fetchAllTimeSlots().then(
      (units) => {
        units.forEach((element) => {
          element.show_time =
            moment('Mon 03-Jul-2017, ' + element.start_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A') +
            '-' +
            moment('Mon 03-Jul-2017, ' + element.end_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A');
        });

        setTimeSlots(units);
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const _fetchCategoryData = () => {
    APIService.fetchAllCategories().then(
      (units) => {
        setCategories(units.data);
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    if (!event.target) {
      setFormData({ ...formData, date: event });
    } else if (event.target.type === 'file') {
      var blobArr = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const blob = URL.createObjectURL(event.target.files[i]);
        blobArr.push(blob);
      }
      setImagesUrl(blobArr);
      setFormData({ ...formData, [event.target.name]: event.target.files });
    } else {
      if (event.target.name === 'subCategory') {
        _fetchServiceType(event.target.value);
      } else if (event.target.name === 'category') {
        _fetchSubCategoryData(event.target.value);
      }
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  function RadioButton() {
    return (
      <FormControl component="fieldset">
        <RadioGroup aria-label="address" name="addressType" value={formData.addressType} onChange={handleChange} row>
          <FormControlLabel value="home" control={<Radio color="primary" />} label="Home" />
          <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Create Service Request" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push('/service-request-mgt');
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>Customer ID</label>
                  <FormInput
                    id="feCustomerID"
                    placeholder="Customer ID"
                    type="text"
                    name="customerID"
                    value={formData.customerID}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                    value={formData.fistName}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 12, order: 4 }} className="p-3">
                  <h4>Service Address</h4>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <RadioButton />
                </Col>

                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                    value={formData.address_1}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 2</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 2"
                    name="address_2"
                    value={formData.address_2}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feCountry">Country</label>
                  <FormSelect
                    id="feCountry"
                    name="country_id"
                    onChange={(e) => {
                      handleChange(e);
                      fetchStateData(e.target.value);
                    }}
                    required
                    value={formData.country_id}
                  >
                    <option value={0}>country</option>
                    {state.countries.map((item, i) => (
                      <option value={item.id} key={i} selected={state.country == item.id}>
                        {item.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feState"> State</label>
                  <FormSelect
                    id="feState"
                    onChange={(e) => {
                      handleChange(e);
                      fetchCityData(e.target.value);
                    }}
                    name="state_id"
                    required
                  >
                    <option value={0}>state</option>
                    {state.states.map((selectedState, i) => (
                      <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                        {selectedState.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity" name="city" value={formData.city} onChange={handleChange} required>
                    <option value={0}>city</option>
                    {state.cities.map((city, i) => (
                      <option value={city.id} key={i} selected={state.city == city.id}>
                        {city.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={{ size: 12, order: 4 }}>
                  <h4>Expected Service Date and Time</h4>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <SelectDate
                    name="date"
                    label="Service Date"
                    handleChange={handleChange}
                    selectedDate={formData.date}
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feTime">Service Time</label>
                  <FormSelect
                    id="feTime"
                    name="service_time"
                    required
                    value={formData.service_time}
                    onChange={handleChange}
                  >
                    <option value="">Choose</option>
                    {timeSlots.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.show_time}
                        </option>
                      );
                    })}
                  </FormSelect>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Service</h4>
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory" name="category" value={formData.category} onChange={handleChange}>
                    <option value="">Choose</option>
                    {categories.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category_name}
                        </option>
                      );
                    })}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser" name="subCategory" value={formData.subCategory} onChange={handleChange}>
                    <option value="">Choose</option>
                    {subCategories.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.sub_category_name}
                        </option>
                      );
                    })}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Type </label>
                  <FormSelect id="feUser" name="serviceType" value={formData.serviceType} onChange={handleChange}>
                    <option>Choose</option>
                    {serviceTypes.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </FormSelect>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Request Title </h4>

                  <FormTextarea
                    id="feTitle"
                    type="textarea"
                    placeholder="Provide high level summary of your request.
                      Max 50 Characters"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    maxLength="50"
                  />
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Request Description</h4>

                  <FormTextarea
                    style={{ height: '110px' }}
                    id="feDescription"
                    type="text"
                    placeholder="Provide a brief description of the request and all the relevent details to get the most accurate quote.   
                      Max 1000 Characters"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    maxLength="1000"
                  />
                  {formData.category == 1 && formData.subCategory == 4 && (
                    <Row>
                      <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                        <h4>Additional</h4>
                        <Applianes />
                      </Col>
                    </Row>
                  )}
                  {(formData.category == 4 || formData.category == 5) && (
                    <Row>
                      <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                        <h4>Additional</h4>
                        <MovingService />
                      </Col>
                    </Row>
                  )}
                  {formData.category == 6 && (
                    <Row>
                      <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                        <h4>Additional</h4>
                        <AutoService />
                      </Col>
                    </Row>
                  )}
                  {formData.category == 7 && (
                    <Row>
                      <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                        <h4>Additional</h4>
                        <FoodService />
                      </Col>
                    </Row>
                  )}
                  {formData.category == 3 && (
                    <Row>
                      <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                        <h4>Additional</h4>
                        <LegalService />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={{ size: 4, order: 4 }} className="form-group mt-4">
                      <label>Choose Image and videos..</label>
                      <div className="custom-file mb-3">
                        <FormInput
                          type="file"
                          className="custom-file-input"
                          id="profile_image"
                          name="profile_image"
                          onChange={handleChange}
                          multiple
                        />
                        <label className="custom-file-label" htmlFor="profile_image">
                          Choose Image...
                        </label>
                      </div>
                    </Col>
                    <Col md={{ size: 10, order: 4 }} className="form-group">
                      <CardBody className=" mt-5 mb-5">
                        <Col>
                          <div style={{ display: 'flex' }}>
                            {imagesUrl.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    width: '200px',
                                    height: '200px',
                                    border: '1px solid #c4c4c4',
                                    marginRight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <img key={index} src={item} width="100%" id={`profile_image${index}`} />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </CardBody>
                    </Col>
                  </Row>

                  <Col sm={{ size: 6, order: 6, offset: 10 }} className="mb-5">
                    <Button theme="primary" onClick={handleSubmit} type="buttom" classNmae="p-1">
                      Add
                    </Button>
                  </Col>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

function ViewPhotoImageUpload({ sources, deleteImage }) {
  const [currentDeletes, setCurrentDeletes] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(15),
        height: theme.spacing(15),
        // boxShadow: '5px 5px 5px #aaaaaa',
      },
    },
    imageWrapper: {
      position: 'relative',
    },
    iconWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Object.entries(sources).map(([fileName, source]) => (
        <div className={classes.imageWrapper}>
          {deleteImage && (
            <div className={classes.iconWrapper}>
              {currentDeletes.includes(fileName) ? (
                <span class="spinner-border" />
              ) : (
                <i
                  class="material-icons"
                  onClick={() => {
                    deleteImage(fileName);
                    setCurrentDeletes([...currentDeletes, fileName]);
                  }}
                  style={{
                    cursor: 'pointer',
                    background: 'black',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                >
                  clear
                </i>
              )}
            </div>
          )}

          <img src={source.url} width="100%" height="100%" alt="profile" className={classes.media} />
        </div>
      ))}
    </div>
  );
}
export default CreateServiceRequest;
export { ViewPhotoImageUpload };

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';
import { Row, Col, Container, Card, CardBody } from 'shards-react';

function ViewComments({ quoteId }) {
  const [comments, setComments] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setComments();
    const serviceReq = async () => {
      const response = await APIService.fetchQuoteComments(quoteId);
      setComments(
        response.comments.map((item) => {
          return {
            id: item.id,
            description: item.comment,
            commentDateTime: item.created_at,
            commentFrom: item.user,
            // userType: item.user && item.user.user_type,
          };
        })
      );
    };
    serviceReq();
  }

  return (
    <>
      <Container fluid className="main-content-container px-3">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  isLoading={!comments}
                  title=""
                  columns={[
                    { title: 'ID', field: 'id', editable: 'never' },
                    { title: 'Comments Description', field: 'description' },
                    { title: 'Comment Date and Time', field: 'commentDateTime', editable: 'never' },
                    { title: 'Comment From', field: 'commentFrom' },
                    // { title: 'User Type (Customer/Service Provider)', field: 'userType' },
                  ]}
                  data={comments}
                  options={{ search: true, actionsColumnIndex: -1, exportButton: true }}
                  localization={{
                    body: { editRow: { deleteText: `Are you sure you want to delete this Comment?` } },
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        APIService.updateQuoteComment(oldData.id, newData)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        APIService.deleteQuoteComment(oldData.id)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ViewComments;

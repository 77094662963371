import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import TncGifs from './gifs';
import TncTexts from './texts';

function Tnc(props) {
  const [state, setState] = useState({ gifs: true, texts: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ gifs: 'GIFs', texts: 'Texts' }} />
        </ListGroupItem>
      </ListGroup>

      {state.gifs && (
        <TncGifs isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.texts && (
        <TncTexts isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default Tnc;

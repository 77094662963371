import React, { useState, useEffect } from 'react';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CustBottom, { ButtonClick } from '../ServiceRequest/CustButton';
import { APIService } from '../../utils/APIService';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from 'shards-react';
import { useHistory, useParams } from 'react-router-dom';

const ViewWarrantyRequestList = (props) => {
  const [srRequest, setSrRequest] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = async () => {
      const response = await APIService.fetchWarrantyServiceForSr(props.srId);

      setSrRequest(
        response.map((item) => {
          return {
            id: item.id,
            warrantyDetails: item.details,
            warrantyCreationDate: item.warranty_creation_date?.split('T')[0],
            srId: item.request_id,
            quoteId: item.quote_id,
            // customerId: item,
            serviceProviderId: item.provider_id,
            warrantyClosureDate: item.warranty_closure_date?.split('T')[0] || '',
            warrantyExpiryDate: item.warranty_expiration_date?.split('T')[0] || '',
            warrantyClosureComments: item.closure_comments,
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <div>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <MaterialTable
                isLoading={!srRequest}
                title=""
                columns={[
                  { title: 'ID', field: 'id' },
                  { title: 'Warranty Details', field: 'warrantyDetails' },
                  { title: 'Warranty Creation Date', field: 'warrantyCreationDate' },
                  { title: 'SR ID', field: 'srId' },
                  { title: 'Quote Id', field: 'quoteId' },
                  // { title: 'Customer ID', field: 'customerId' },
                  { title: 'Service Provider ID', field: 'serviceProviderId' },
                  { title: 'Warranty Closure Date', field: 'warrantyClosureDate' },
                  { title: 'Warranty Expiry Date', field: 'warrantyExpiryDate' },
                  { title: 'Warranty Closure Comments', field: 'warrantyClosureComments' },
                ]}
                data={srRequest}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  // exportCsv: (columns, data) => {
                  //   alert(
                  //     "You should develop a code to export " +
                  //       data.length +
                  //       " rows"
                  //   );
                  // },
                }}
                // actions={[
                //   {
                //     icon: 'chat',
                //     tooltip: 'View Chats',
                //     onClick: (event, data) => {
                //       console.log(data);
                //       console.log(event);

                //       props.history.push('/view-chats');
                //     },
                //   },
                //   {
                //     icon: 'comment',
                //     tooltip: 'View Comment',
                //     onClick: (event, data) => {
                //       console.log(data);
                //       console.log(event);
                //       props.history.push('/view-comments');
                //     },
                //   },
                // ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewWarrantyRequestList;

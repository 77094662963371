export const ROLE_TYPES = { 1: 'Customer', 2: 'Provider', 3: 'Both' };
export const REVERSE_ROLE_TYPES = { Customer: 1, Provider: 2, Both: 3 };

export const USER_STATUS = { 0: 'Inactive', 1: 'Active' };
export const REVERSE_USER_STATUS = { Inactive: 0, Active: 1 };

export const SR_STATUSES = {
  Submitted: 1,
  Cancelled: 2,
  'Quote Received': 3,
  Confirmed: 4,
  Started: 5,
  Completed: 6,
  Pending: 7,
  'Past Due': 8,
  Closed: 9,
  Revisit: 10,
  Warranty: 11,
  Dispute: 12,
  'Warranty Closed': 13,
  'Dispute Closed': 14,
  Disabled: 15,
};

export const QUOTE_STATUSES = {
  Submitted: 1,
  'Accepted By Customer': 2,
  Confirmed: 3,
  'Withdrawn by SP': 4,
  'Rejected by Customer': 5,
  Disabled: 6,
};

export const DISPUTE_REASONS = {
  1: 'Work was not delivered',
  2: 'Work was partially completed',
  3: 'Quality concerns',
  4: 'Damages made',
  5: 'Not satisfied with delivery',
};

export const REVERSE_DISPUTE_REASONS = {
  'Work was not delivered': 1,
  'Work was partially completed': 2,
  'Quality concerns': 3,
  'Damages made': 4,
  'Not satisfied with delivery': 5,
};

export const EMAIL_REGEX =
  /(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/gi;

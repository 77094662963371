import React, { useState, useEffect } from 'react';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';

import { Row, Col, Container, Card, CardBody } from 'shards-react';

function CommissionTax(props) {
  const [commission, setCommission] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setCommission();
    const serviceReq = async () => {
      const response = await APIService.fetchAllcommissionTax();
      setCommission(
        response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            code: item.code,
            value: item.value,
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <MainTitle title="Commission, Tax, Payment Factor" />
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <CardBody className="p-0 pb-3">
                  <MaterialTable
                    isLoading={!commission}
                    title=""
                    columns={[
                      { title: 'ID', field: 'id', editable: 'never' },
                      { title: 'Charge Factor Name', field: 'name', editable: 'onAdd' },
                      { title: 'Charge Factor Code', field: 'code', editable: 'onAdd' },
                      { title: 'Charge Factor Value %', field: 'value', type: 'numeric' },
                    ]}
                    data={commission}
                    options={{ search: true, exportButton: true, actionsColumnIndex: -1 }}
                    editable={{
                      onRowAdd: (newData) =>
                        new Promise((resolve) => {
                          APIService.addcommissionTax(newData)
                            .then(getService)
                            .then(resolve)
                            .catch((error) => alert(error.errorMessage || error.message));
                        }),

                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          APIService.updatecommissionTax(oldData.id, newData)
                            .then(getService)
                            .then(resolve)
                            .catch((error) => alert(error.errorMessage || error.message));
                        }),

                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          APIService.deletecommissionTax(oldData.id)
                            .then(getService)
                            .then(resolve)
                            .catch((error) => alert(error.errorMessage || error.message));
                        }),
                    }}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CommissionTax;

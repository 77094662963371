import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import AboutEzunctionGifs from './gifs';
import AboutEzunctionTexts from './texts';
import AboutEzunctionVideos from './videos';

function AboutEzunction(props) {
  const [state, setState] = useState({ gifs: true, texts: false, videos: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ gifs: 'GIFs', texts: 'Texts', videos: 'Videos' }} />
        </ListGroupItem>
      </ListGroup>

      {state.gifs && (
        <AboutEzunctionGifs isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.texts && (
        <AboutEzunctionTexts isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.videos && (
        <AboutEzunctionVideos isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default AboutEzunction;

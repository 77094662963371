import React, { useState, useEffect } from 'react';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';
import ContentHeader from '../../components/common/ContentHeader';
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
} from 'shards-react';
import { ROLE_TYPES, REVERSE_ROLE_TYPES, USER_STATUS, REVERSE_USER_STATUS } from '../../data/constants';

const UserManagementMain = (props) => {
  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
    ...(props.location?.state || {}),
  });
  const [loading, setloading] = useState(false);
  const [srRequest, setSrRequest] = useState();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchCountryData();
    getService();
  }, []);

  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  // const handleChange = (event) => {
  //   if (event.target.checked)
  //     setState({ ...state, [event.target.name]: event.target.checked });
  //   else setState({ ...state, [event.target.name]: event.target.value });
  // };

  const getService = () => {
    setloading(true);
    APIService.fetchUserMgt({ queryParams: filters })
      .then((response) => {
        setSrRequest(
          response.map((item) => {
            return {
              id: item.id,
              firstName: item.first_name,
              middleName: item.middle_name,
              lastName: item.last_name,
              emailId: item.email,
              mobile: item.phone,
              userId: item.id,
              status: USER_STATUS[parseInt(item.status)] || '',
              userType: ROLE_TYPES[parseInt(item.role_type)] || '',
            };
          })
        );
        setloading(false);
      })
      .catch(() => {});
  };

  const handleChange = (event) => {
    let { name, value } = event.currentTarget;

    const customizeKeys = ['userStatus', 'userType'];
    const customizeValues = {
      userStatus: REVERSE_USER_STATUS,
      userType: REVERSE_ROLE_TYPES,
    };
    if (customizeKeys.includes(name)) value = customizeValues[name][value];

    setFilters({ ...filters, [name]: value });
  };

  const dismissAlert = () => {
    setState({ ...state, visible: false });
  };

  return (
    <>
      {state.visible && (
        <Container fluid className="px-0">
          <Alert theme={state.alertStyle || 'primary'} dismissible={dismissAlert} open={state.visible} className="mb-0">
            <i className={state.alertIcon} /> {state.alertMessage}
          </Alert>
        </Container>
      )}
      <div>
        <Container fluid className="main-content-container">
          <MainTitle title="User Management" />
          <Row>
            <Col lg="12" className="mb-4">
              <ContentHeader>
                <Button
                  onClick={() => {
                    props.history.push('/users-new-personal');
                  }}
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                >
                  Add User
                </Button>
              </ContentHeader>
              <ListGroupItem>
                <Row>
                  <Col md={{ size: 1, order: 2 }}>
                    <label>User Type</label>
                    <FormSelect id="feUser" name="userType" onChange={handleChange}>
                      <option>Choose</option>
                      <option>Customer</option>
                      <option>Provider</option>
                      <option>Both</option>
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                    <label htmlFor="feLastName">Last Name</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                    <label htmlFor="feFirstName">First Name</label>
                    <FormInput
                      id="feFirstName"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={{ size: 2, order: 4 }}>
                    <label htmlFor="feMobile">Mobile #</label>
                    <FormInput id="feMobile" type="number" placeholder="Mobile" name="mobile" onChange={handleChange} />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                    <label>Email ID</label>
                    <FormInput id="feEmail" type="email" placeholder="Email" name="email" onChange={handleChange} />
                  </Col>
                  <Col md={{ size: 3, order: 4 }} className="form-group">
                    <label htmlFor="feCountry">Country</label>
                    <FormSelect
                      id="feCountry"
                      name="countryId"
                      onChange={(e) => {
                        fetchStateData(e.target.value);
                        handleChange(e);
                      }}
                      required
                    >
                      <option value={0}>country</option>
                      {state.countries.map((country, i) => (
                        <option value={country.id} key={i} selected={state.country == country.id}>
                          {country.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  <Col md={{ size: 3, order: 4 }} className="form-group">
                    <label htmlFor="feState"> State</label>
                    <FormSelect
                      id="feState"
                      name="stateId"
                      onChange={(e) => {
                        fetchCityData(e.target.value);
                        handleChange(e);
                      }}
                      required
                    >
                      <option value={0}>state</option>
                      {state.states.map((selectedState, i) => (
                        <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                          {selectedState.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 3, order: 4 }} className="form-group">
                    <label htmlFor="feCity">City</label>
                    <FormSelect
                      id="feCity"
                      value={state.city}
                      name="cityId"
                      onChange={(e) => {
                        setState({ ...state, city: e.target.value });
                        handleChange(e);
                      }}
                      required
                    >
                      <option value={0}>city</option>
                      {state.cities.map((city, i) => (
                        <option value={city.id} key={i} selected={state.city == city.id}>
                          {city.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                    <label htmlFor="feZip">Zip Code</label>
                    <FormInput id="feZip" type="text" placeholder="Zip Code" name="zipCode" onChange={handleChange} />
                  </Col>

                  <Col md={{ size: 3, order: 4 }}>
                    <label htmlFor="feAddress">Address Line 1</label>
                    <FormInput
                      id="feAddress"
                      type="text"
                      placeholder="Address Line 1"
                      name="address"
                      onChange={handleChange}
                    />
                  </Col>

                  <Col md={{ size: 2, order: 4 }}>
                    <label htmlFor="feSrStatus">Status</label>
                    <FormSelect id="feState" name="userStatus" onChange={handleChange}>
                      <option>Choose</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                    <label htmlFor="feSrStatus">User ID</label>
                    <FormInput
                      id="feUserId"
                      type="number"
                      placeholder="User ID"
                      name="userId"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4, offset: 9 }} className="form-group p-3">
                    <Button
                      outline
                      theme="primary"
                      className="mb-2 mr-4"
                      onClick={getService}
                      disabled={!Object.keys(filters).length}
                    >
                      Apply Filter
                    </Button>
                    {!!Object.keys(filters).length && (
                      <Button
                        outline
                        theme="danger"
                        className="mb-2 mr-1"
                        onClick={() => {
                          window.location.reload();
                        }}
                        disabled={!Object.keys(filters).length}
                      >
                        Clear Filter
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            </Col>
          </Row>
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row>
            <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
              <h4>
                <b>User List </b>
              </h4>
            </Col>
            <Col lg="12" className="mb-6"></Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <MaterialTable
                    isLoading={loading}
                    title=""
                    columns={[
                      { title: 'ID', field: 'id' },
                      { title: 'First Name', field: 'firstName' },
                      { title: 'Middle Name', field: 'middleName' },
                      { title: 'Last Name', field: 'lastName' },
                      { title: 'Email ID', field: 'emailId' },
                      { title: 'Mobile Phone#', field: 'mobile' },
                      { title: 'User Id', field: 'userId' },
                      { title: 'Status', field: 'status' },
                      { title: 'User Type', field: 'userType' },
                    ]}
                    data={srRequest}
                    options={{
                      search: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      // paging:false
                    }}
                    actions={[
                      {
                        icon: 'visibility',
                        tooltip: 'User Details',
                        onClick: (event, data) => {
                          props.history.push({ pathname: '/view-edit-user/' + data.id, state: { isEdit: false } });
                        },
                      },
                      {
                        icon: 'edit',
                        tooltip: 'Edit User',
                        onClick: (event, data) => {
                          props.history.push({ pathname: '/view-edit-user/' + data.id, state: { isEdit: true } });
                        },
                      },
                    ]}
                    localization={{
                      body: { editRow: { deleteText: `Are you sure you want to activate/deactivate this User?` } },
                    }}
                    editable={{
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          APIService.userActivateDeactivate(oldData.id).then(getService).then(resolve);
                        }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default UserManagementMain;

import React, { useState, useEffect } from 'react';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import CustBotton from './CustButton';
import { ViewPhotoImageUpload } from '../ServiceRequest/CreateServiceRequest';
import ViewQuotesList from '../ViewList/ViewQuotesList';
import ViewWarrantyRequestList from '../ViewList/ViewWarrantyRequestList';
import ViewDisputeRequestList from '../ViewList/ViewDisputeRequestList';
import { useParams } from 'react-router-dom';
import Appliances from '../UserManagemnet/Additional';
import { APIService } from '../../utils/APIService';
import { ListGroupItem, Row, Col, FormInput, FormSelect, Container, Button, FormTextarea } from 'shards-react';
import { SelectDate } from './Calender';
import config from '../../data/config';
import { SR_STATUSES } from '../../data/constants';

const EditServiceRequest = (props) => {
  const [state, setState] = React.useState({
    viewQuotesList: true,
    ViewWarrantyRequestList: false,
    ViewDisputeRequestList: false,
    checkedA: true,
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [imagesUrl, setImagesUrl] = useState({});
  const [viewImagesUrl, setViewImagesUrl] = useState({});
  const [formData, setFormData] = useState({
    customerID: '',
    SrID: '',
    lastName: '',
    firstName: '',
    srStatus: '',
    actionReason: '',
    actionComment: '',
    addressType: '',
    address_1: '',
    address_2: '',
    category: '',
    country: '',
    state: '',
    city: '',
    subCategory: '',
    serviceType: '',
    service_date: '',
    service_time: '',
    title: '',
    description: '',
    zip_code: '',
    cust_noshow_comments: '',
  });
  const [initialState, setInitialState] = useState({});
  const [updates, setUpdates] = useState({});
  const { srId } = useParams();

  useEffect(() => {
    fetchCountryData();
    getSr();
    _fetchListData();
    _fetchCategoryData();
  }, []);
  useEffect(() => {
    if (formData.country) {
      fetchStateData(formData.country);
    }
  }, [formData.country]);
  useEffect(() => {
    if (formData.state) {
      fetchCityData(formData.state);
    }
  }, [formData.state]);
  useEffect(() => {
    if (formData.category) {
      _fetchSubCategoryData(formData.category);
    }
  }, [formData.category]);
  useEffect(() => {
    if (formData.subCategory) {
      _fetchServiceType(formData.subCategory);
    }
  }, [formData.subCategory]);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        setCountries(units);
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setStates(units);
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const _fetchListData = () => {
    APIService.fetchAllTimeSlots().then(
      (units) => {
        units.forEach((element) => {
          element.show_time =
            moment('Mon 03-Jul-2017, ' + element.start_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A') +
            '-' +
            moment('Mon 03-Jul-2017, ' + element.end_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A');
        });

        setTimeSlots(units);
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setCities(units);
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const _fetchCategoryData = () => {
    APIService.fetchAllCategories().then(
      (units) => {
        setCategories(units.data);
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };
  const handleChange = (event) => {
    const { name, files, type, value } = event.currentTarget;
    if (!event.target) {
      setFormData({ ...formData, date: event });
    } else if (type === 'file') {
      const blobArr = { ...imagesUrl };
      for (let i = 0; i < files.length; i++) {
        const blob = URL.createObjectURL(files[i]);
        blobArr[files[i].name] = { url: blob, file: files[i] };
      }
      setImagesUrl(blobArr);
    } else {
      if (name === 'subCategory') {
        _fetchServiceType(value);
      } else if (name === 'category') {
        _fetchSubCategoryData(value);
      } else if (name === 'addressType') {
        formData.address_1 = initialState.address_1;
        formData.address_2 = initialState.address_2;
        formData.city = initialState.city;
        formData.state = initialState.state;
        formData.country = initialState.country;
        formData.zip_code = initialState.zip_code;

        updates.address_1 = initialState.address_1;
        updates.address_2 = initialState.address_2;
        updates.city = initialState.city;
        updates.state = initialState.state;
        updates.country = initialState.country;
        updates.zip_code = initialState.zip_code;
      }
      setFormData({ ...formData, [name]: value });
      setUpdates({ ...updates, [name]: value });
    }
  };

  const handleSubmit = () => {
    setState({ ...state, loading: true });
    APIService.fetchServiceRequestUpdate({ SrID: initialState.SrID, ...updates, images: imagesUrl })
      .then(getSr)
      .catch((error) => alert(error.errorMessage || error.message))
      .finally(() => {
        setState({ ...state, loading: false });
      });
  };

  const _fetchServiceType = (subCategory) => {
    APIService.fetchServiceType(subCategory).then(
      (units) => {
        setServiceTypes(units);
      },
      (error) => setState({ ...state, internetConnected: false })
    );
  };
  const _fetchSubCategoryData = (catId) => {
    APIService.fetchSubCategory(catId).then((res) => setSubCategories(res));
  };

  async function getSr() {
    setFormData({});
    setInitialState({});
    setViewImagesUrl({});
    const response = await APIService.fetchServiceRequestById(srId);
    const data = response[0];
    const stateToSet = {
      customerID: data.user_id,
      SrID: data.id,
      lastName: data.user && data.user.last_name,
      firstName: data.user && data.user.first_name,
      addressType: String(data.address_type),
      address_1: data.address_1,
      address_2: data.address_2,
      country: data.country,
      state: data.state,
      city: data.city,
      srStatus: data.sr_status,
      actionReason: '',
      actionComment: '',
      service_date: data.service_date.split('-').reverse().join('-'),
      service_time: data.time_slot,
      category: data.category.id,
      subCategory: data.sub_category.id,
      serviceType: data.serviceType.id,
      title: data.title,
      description: data.description,
      zip_code: data.zip_code,
      appliance_make: data.addtionalDetail?.appliance_make || '',
      appliance_model: data.addtionalDetail?.appliance_model || '',
      appliance_year: data.addtionalDetail?.appliance_year || '',
      cust_noshow_comments: data.cust_noshow_comments,
    };
    setFormData({ ...stateToSet });
    setInitialState({ ...stateToSet });

    const fetchedImageUrls = {};
    data.files.forEach((elem) => {
      fetchedImageUrls[elem.file] = { id: elem.id, url: config.endpoints.image_path + elem.file };
    });

    setViewImagesUrl({ ...fetchedImageUrls });
    setImagesUrl({});
  }

  const deleteServiceFile = (fileName) => {
    const fileId = viewImagesUrl[fileName].id;
    APIService.srFileDelete(fileId)
      .then(getSr)
      .catch((error) => {
        alert(error.message);
      });
  };

  function RadioButton() {
    return (
      <FormControl component="fieldset">
        <RadioGroup aria-label="address" name="addressType" value={formData.addressType} onChange={handleChange} row>
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Home Address" />
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Other Address" />
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Edit Service Request Details" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push('/service-request-mgt');
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>Customer ID</label>
                  <FormInput
                    id="feCustomerID"
                    placeholder="Customer ID"
                    type="text"
                    name="customerID"
                    value={formData.customerID}
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 2, order: 2 }}>
                  <label>SR ID</label>
                  <FormInput
                    id="SrID"
                    placeholder="SR ID"
                    type="text"
                    name="SrID"
                    value={formData.SrID}
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    readonly="true"
                  />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                    value={formData.firstName}
                    readonly="true"
                  />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label>SR Status</label>
                  <FormSelect id="feSrStatus" name="srStatus" value={formData.srStatus} onChange={handleChange}>
                    {/* <option value="">Choose</option>
                    <option value="14">Dispute Closed</option>
                    <option value="15" disabled={true}>
                      Disabled
                    </option> */}
                    <option value="">Choose</option>
                    {Object.entries(SR_STATUSES).map(([key, val]) => (
                      <option value={val} disabled={![15].includes(val)}>
                        {key}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/* <Col md={{ size: 2, order: 4 }}>
                  <label>Action Reason</label>
                  <FormSelect id="feActionReason" name="actionReason" value={formData.actionReason}>
                    <option>Choose</option>
                  </FormSelect>
                </Col> */}
                <Col md={{ size: 2, order: 4 }}>
                  <label>Action Comments</label>
                  <FormInput
                    id="actionComments"
                    placeholder="Action Comments"
                    type="text"
                    name="cust_noshow_comments"
                    value={formData.cust_noshow_comments}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="8" className="mb-4">
                  <Row>
                    <Col md={{ size: 8, order: 4 }}>
                      <h4>Service Address</h4>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <RadioButton />
                    </Col>

                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line 1</label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 1"
                        name="address_1"
                        value={formData.address_1}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line 2 </label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 2"
                        name="address_2"
                        onChange={handleChange}
                        value={formData.address_2}
                      />
                    </Col>

                    <Col md={{ size: 3, order: 4 }} className="form-group">
                      <label htmlFor="feCountry">Country</label>
                      <FormSelect
                        id="feCountry"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value={0}>country</option>
                        {countries.map((country, i) => (
                          <option value={country.id} key={i} selected={formData.country == country.id}>
                            {country.name}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>

                    <Col md={{ size: 3, order: 4 }} className="form-group">
                      <label htmlFor="feState"> State</label>
                      <FormSelect id="feState" name="state" onChange={handleChange} value={formData.state} required>
                        <option value={0}>state</option>
                        {states.map((selectedState, i) => (
                          <option value={selectedState.id} key={i} selected={formData.state == selectedState.id}>
                            {selectedState.name}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 3, order: 4 }} className="form-group">
                      <label htmlFor="feCity">City</label>
                      <FormSelect id="feCity" name="city" value={formData.city} onChange={handleChange} required>
                        <option value={0}>city</option>
                        {cities.map((city, i) => (
                          <option value={city.id} key={i} selected={formData.city == city.id}>
                            {city.name}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 3, order: 4 }} readonly="true">
                      <label htmlFor="feZip">Zip Code</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Zip Code"
                        name="zip_code"
                        onChange={handleChange}
                        value={formData.zip_code}
                      />
                    </Col>

                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Expected Date and Time</h4>
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feDate">Service Date</label>
                      <Col md={{ size: 4, order: 4 }}>
                        <SelectDate
                          id="feDate"
                          name="service_date"
                          handleChange={handleChange}
                          selectedDate={formData.service_date}
                        />
                      </Col>
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feTime">Service Time</label>
                      <FormSelect
                        id="feTime"
                        name="service_time"
                        required
                        value={formData.service_time}
                        onChange={handleChange}
                      >
                        <option value="">Choose</option>
                        {timeSlots.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.show_time}
                            </option>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Service</h4>
                    </Col>

                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Category</label>
                      <FormSelect
                        id="feServiceCategory"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value="">Choose</option>
                        {categories.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.category_name}
                            </option>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Sub-Category</label>
                      <FormSelect id="feUser" name="subCategory" value={formData.subCategory} onChange={handleChange}>
                        <option value="">Choose</option>
                        {subCategories.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.sub_category_name}
                            </option>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label htmlFor="feMobile">Service Type </label>
                      <FormSelect id="feUser" name="serviceType" value={formData.serviceType} onChange={handleChange}>
                        <option>Choose</option>
                        {serviceTypes.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Title </h4>
                      <FormTextarea
                        id="feTitle"
                        type="textarea"
                        placeholder="Provide high level summary of your request.
                      Max 50 Characters"
                        name="title"
                        maxLength="50"
                        onChange={handleChange}
                        value={formData.title}
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Description</h4>

                      <FormTextarea
                        style={{ height: '110px' }}
                        id="feDescription"
                        type="text"
                        placeholder="Provide a brief description of the request and all the relevent details to get the most accurate quote.   
                      Max 1000 Characters"
                        name="description"
                        maxLength="1000"
                        onChange={handleChange}
                        value={formData.description}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col lg="4" className="mb-4" className="mb-2 mt-5">
                  <Row>
                    <Col md={{ size: 12, order: 1 }} className="mb-2 mt-5 mb-5">
                      <h4>Uploaded Photo</h4>
                      <ViewPhotoImageUpload sources={viewImagesUrl} deleteImage={deleteServiceFile} />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={{ size: 12, order: 2 }} className="mb-2 mt-5 mt-5">
                      <h4>Uploaded Videos</h4>
                      <VideoUploads />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                      <h4>Additional</h4>
                      <Appliances handleChange={handleChange} values={formData} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12, order: 4 }} className="form-group p-3">
                      <h4>Upload Additional Photos</h4>
                      <h6>Image 5MB Max (Max 10 uploads)</h6>

                      <Col md={{ size: 12, order: 4 }} className="form-group p-3 mt-5">
                        <label>Choose Image</label>
                        <div className="custom-file mb-3">
                          <FormInput
                            type="file"
                            className="custom-file-input"
                            id="profile_image"
                            name="images[]"
                            accept="image/*"
                            multiple
                            onChange={handleChange}
                            disabled={Object.keys(imagesUrl).length + Object.keys(viewImagesUrl).length >= 10}
                          />
                          <label className="custom-file-label" htmlFor="profile_image">
                            Choose Image...
                          </label>
                        </div>
                      </Col>

                      <ViewPhotoImageUpload
                        sources={imagesUrl}
                        deleteImage={(fileName) => {
                          delete imagesUrl[fileName];
                          setImagesUrl(imagesUrl);
                          const updatedUrls = {};
                          Object.entries(imagesUrl).forEach(([key, val]) => {
                            if (key !== fileName) updatedUrls[key] = val;
                          });
                          setImagesUrl(updatedUrls);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col sm={{ size: 6, order: 6, offset: 10 }} className="mb-5">
                <Button outline theme="primary" onClick={handleSubmit} disabled={state.loading}>
                  {state.loading ? <span class="spinner-border" /> : 'Update'}
                </Button>
              </Col>
            </ListGroupItem>
          </Col>
          <Container fluid className="main-content-container">
            <Row>
              <Col sm={{ size: 12, order: 2 }}>
                <CustBotton
                  state={state}
                  setState={setState}
                  buttons={{
                    viewQuotesList: 'View Quotes List',
                    ViewWarrantyRequestList: 'View Warranty Request',
                    ViewDisputeRequestList: 'View Dispute Request',
                  }}
                />
              </Col>
            </Row>
            {state.viewQuotesList ? <ViewQuotesList srId={srId} /> : null}
            {state.ViewWarrantyRequestList ? <ViewWarrantyRequestList srId={srId} /> : null}
            {state.ViewDisputeRequestList ? <ViewDisputeRequestList srId={srId} /> : null}
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default EditServiceRequest;

import moment from 'moment';


export function dateWithFormate (_date_, _formate_="ddd, MMMM DD, YYYY", parse="DD:MM:YYYY") {
    try{
      console.log("_date_", _date_, _formate_)
      if (_date_) return moment(_date_, parse).format(_formate_);
      else return "";   
    } catch (e) {
      console.log("_date_Err", e)
      return "";
    }
  }
  
import React, { useState, useEffect } from 'react';
import Calender from '../ServiceRequest/Calender';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';
import { useHistory, useParams } from 'react-router-dom';
import { ListGroupItem, Row, Col, FormInput, FormSelect, Container, Card, CardBody, Button } from 'shards-react';
import { USER_STATUS, QUOTE_STATUSES } from '../../data/constants';

const QuoteManagement = (props) => {
  const [state, setState] = React.useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });
  const [quoteMgt, setQuoteMgt] = useState();
  const [filters, setFilters] = useState({});
  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);

  const { quoteId } = useParams();

  useEffect(() => {
    fetchCountryData();

    APIService.fetchAllCategories()
      .then((res) => {
        setServiceCats(res.data);
      })
      .catch((err) => {});

    APIService.fetchAllSubCategories()
      .then((res) => {
        setServiceSubCats(res);
      })
      .catch((err) => {});
  }, []);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    let { name, value } = event.currentTarget;

    if (name === 'categoryId') filters.subCategoryId = '';

    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = async () => {
      setQuoteMgt();
      const response = await APIService.fetchQuoteList({ queryParams: filters });
      console.log(response);
      setQuoteMgt(
        response.quote.map((item) => {
          return {
            quoteId: item.id || '',
            quoteCreationDate: item.created_at?.split(' ')[0] || '',
            quoteStatus: item.quotestatus.name || '',
            serviceProviderId: item.provider_id || '',
            warrantyExpiryDate: item.warranty_expiry_date?.split('T')[0] || '',
            totalQuoteAmountToCustomer: (
              parseFloat(item.uplifted_labor_quote_amount || 0) + parseFloat(item.uplifted_material_quote_amount || 0)
            ).toFixed(2),
            totalQuoteAmountToSP: (
              parseFloat(item.labor_quote_amount || 0) + parseFloat(item.material_quote_amount || 0)
            ).toFixed(2),
            labourQuoteAmountToSP: item.labor_quote_amount || '0.00',
            materialIncluded: item.material_included_flag === 1 ? 'Yes' : 'No' || '',
            materialQuoteAmountFromSP: item.material_quote_amount || '0.00',
            status: USER_STATUS[parseInt(item.status)] || '',
            srId: item.request_id,
            custId: item.user_id,
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Quote Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ListGroupItem>
              <Row>
                <Col md={{ size: 1, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser" name="userType" onChange={handleChange}>
                    <option value="">Choose</option>
                    <option value="2">Provider</option>
                    <option value="3">Both</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="custLastName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="custFirstName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile #</label>
                  <FormInput id="feMobile" type="text" placeholder="Mobile" name="custMobile" onChange={handleChange} />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput id="feEmail" type="email" placeholder="Email" name="custEmail" onChange={handleChange} />
                </Col>
                <Col md={{ size: 1, order: 4 }}>
                  <label htmlFor="feMobile">User ID </label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Customer Id"
                    name="custId"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory" name="categoryId" onChange={handleChange}>
                    <option value="">Select the service category *</option>
                    {serviceCats.map((sc) => (
                      <option value={sc.id}>{sc.category_name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser" name="subCategoryId" onChange={handleChange} value={filters.subCategoryId}>
                    <option value="">Choose</option>
                    {serviceSubCats
                      .filter((sc) => sc.category_id == filters.categoryId)
                      .map((sc) => (
                        <option value={sc.id}>{sc.sub_category_name}</option>
                      ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Service Request ID"
                    name="srId"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feCountry">Country</label>
                  <FormSelect
                    id="feCountry"
                    name="countryId"
                    onChange={(e) => {
                      fetchStateData(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value={0}>country</option>
                    {state.countries.map((country, i) => (
                      <option value={country.id} key={i} selected={state.country == country.id}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feState">State</label>
                  <FormSelect
                    id="feState"
                    name="stateId"
                    onChange={(e) => {
                      fetchCityData(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value={0}>state</option>
                    {state.states.map((selectedState, i) => (
                      <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                        {selectedState.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }} className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity" value={state.city} name="cityId" onChange={handleChange} required>
                    <option value={0}>city</option>
                    {state.cities.map((city, i) => (
                      <option value={city.id} key={i} selected={state.city == city.id}>
                        {city.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput id="feZip" type="text" placeholder="Zip Code" name="zipCode" onChange={handleChange} />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feSrStatus">Quote ID</label>
                  <FormInput id="feZip" type="number" placeholder="Quote ID" name="quoteId" onChange={handleChange} />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">Quote Status</label>
                  <FormSelect id="feState" name="quoteStatus" value={filters.quoteStatus} onChange={handleChange}>
                    <option value={0}>Choose</option>
                    {Object.entries(QUOTE_STATUSES).map(([name, status]) => (
                      <option value={status}>{name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Email ID</label>
                  <FormInput id="feZip" type="text" placeholder="SP Email ID" name="spEmail" onChange={handleChange} />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Last Name </label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP Last Name"
                    name="spLastName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP First Name </label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP First Name"
                    name="spFirstName"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Mobile #</label>
                  <FormInput
                    id="feZip"
                    type="number"
                    placeholder="SP Mobile #"
                    name="spMobile"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Quote Creation Date Range</label>
                  <Calender onChange={handleChange} name="created_at" />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Quote Schedule Date Range</label>
                  <Calender onChange={handleChange} name="service_date" />
                </Col>
                <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group p-3">
                  <Button
                    outline
                    theme="primary"
                    className="mb-2 mr-4"
                    onClick={getService}
                    disabled={!Object.keys(filters).length}
                  >
                    Apply Filter
                  </Button>
                  {!!Object.keys(filters).length && (
                    <Button
                      outline
                      theme="danger"
                      className="mb-2 mr-1"
                      onClick={() => {
                        window.location.reload();
                      }}
                      disabled={!Object.keys(filters).length}
                    >
                      Clear Filter
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              <b>Quote List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  isLoading={!quoteMgt}
                  title=""
                  columns={[
                    { title: 'Quote ID', field: 'quoteId' },
                    { title: 'SR ID', field: 'srId' },
                    { title: 'Quote Creation Date', field: 'quoteCreationDate' },
                    { title: 'Quote Status', field: 'quoteStatus' },
                    { title: 'Customer ID', field: 'custId' },
                    { title: 'Service Provider ID', field: 'serviceProviderId' },
                    { title: 'Warranty Expiry Date', field: 'warrantyExpiryDate' },
                    { title: 'Total Quote Amount to Customer', field: 'totalQuoteAmountToCustomer' },
                    { title: 'Total Quote Amount from SP', field: 'totalQuoteAmountToSP' },
                    { title: 'Labour Quote Amount from SP', field: 'labourQuoteAmountToSP' },
                    { title: 'Material Included(Yes/No)', field: 'materialIncluded' },
                    { title: 'Material Quote Amount from SP', field: 'materialQuoteAmountFromSP' },
                  ]}
                  data={quoteMgt}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    // paging:false
                  }}
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Quote Details',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/view-quote-details/' + data.quoteId);
                      },
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Edit Quote',
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push('/edit-quote/' + data.quoteId);
                      },
                    },
                  ]}
                  // localization={{
                  //   body: { editRow: { deleteText: `Are you sure you want to activate/deactivate this Quote?` } },
                  // }}
                  // editable={{
                  //   onRowDelete: (oldData) =>
                  //     new Promise((resolve) => {
                  //       APIService.quoteActivateDeactivate(oldData.quoteId)
                  //         .then(getService)
                  //         .then(resolve)
                  //         .catch((error) => alert(error.errorMessage || error.message));
                  //     }),
                  // }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default QuoteManagement;

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';
import { ListGroupItem, Row, Col, Card, CardBody } from 'shards-react';

import { useHistory, useParams } from 'react-router-dom';

const ViewQuotesList = (props) => {
  const [srRequest, setSrRequest] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = async () => {
      const response = await APIService.fetchQuoteListBySrId(props.srId);

      setSrRequest(
        response.quote.map((item) => {
          return {
            quoteId: item.id || '',
            quoteCreationDate: item.created_at || '',
            quoteStatus: item.quotestatus.name || '',
            serviceProviderId: item.provider_id || '',
            warrantyExpiryDate: item.warranty_expiry_date?.split('T')[0] || '',
            totalQuoteAmountToCustomer: (
              parseFloat(item.uplifted_labor_quote_amount || 0) + parseFloat(item.uplifted_material_quote_amount || 0)
            ).toFixed(2),
            totalQuoteAmountToSP: (
              parseFloat(item.labor_quote_amount || 0) + parseFloat(item.material_quote_amount || 0)
            ).toFixed(2),
            labourQuoteAmountToSP: item.labor_quote_amount || '0.00',
            materialIncluded: item.material_included_flag === 1 ? 'Yes' : 'No' || '',
            materialQuoteAmountFromSP: item.material_quote_amount || '0.00',
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <div>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <MaterialTable
                isLoading={!srRequest}
                title=""
                columns={[
                  { title: 'Quote ID', field: 'quoteId' },
                  { title: 'Quote Creation Date', field: 'quoteCreationDate' },
                  { title: 'Quote Status', field: 'quoteStatus' },
                  { title: 'Service Provider ID', field: 'serviceProviderId' },
                  { title: 'Warranty Expiry Date', field: 'warrantyExpiryDate' },
                  { title: 'Total Quote Amount to Customer', field: 'totalQuoteAmountToCustomer' },
                  { title: 'Total Quote Amount from SP', field: 'totalQuoteAmountToSP' },
                  { title: 'Labour Quote Amount from SP', field: 'labourQuoteAmountToSP' },
                  { title: 'Material Included(Yes/No)', field: 'materialIncluded' },
                  { title: 'Material Quote Amount from SP', field: 'materialQuoteAmountFromSP' },
                ]}
                data={srRequest}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  // exportCsv: (columns, data) => {
                  //   alert(
                  //     "You should develop a code to export " +
                  //       data.length +
                  //       " rows"
                  //   );
                  // },
                }}
                // actions={[
                //   {
                //     icon: 'chat',
                //     tooltip: 'View Chat',
                //     onClick: (event, data) => {
                //       console.log(data);
                //       console.log(event);

                //       props.history.push('/view-chats');
                //     },
                //   },

                //   {
                //     icon: 'comment',
                //     tooltip: 'View Comment',
                //     onClick: (event, data) => {
                //       console.log(data);
                //       console.log(event);
                //       props.history.push('/view-comments');
                //     },
                //   },
                // ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewQuotesList;

import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  FormInput,
  FormSelect,
  Alert,
  Form,
} from 'shards-react';

import MainTitle from '../../../components/common/MainTitle';

import ContentHeader from '../../../components/common/ContentHeader';
import { APIService } from '../../../utils/APIService';
import { Redirect } from 'react-router-dom';

function AddSrDisable(props) {
  const [state, setState] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    APIService.addSrDisableReason(state).then(
      (unit) => {
        setState({
          ...state,
          success: true,
          loading: false,
          redirect: true,
          redirectPath: '/sr-disable-reasons',
          redirectData: {
            visible: true,
            alertStyle: 'success',
            alertIcon: 'fa fa-check mx-2',
            alertMessage: 'SR disable reason added successfully.',
          },
        });
      },
      (error) => {
        alert(error.errorMessage || error.message);
        setState({
          ...state,
          success: false,
          loading: false,
          visible: true,
          alertStyle: 'danger',
          alertIcon: 'fa fa-exclamation mx-2',
          alertMessage: error.errorMessage,
        });
      }
    );
  };

  if (state.redirect) {
    return (
      <Redirect
        to={{
          pathname: state.redirectPath,
          state: state.redirectData,
        }}
      />
    );
  }

  return (
    <>
      <Container fluid className="main-content-container px-4">
        <MainTitle title="Add SR Disable Reason" />
        <Row>
          <Col>
            <Card small className="mb-4">
              <ContentHeader title="">
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    if (window.confirm('All your changes will be lost. Do you still want continue?')) {
                      props.history.push('/sr-disable-reasons');
                    }
                  }}
                >
                  Back
                </Button>
              </ContentHeader>
              <CardBody className="p-0 pb-3">
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col sm={{ size: 4, order: 4, offset: 4 }}>
                      <label>SR Disable Reason</label>
                      <FormInput
                        id="srDisableReason"
                        type="text"
                        placeholder="SR Disable Reason"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={{ size: 4, order: 4, offset: 4 }} className="mt-3">
                      <label htmlFor="feStatus"> SR Disable Reason Status</label>
                      <FormSelect id="feStatus" name="status" onChange={handleChange} required>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </FormSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: 6, order: 4, offset: 10 }}></Col>
                    <Col sm={{ size: 6, order: 4, offset: 10 }}>
                      <Button outline theme="primary" className="mb-2 mr-1" type="submit">
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddSrDisable;

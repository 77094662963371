import React from 'react';
import { Col } from 'shards-react';

export default function ImageHandle({ state, name, title, handleChange }) {
  return (
    <>
      <Col md={{ size: 4, order: 4 }} className="form-group mt-3">
        <label>{title}</label>
        <div className="custom-file mb-3">
          <input
            type="file"
            className="custom-file-input"
            id={name + '_image'}
            name={name + '_photo'}
            accept="image/*"
            onChange={handleChange}
            disabled={state.loading}
          />
          <label className="custom-file-label" htmlFor={name + '_image'}>
            {state[name + '_photo_name'] || `Choose ${title}...`}
          </label>
        </div>
      </Col>
      <Col md={{ size: 8, order: 4 }} className="form-group mt-3"></Col>
      <Col md={{ size: 1, order: 4 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {!!state[name + '_photo_url'] && (
          <img src={state[name + '_photo_url']} width="100%" id={name + '_img_prev'} alt={title} />
        )}
      </Col>
    </>
  );
}

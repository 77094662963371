import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
import {
  Container,
  ListGroupItem,
  Row,
  Col,
  Button,
  Form,
  FormInput,
  Alert,
  FormSelect,
  InputGroup,
} from 'shards-react';
import { APIService } from '../../utils/APIService';

function Personal(props) {
  // Country Series API intregation

  const [state, setState] = useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
    profile_photo: null,
    profile_photo_url: '',
    profile_photo_name: '',
    loading: false,
  });

  useEffect(() => {
    fetchCountryData();
  }, []);
  const fetchCountryData = () => {
    APIService.fetchCountry().then(
      (units) => {
        // console.log(units);
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          country,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          state: stat,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    if (target.checked) setState({ ...state, [target.name]: target.checked });
    else setState({ ...state, [target.name]: target.value });
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      setState({ ...state, profile_photo: image, profile_photo_name: image.name, profile_photo_url: reader.result });
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.password !== state.confirm_password) {
      setState({
        ...state,
        visible: true,
        alertStyle: 'danger',
        alertIcon: 'fa fa-exclamation mx-2',
        alertMessage: 'Password and confirm password should match',
      });
    } else {
      setState({ ...state, loading: true });
      APIService.addUser(state).then(
        (unit) => {
          setState({
            ...state,
            success: true,
            loading: false,
            redirect: true,
            redirectPath: '/users',
            redirectData: {
              visible: true,
              alertStyle: 'success',
              alertIcon: 'fa fa-check mx-2',
              alertMessage: 'User added successfully.',
            },
          });
        },
        (error) => {
          setState({
            ...state,
            success: false,
            loading: false,
            visible: true,
            alertStyle: 'danger',
            alertIcon: 'fa fa-exclamation mx-2',
            alertMessage: error.message,
          });
        }
      );
    }
  };

  const dismissAlert = () => {
    setState({ ...state, visible: false });
  };

  return (
    <>
      {state.redirect ? (
        <Redirect to={{ pathname: state.redirectPath, state: state.redirectData }} />
      ) : (
        <>
          {state.visible && (
            <Container fluid className="px-0">
              <Alert
                theme={state.alertStyle || 'primary'}
                dismissible={dismissAlert}
                open={state.visible}
                className="mb-0"
              >
                <i className={state.alertIcon} /> {state.alertMessage}
              </Alert>
            </Container>
          )}

          <Container fluid className="main-content-container">
            <MainTitle title="Personal Information" />
            <ContentHeader>
              <Button
                onClick={() => {
                  if (window.confirm('All your changes will be lost.Do you still want to continue ?')) {
                    props.history.push('/users');
                  }
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={{ size: 4, order: 4 }} className="form-group mt-3">
                    <label>Profile Image*</label>
                    <div className="custom-file mb-3">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="profile_image"
                        name="profile_photo"
                        accept="image/*"
                        onChange={handleChangeImage}
                        required
                        disabled={state.loading}
                      />
                      <label className="custom-file-label" htmlFor="profile_image">
                        {state.profile_photo_name || 'Choose Profile Image...'}
                      </label>
                    </div>
                  </Col>
                  <Col
                    md={{ size: 1, order: 4 }}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {!!state.profile_photo_url && (
                      <img src={state.profile_photo_url} width="100%" id="profile_img_prev" alt="profile" />
                    )}
                  </Col>
                </Row>
                <Row form>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feFirstName">User Type*</label>
                    <FormSelect id="feUser" name="role_type" onChange={handleChange} disabled={state.loading}>
                      <option value="1">Customer</option>
                      <option value="2">Provider</option>
                      <option value="3">Both</option>
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 8, order: 4 }}></Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feFirstName">First Name*</label>
                    <FormInput
                      id="feFirstName"
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feMiddleName">Middle Name</label>
                    <FormInput
                      id="feMiddleName"
                      placeholder="Middle Name"
                      type="text"
                      name="middle_name"
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feLastName">Last Name*</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={{ size: 6, order: 4 }} className="form-group ">
                    <label htmlFor="feAddress">Address Line 1*</label>
                    <FormInput
                      id="feAddress"
                      type="text"
                      placeholder="Address Line 1"
                      name="address_1"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 6, order: 4 }} className="form-group ">
                    <label htmlFor="feAddress2">Address Line 2</label>
                    <FormInput
                      id="feAddress2"
                      type="text"
                      placeholder="Address Line 2"
                      name="address_2"
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feCountry">Country*</label>
                    <FormSelect
                      id="feCountry"
                      name="country_id"
                      onChange={(e) => fetchStateData(e.target.value)}
                      required
                      disabled={state.loading}
                    >
                      <option value={0}>country</option>
                      {state.countries.map((country, i) => (
                        <option value={country.id} key={i} selected={state.country == country.id}>
                          {country.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feState">State*</label>
                    <FormSelect
                      id="feState"
                      onChange={(e) => fetchCityData(e.target.value)}
                      required
                      disabled={state.loading}
                    >
                      <option value={0}>state</option>
                      {state.states.map((selectedState, i) => (
                        <option value={selectedState.id} key={i} selected={state.state == selectedState.id}>
                          {selectedState.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feCity">City*</label>
                    <FormSelect
                      id="feCity"
                      value={state.city}
                      onChange={(e) => setState({ ...state, city: e.target.value })}
                      required
                      disabled={state.loading}
                    >
                      <option value={0}>city</option>
                      {state.cities.map((city, i) => (
                        <option value={city.id} key={i} selected={state.city == city.id}>
                          {city.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group ">
                    <label htmlFor="feZip">Zip Code*</label>
                    <FormInput
                      id="feZip"
                      type="text"
                      placeholder="Zip Code"
                      name="zip_code"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feEmail">Email ID*</label>
                    <FormInput
                      id="feEmail"
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 4, order: 4 }} className="form-group">
                    <label htmlFor="feMobile">Mobile Phone*</label>
                    <FormInput
                      id="feMobile"
                      type="number"
                      placeholder="Mobile Phone"
                      name="phone"
                      maxLength="10"
                      required
                      onChange={handleChange}
                      disabled={state.loading}
                    />
                  </Col>
                  <Col md={{ size: 6, order: 4 }} className="form-group mb-5 ">
                    <label htmlFor="fepassword">Password*</label>
                    <InputGroup>
                      <FormInput
                        id="fepassword"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        disabled={state.loading}
                        required
                      />
                    </InputGroup>
                  </Col>

                  <Col md={{ size: 6, order: 4 }} className="form-group ">
                    <label htmlFor="fepassword">Confirm Password*</label>
                    <InputGroup>
                      <FormInput
                        id="fepassword"
                        type="password"
                        placeholder="confirm Password"
                        name="confirm_password"
                        onChange={handleChange}
                        disabled={state.loading}
                        required
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Col md={{ size: 4, order: 4, offset: 5 }} className="form-group ">
                  <Button outline type="submit" theme="primary" className="mb-2 mr-1" disabled={state.loading}>
                    {state.loading ? <span class="spinner-border" /> : 'Submit'}
                  </Button>
                </Col>
              </Form>
            </ListGroupItem>
          </Container>
        </>
      )}
    </>
  );
}
export default Personal;

import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup, Alert } from 'shards-react';
import MainTitle from '../../../components/common/MainTitle';

import ContentHeader from '../../../components/common/ContentHeader';
import Loader from '../../../components/Loader/Loader';
import { Redirect } from 'react-router-dom';
import { APIService } from '../../../utils/APIService';
import userLoginStatus from '../../../utils/userLoginStatus';
import MaterialTable from 'material-table';
import Navigate from '../Navigate/Navigate';

function WarrantyClosureReason(props) {
  return (
    <>
      <Container fluid className="main-content-container px-4">
        <MainTitle title="Data Set Management" />
        <Row>
          <Col>
            <Card small className="mb-4">
              <ContentHeader>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/data-set');
                  }}
                >
                  House Type
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/time-slots');
                  }}
                >
                  Time Slots
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/quote-withdrawal-reasons');
                  }}
                >
                  Quote withdrawal reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/quote-rejection-reasons');
                  }}
                >
                  Quote rejection reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/sr-cancel-reasons');
                  }}
                >
                  SR cancel reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/dispute-reasons');
                  }}
                >
                  Dispute Reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/revisit-reasons');
                  }}
                >
                  Revisit Reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/sr-disable-reason');
                  }}
                >
                  SR Disable Reasons
                </Button>
                <Button
                  outline
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => {
                    props.history.push('/quote-disable-reasons');
                  }}
                >
                  Quote Disable Reasons
                </Button>

                <Button theme="primary" className="mb-2 mr-1">
                  Warranty Closure Reasons
                </Button>

                <Button
                  onClick={() => {
                    props.history.push('/add-warranty-closure-reasons');
                  }}
                  outline
                  theme="success"
                  className="mb-2 mr-1"
                >
                  Add Warranty Closure Reason
                </Button>
              </ContentHeader>
              <CardBody className="p-0 pb-3">
                <CardBody className="p-0 pb-3">
                  <MaterialTable
                    title="Warranty Closure Reason"
                    columns={[
                      { title: 'ID', field: 'id' },
                      { title: 'Warranty Closure Reason', field: 'reason' },
                      {
                        title: 'Warranty Closure Reason Description',
                        field: 'description',
                      },
                      {
                        title: 'Warranty Closure Reason Status(Active/Inactive)',
                        field: 'status',
                      },
                    ]}
                    data={[]}
                    options={{
                      search: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                    }}
                    actions={[]}
                    editable={{
                      // onRowAddCancelled: (rowData) =>
                      //   console.log("Row adding cancelled"),
                      onRowUpdateCancelled: (rowData) => console.log('Row editing cancelled'),
                      // onRowAdd: (newData) => new Promise((resolve, reject) => {}),
                      // onRowUpdate: (newData, oldData) =>
                      //   APIService.updateServiceRequest(newData),

                      onRowDelete: (oldData) => console.log('Row editing cancelled'),
                      //   APIService.fetchServiceRequestDelete(oldData.srId),
                    }}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WarrantyClosureReason;

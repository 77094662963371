import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import ContentRowImage from '../common/ContentRowImage';
import { APIService } from '../../../utils/APIService';

function HomePageHerography(props) {
  const [state, setState] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setState();
    APIService.getCMSSubSection('home-page', 'herography').then((resp) => {
      if (typeof resp === 'object' && Array.isArray(resp.contents)) {
        setState(resp);
      }
    });
  }

  if (!state) return <Loader />;

  return (
    <>
      {state.contents.map((content) =>
        content.image ? <ContentRowImage key={content.id} content={content} getService={getService} /> : <></>
      )}
    </>
  );
}

export default HomePageHerography;

import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import PeoplesVoiceTexts from './texts';
import PeoplesVoiceVideos from './videos';

function PeoplesVoice(props) {
  const [state, setState] = useState({ texts: true, videos: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ texts: 'Texts', videos: 'Videos' }} />
        </ListGroupItem>
      </ListGroup>

      {state.texts && (
        <PeoplesVoiceTexts
          isEdit={props.isEdit}
          setEdit={props.setEdit}
          userId={props.userId}
          history={props.history}
        />
      )}
      {state.videos && (
        <PeoplesVoiceVideos
          isEdit={props.isEdit}
          setEdit={props.setEdit}
          userId={props.userId}
          history={props.history}
        />
      )}
    </>
  );
}

export default PeoplesVoice;

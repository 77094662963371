import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import SocialMediaLinks from './SocialMediaLinks';

function GetInTouch(props) {
  const [state, setState] = useState({ socialMediaLinks: true });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ socialMediaLinks: 'Social Media Links' }} />
        </ListGroupItem>
      </ListGroup>

      {state.socialMediaLinks && (
        <SocialMediaLinks
          isEdit={props.isEdit}
          setEdit={props.setEdit}
          userId={props.userId}
          history={props.history}
        />
      )}
    </>
  );
}

export default GetInTouch;

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, ListGroupItem, Row, Col, Button, Form, FormInput, Alert, FormSelect } from 'shards-react';
import config from '../../../../data/config';
import { APIService } from '../../../../utils/APIService';

function BusinessLicense(props) {
  const [state, setState] = useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });
  const [image, setImage] = useState({
    bl_photo: null,
    bl_photo_url: '',
    bl_photo_name: '',
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    states: [],
  });

  useEffect(() => {
    getService();
  }, []);

  const getService = () => {
    setLoading(true);

    APIService.fetchSpBl(props.userId)
      .then((resp) => {
        if (resp && Array.isArray(resp.data) && resp.data[0]) {
          setData({
            ...resp.data[0],
            bl_issue_date: resp.data[0].bl_issue_date?.split('T')[0],
            bl_expiry_date: resp.data[0].bl_expiry_date?.split('T')[0],
          });

          setImage({
            ...image,
            bl_photo_name: resp.data[0].bl_image,
            bl_photo_url: config.endpoints.image_path + resp.data[0].bl_image,
          });

          fetchStateData();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStateData = () => {
    return APIService.fetchState().then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    console.log({ [target.name]: target.value });
    setData({ ...data, [target.name]: target.value });
  };

  const handleChangeImage = (event) => {
    const image = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = (e) => {
      setImage({
        bl_photo: image,
        bl_photo_name: image.name,
        bl_photo_url: reader.result,
        image_updated: 'true',
      });
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    APIService.updateSpBl(props.userId, {
      ...data,
      bl_image: image.bl_photo,
      image_updated: image.image_updated,
    })
      .then(
        (unit) => {
          setState({
            ...state,
            success: true,
            loading: false,
            redirect: true,
            redirectPath: '/users',
            redirectData: {
              visible: true,
              alertStyle: 'success',
              alertIcon: 'fa fa-check mx-2',
              alertMessage: 'Business license updated successfully.',
            },
          });
        },
        (error) => {
          setState({
            ...state,
            success: false,
            loading: false,
            visible: true,
            alertStyle: 'danger',
            alertIcon: 'fa fa-exclamation mx-2',
            alertMessage: error.message,
          });
        }
      )
      .finally(() => setLoading(false));
  };

  const dismissAlert = () => {
    setState({ ...state, visible: false });
  };

  if (state.redirectPath) return <Redirect to={{ pathname: state.redirectPath, state: state.redirectData }} />;

  return (
    <>
      {state.visible && (
        <Container fluid className="px-0">
          <Alert theme={state.alertStyle || 'primary'} dismissible={dismissAlert} open={state.visible} className="mb-0">
            <i className={state.alertIcon} /> {state.alertMessage}
          </Alert>
        </Container>
      )}
      <ListGroupItem>
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>License Number</label>
              <FormInput
                type="number"
                placeholder="License Number"
                name="bl_id"
                required
                onChange={handleChange}
                value={data.bl_id}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>Issue Date</label>
              <FormInput
                placeholder="Issue Date"
                type="date"
                name="bl_issue_date"
                onChange={handleChange}
                value={data.bl_issue_date}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>Expiry Date</label>
              <FormInput
                placeholder="Expiry Date"
                type="date"
                name="bl_expiry_date"
                onChange={handleChange}
                value={data.bl_expiry_date}
                disabled={loading || !props.isEdit}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label>Issuing Authority Name</label>
              <FormInput
                placeholder="Issuing Authority Name"
                type="text"
                name="bl_issue_authority"
                required
                onChange={handleChange}
                value={data.bl_issue_authority}
                disabled={loading || !props.isEdit}
              />
            </Col>

            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feState">Issuing State</label>
              {props.isEdit ? (
                <FormSelect
                  id="feState"
                  name="bl_issue_state"
                  onChange={handleChange}
                  required
                  disabled={loading || !props.isEdit}
                >
                  <option value={0}>state</option>
                  {state.states.map((selectedState, i) => (
                    <option value={selectedState.id} key={i} selected={data.bl_issue_state == selectedState.id}>
                      {selectedState.name}
                    </option>
                  ))}
                </FormSelect>
              ) : (
                <FormInput
                  placeholder="State"
                  name="bl_issue_state"
                  value={data.state?.name || ''}
                  disabled={loading || !props.isEdit}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={{ size: 4, order: 4 }} className="form-group mt-3">
              <label>Business License Image</label>
              <div className="custom-file mb-3">
                <input
                  type="file"
                  className="custom-file-input"
                  name="image"
                  accept="image/*"
                  onChange={handleChangeImage}
                  disabled={loading || !props.isEdit}
                />
                <label className="custom-file-label" htmlFor="profile_image">
                  {image.bl_photo_name || 'Choose Image...'}
                </label>
              </div>
            </Col>
            <Col md={{ size: 2, order: 4 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {!!image.bl_photo_url && (
                <img src={image.bl_photo_url} width="100%" id="profile_img_prev" alt="profile" />
              )}
            </Col>
          </Row>

          <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group ">
            {props.isEdit && (
              <Button outline type="submit" theme="primary" className="mb-2 mr-1" disabled={loading}>
                {loading ? <span class="spinner-border" /> : 'Save'}
              </Button>
            )}
            {!props.isEdit && (
              <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={props.setEdit}>
                {loading ? <span class="spinner-border" /> : 'Edit'}
              </Button>
            )}
          </Col>
        </Form>
      </ListGroupItem>
    </>
  );
}

export default BusinessLicense;

import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'shards-react';
import CustBotton from '../../ServiceRequest/CustButton';
import ServiceProviderGuideGifs from './gifs';
import ServiceProviderGuideTexts from './texts';

function ServiceProviderGuide(props) {
  const [state, setState] = useState({ gifs: true, texts: false });

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <CustBotton state={state} setState={setState} buttons={{ gifs: 'GIFs', texts: 'Texts' }} />
        </ListGroupItem>
      </ListGroup>

      {state.gifs && (
        <ServiceProviderGuideGifs isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
      {state.texts && (
        <ServiceProviderGuideTexts isEdit={props.isEdit} setEdit={props.setEdit} userId={props.userId} history={props.history} />
      )}
    </>
  );
}

export default ServiceProviderGuide;

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, ListGroupItem, Row, Col, Button, Form, FormInput, Alert, FormSelect } from 'shards-react';
import { APIService } from '../../../../utils/APIService';

function CompanyInfo(props) {
  const [state, setState] = useState({
    checkedA: true,
    countries: [],
    states: [],
    cities: [],
    country: 0,
    state: 0,
    city: 0,
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [updates, setUpdates] = useState({});

  useEffect(() => {
    getService();
  }, []);

  const getService = () => {
    setLoading(true);

    APIService.fetchCompanyInfo(props.userId)
      .then((resp) => {
        if (resp && Array.isArray(resp.data) && resp.data[0]) {
          setData({ ...resp.data[0] });

          fetchCountryData()
            .then(() => fetchStateData(resp.data[0].c_country))
            .then(() => fetchCityData(resp.data[0].c_state));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCountryData = () => {
    return APIService.fetchCountry().then(
      (units) => {
        setState({
          ...state,
          countries: units,
        });
      },
      (error) => setState({ internetConnected: false })
    );
  };
  const fetchStateData = (country) => {
    return APIService.fetchState(country).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          states: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const fetchCityData = (stat) => {
    return APIService.fetchCity(stat).then(
      (units) => {
        setState((prevState) => ({
          ...prevState,
          cities: units,
        }));
      },
      (error) => setState({ internetConnected: false })
    );
  };

  const handleChange = (event) => {
    const target = event.currentTarget;
    setData({ ...data, [target.name]: target.value });
    setUpdates({ ...updates, [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    APIService.updateCompanyInfo(data.id, { ...updates })
      .then(
        (unit) => {
          setState({
            ...state,
            success: true,
            loading: false,
            redirect: true,
            redirectPath: '/users',
            redirectData: {
              visible: true,
              alertStyle: 'success',
              alertIcon: 'fa fa-check mx-2',
              alertMessage: 'Company info updated successfully.',
            },
          });
        },
        (error) => {
          setState({
            ...state,
            success: false,
            loading: false,
            visible: true,
            alertStyle: 'danger',
            alertIcon: 'fa fa-exclamation mx-2',
            alertMessage: error.message,
          });
        }
      )
      .finally(() => setLoading(false));
  };

  const dismissAlert = () => {
    setState({ ...state, visible: false });
  };

  if (state.redirectPath) return <Redirect to={{ pathname: state.redirectPath, state: state.redirectData }} />;

  return (
    <>
      {state.visible && (
        <Container fluid className="px-0">
          <Alert theme={state.alertStyle || 'primary'} dismissible={dismissAlert} open={state.visible} className="mb-0">
            <i className={state.alertIcon} /> {state.alertMessage}
          </Alert>
        </Container>
      )}
      <ListGroupItem>
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={{ size: 4, order: 4 }} className="form-group">
              <label htmlFor="feMiddleName">Company Name</label>
              <FormInput
                placeholder="Company Name"
                type="text"
                name="company_name"
                onChange={handleChange}
                value={data.company_name}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="feAddress">Address Line 1*</label>
              <FormInput
                type="text"
                placeholder="Address Line 1"
                name="c_addresline_1"
                required
                onChange={handleChange}
                value={data.c_addresline_1}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 4, order: 4 }} className="form-group ">
              <label htmlFor="feAddress2">Address Line 2</label>
              <FormInput
                type="text"
                placeholder="Address Line 2"
                name="c_addresline_2"
                onChange={handleChange}
                value={data.c_addresline_2}
                disabled={loading || !props.isEdit}
              />
            </Col>

            <Col md={{ size: 3, order: 4 }} className="form-group">
              <label htmlFor="feCountry">Country*</label>
              {props.isEdit ? (
                <FormSelect
                  id="feCountry"
                  name="c_country"
                  onChange={(e) => {
                    handleChange(e);
                    fetchStateData(e.target.value);
                  }}
                  required
                  disabled={loading || !props.isEdit}
                >
                  <option value={0}>country</option>
                  {state.countries.map((country, i) => (
                    <option value={country.id} key={i} selected={data.c_country == country.id}>
                      {country.name}
                    </option>
                  ))}
                </FormSelect>
              ) : (
                <FormInput
                  placeholder="Country"
                  name="c_country"
                  value={data.country?.name}
                  disabled={loading || !props.isEdit}
                />
              )}
            </Col>
            <Col md={{ size: 3, order: 4 }} className="form-group">
              <label htmlFor="feState">State*</label>
              {props.isEdit ? (
                <FormSelect
                  id="feState"
                  name="c_state"
                  onChange={(e) => {
                    handleChange(e);
                    fetchCityData(e.target.value);
                  }}
                  required
                  disabled={loading || !props.isEdit}
                >
                  <option value={0}>state</option>
                  {state.states.map((selectedState, i) => (
                    <option value={selectedState.id} key={i} selected={data.c_state == selectedState.id}>
                      {selectedState.name}
                    </option>
                  ))}
                </FormSelect>
              ) : (
                <FormInput
                  placeholder="State"
                  name="c_state"
                  value={data.state?.name}
                  disabled={loading || !props.isEdit}
                />
              )}
            </Col>
            <Col md={{ size: 3, order: 4 }} className="form-group">
              <label htmlFor="feCity">City*</label>
              {props.isEdit ? (
                <FormSelect
                  id="feCity"
                  name="c_city"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  disabled={loading || !props.isEdit}
                >
                  <option value={0}>city</option>
                  {state.cities.map((city, i) => (
                    <option value={city.id} key={i} selected={data.c_city == city.id}>
                      {city.name}
                    </option>
                  ))}
                </FormSelect>
              ) : (
                <FormInput
                  placeholder="City"
                  name="c_city"
                  value={data.city?.name}
                  disabled={loading || !props.isEdit}
                />
              )}
            </Col>

            <Col md={{ size: 3, order: 4 }} className="form-group ">
              <label htmlFor="feZip">Zip Code*</label>
              <FormInput
                type="text"
                placeholder="Zip Code"
                name="c_zipcode"
                required
                onChange={handleChange}
                value={data.c_zipcode}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label htmlFor="feEmail">Email ID*</label>
              <FormInput
                type="email"
                placeholder="Email"
                name="c_emailid"
                required
                onChange={handleChange}
                value={data.c_emailid}
                disabled={loading || !props.isEdit}
              />
            </Col>
            <Col md={{ size: 6, order: 4 }} className="form-group">
              <label htmlFor="feMobile">Mobile Phone*</label>
              <FormInput
                type="number"
                placeholder="Mobile Phone"
                name="c_phone_number"
                maxLength="10"
                required
                onChange={handleChange}
                value={data.c_phone_number}
                disabled={loading || !props.isEdit}
              />
            </Col>
          </Row>
          <Col md={{ size: 4, order: 4, offset: 10 }} className="form-group ">
            {props.isEdit && (
              <Button outline type="submit" theme="primary" className="mb-2 mr-1" disabled={loading}>
                {loading ? <span class="spinner-border" /> : 'Save'}
              </Button>
            )}
            {!props.isEdit && (
              <Button outline theme="primary" className="mb-2 mr-1" disabled={loading} onClick={props.setEdit}>
                {loading ? <span class="spinner-border" /> : 'Edit'}
              </Button>
            )}
          </Col>
        </Form>
      </ListGroupItem>
    </>
  );
}

export default CompanyInfo;

import React from "react";
import Appliances,{MovingService,AutoService,FoodService,LegalService} from "./UserManagemnet/Additional"

function Test() {
  return(
  <>
  <Appliances/>
  <MovingService/>
  <AutoService/>
  <FoodService/>
  <LegalService/>
  </>
  )
}

export default Test;


import React, { useState, useEffect } from 'react';
import Calender from '../ServiceRequest/Calender';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { APIService } from '../../utils/APIService';
import TextField from '@material-ui/core/TextField';

import { ListGroupItem, Row, Col, FormInput, Container, Card, CardBody, Button } from 'shards-react';

const CouponManagement = (props) => {
  const [srRequest, setSrRequest] = useState();
  const [filters, setFilters] = useState({});
  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setSrRequest();
    const serviceReq = async () => {
      const response = await APIService.fetchCouponManagement({ queryParams: filters });

      setSrRequest(
        response.map((item) => {
          return {
            id: item.id,
            couponName: item.name,
            couponCode: item.code,
            couponDescription: item.description,
            couponDiscount: item.discount,
            couponValue: item.value,
            validityStartDate: item.start_date?.split('T')[0] || '',
            validityEndDate: item.end_date?.split('T')[0] || '',
            couponLimit: item.limit,
            no_ofTimesUsed: item.used,
          };
        })
      );
    };
    serviceReq();
  }

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    setFilters({ ...filters, [name]: value });
  };

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Coupon Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ListGroupItem>
              <Row>
                <Col md={{ size: 3 }} className="mr-5">
                  <label>Coupon Discount %</label>
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="Start Value %"
                    name="couponDiscountMin"
                    autocomplete="off"
                    onChange={handleChange}
                  />
                  <FormInput
                    id="feCustId"
                    type="number"
                    placeholder="End Value %"
                    name="couponDiscountMax"
                    autocomplete="off"
                    onChange={handleChange}
                  />
                </Col>

                <Col md={{ size: 4 }} className="form-group p-3">
                  <label>Coupon Validity Start Date Range</label>
                  <Calender name="coupon_validity_start_date" onChange={handleChange} />
                </Col>
                <Col md={{ size: 4 }} className="form-group p-3">
                  <label>Coupon Validity End Date Range</label>
                  <Calender name="coupon_validity_end_date" onChange={handleChange} />
                </Col>

                <Col md={{ size: 10, order: 4 }} className="form-group p-3">
                  <Button
                    outline
                    theme="primary"
                    className="mb-2 mr-4"
                    onClick={getService}
                    disabled={!Object.keys(filters).length}
                  >
                    Apply Filter
                  </Button>
                  {!!Object.keys(filters).length && (
                    <Button
                      outline
                      theme="danger"
                      className="mb-2 mr-1"
                      onClick={() => {
                        window.location.reload();
                      }}
                      disabled={!Object.keys(filters).length}
                    >
                      Clear Filter
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              <b>Coupon List</b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  isLoading={!srRequest}
                  title=""
                  columns={[
                    { title: 'ID', field: 'id', editable: 'never' },
                    { title: 'Coupon Name', field: 'couponName', validate: (rowData) => Boolean(rowData.couponName) },
                    { title: 'Coupon Code', field: 'couponCode', validate: (rowData) => Boolean(rowData.couponCode) },
                    {
                      title: 'Coupon Description',
                      field: 'couponDescription',
                      validate: (rowData) => Boolean(rowData.couponDescription),
                    },
                    {
                      title: 'Coupon Discount (%)',
                      field: 'couponDiscount',
                      type: 'numeric',
                      validate: (rowData) => Boolean(rowData.couponDiscount),
                    },
                    {
                      title: 'Coupon Value($)',
                      field: 'couponValue',
                      type: 'numeric',
                      validate: (rowData) => Boolean(rowData.couponValue),
                    },
                    {
                      title: 'Validity Start Date',
                      field: 'validityStartDate',
                      editComponent: (props) => (
                        <TextField
                          type="date"
                          name="validityStartDate"
                          InputLabelProps={{ shrink: true }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.currentTarget.value)}
                        />
                      ),
                      validate: (rowData) => Boolean(rowData.validityStartDate),
                    },
                    {
                      title: 'Validity End Date',
                      field: 'validityEndDate',
                      editComponent: (props) => (
                        <TextField
                          type="date"
                          name="validityEndDate"
                          InputLabelProps={{ shrink: true }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.currentTarget.value)}
                        />
                      ),
                      validate: (rowData) => Boolean(rowData.validityEndDate),
                    },
                    {
                      title: 'Coupon Limit',
                      field: 'couponLimit',
                      type: 'numeric',
                      validate: (rowData) => Boolean(rowData.couponLimit),
                    },
                    { title: 'No. of Times Used', field: 'no_ofTimesUsed', editable: 'never' },
                  ]}
                  data={srRequest}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve) => {
                        APIService.addCoupon(newData)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        APIService.updateCoupon(oldData.id, newData)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        APIService.deleteCoupon(oldData.id)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CouponManagement;

import React from 'react';
import { Nav } from 'shards-react';

import SidebarNavItem from './SidebarNavItem';
import { Store } from '../../../flux';
import Loader from '../../../components/Loader/Loader';
import { APIService } from '../../../utils/APIService';

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
      roles: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    const roles = localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')).sort() : [];
    if (roles.length) this.setState({ roles });
    else this.setState({ loading: true });

    APIService.currentAdminRoles()
      .then((data) => {
        const fetchedRoles = data.map((role) => role.role_code).sort();
        if (JSON.stringify(fetchedRoles) !== JSON.stringify(roles))
          this.setState({ roles: JSON.stringify(fetchedRoles) });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }

  render() {
    const { loading, roles, navItems: items } = this.state;

    if (loading) return <Loader />;

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items
            .filter((item) => (item.code ? roles.includes(item.code) : true))
            .map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;

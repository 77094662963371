import React, { useState, useEffect } from 'react';
import { APIService } from '../../utils/APIService';
import MainTitle from '../../components/common/MainTitle';
import MaterialTable from 'material-table';
import { Row, Col, Container } from 'shards-react';
import { EMAIL_REGEX } from '../../data/constants';

const ReportsManagement = (props) => {
  const [report, setReport] = useState();

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    setReport();
    const serviceReq = async () => {
      const response = await APIService.fetchReport();
      console.log(response);
      setReport(
        response.map((item) => {
          return {
            id: item.id,
            reportName: item.report_name,
            reportDescription: item.report_description,
            reportFrequency: item.report_frequency,
            // reportDate: item.report_date,
            // reportTime: item.created_at.split(' ')[1],
            automaticScheduled: item.automatic_scheduled == 1 ? 'Yes' : 'No',
            email: item.email_to,
          };
        })
      );
    };
    serviceReq();
  }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Report Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <Row>
              <Col>
                <MaterialTable
                  isLoading={!report}
                  title=""
                  columns={[
                    { title: 'ID', field: 'id', editable: 'never' },
                    { title: 'Report Name', field: 'reportName', editable: 'never' },
                    { title: 'Report Description', field: 'reportDescription', width: '10%', editable: 'never' },
                    {
                      title: 'Report Frequency',
                      field: 'reportFrequency',
                      cellStyle: { textTransform: 'capitalize' },
                      editable: 'never',
                    },
                    // { title: "Report Date", field: "reportDate" },
                    // { title: "Report Time", field: "reportTime" },
                    { title: 'Automatic Scheduled(Yes/No)', field: 'automaticScheduled', editable: 'never' },
                    {
                      title: 'Email To',
                      field: 'email',
                      validate: (rowData) =>
                        rowData.email.split(',').every((elem) => {
                          return elem.trim().match(EMAIL_REGEX);
                        }),
                    },
                  ]}
                  data={report}
                  options={{ search: true, actionsColumnIndex: -1, exportButton: true }}
                  actions={[
                    {
                      icon: 'mail',
                      tooltip: 'Generate Report',
                      onClick: (event, data) => {
                        if (window.confirm(`Are you sure you want to generate '${data.reportName}'?`)) {
                          APIService.generateReport(data.id).then(() => {
                            alert('Report sent to email to!');
                          });
                        }
                      },
                    },
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        APIService.updateReport(oldData.id, newData)
                          .then(getService)
                          .then(resolve)
                          .catch((error) => alert(error.errorMessage || error.message));
                      }),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ReportsManagement;

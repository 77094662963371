export default function () {
  return [
    {
      title: 'Dashboard',
      to: '/dashboard',
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: '',
    },
    {
      code: 'SAM',
      title: 'Sub-Admin',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/sub-admins',
    },
    {
      code: 'UM',
      title: 'Users Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/users',
    },
    {
      code: 'SRM',
      title: 'Service Request Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/service-request-mgt',
    },
    {
      code: 'QM',
      title: 'Quote Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/quote-management',
    },
    {
      code: 'WDM',
      title: 'Warranty And Dispute Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/warranty-dispute-mgt',
    },
    {
      code: 'CM',
      title: 'Coupon Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/coupon-managemnet',
    },
    {
      code: 'AM',
      title: 'Adjustment Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/adjustment-management',
    },
    {
      code: 'RM',
      title: 'Reports Management',
      htmlBefore: '<i class="material-icons">people</i>',
      to: '/reports-managemnet',
    },
    {
      code: 'CATM',
      title: 'Categories',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/categories',
    },
    {
      code: 'SCATM',
      title: 'Sub Categories',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/sub-categories',
    },
    {
      code: 'STYM',
      title: 'Service Type',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/service-type',
    },
    {
      code: 'DSM',
      title: 'Data Set Management',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/data-set',
    },
    {
      code: 'CFM',
      title: 'Commission Tax',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/charge-factor',
    },
    {
      code: 'CMS',
      title: 'Content Management',
      htmlBefore: '<i class="material-icons">category</i>',
      to: '/cms',
    },
    {
      title: 'Forms & Components',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/components-overview',
    },
  ];
}

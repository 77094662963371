import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'shards-react';
import ContentHeader from '../../../components/common/ContentHeader';
import MainTitle from '../../../components/common/MainTitle';
import { ROLE_TYPES, USER_STATUS } from '../../../data/constants';
import CustBotton from '../../ServiceRequest/CustButton';
import ViewEditPersonal from './Personal';
import ViewEditAdditional from './Additional';

function ViewEditUser(props) {
  const { id } = useParams();
  const [state, setState] = useState({
    viewPersonal: true,
    viewAdditional: false,
    isEdit: props.location.state?.isEdit,
  });

  const handleRoleStatusChange = (e) => {
    if (state.viewPersonal) {
      const { name, value } = e.currentTarget;
      setState({ ...state, [name]: value });
    }
  };

  return (
    <Container fluid className="main-content-container">
      <MainTitle title={(state.isEdit ? 'Edit' : 'View') + ' User Screen'} />
      <ContentHeader>
        <Button
          onClick={() => {
            if (window.confirm('All your changes will be lost.Do you still want to continue ?')) {
              props.history.push('/users');
            }
          }}
          outline
          theme="primary"
          className="mb-2 mr-1"
        >
          Back
        </Button>
      </ContentHeader>

      <CardHeader>
        <Row>
          <Col md={{ size: 4 }}>
            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText>User Type</InputGroupText>
              </InputGroupAddon>
              {state.viewPersonal && state.isEdit ? (
                <FormSelect id="feUser" name="role_type" onChange={handleRoleStatusChange} value={state.role_type}>
                  <option>Choose</option>
                  <option value="1">Customer</option>
                  <option value="2">Provider</option>
                  <option value="3">Both</option>
                </FormSelect>
              ) : (
                <FormInput
                  placeholder="User Type"
                  name="role_type"
                  value={ROLE_TYPES[state.role_type]}
                  readOnly="true"
                />
              )}
            </InputGroup>
          </Col>
          <Col md={{ size: 4 }}>
            <InputGroup className="mb-2">
              <InputGroupAddon type="prepend">
                <InputGroupText>Status</InputGroupText>
              </InputGroupAddon>
              {state.viewPersonal && state.isEdit ? (
                <FormSelect id="feUser" name="status" onChange={handleRoleStatusChange} value={state.status}>
                  <option>Choose</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </FormSelect>
              ) : (
                <FormInput placeholder="Status" name="status" value={USER_STATUS[state.status]} readOnly="true" />
              )}
            </InputGroup>
          </Col>
        </Row>

        <Col className="mb-3"></Col>

        <Row>
          <CustBotton
            state={state}
            setState={setState}
            buttons={{ viewPersonal: 'Personal Information', viewAdditional: 'Additional Information' }}
          />
        </Row>
      </CardHeader>
      {state.viewPersonal && (
        <ViewEditPersonal
          isEdit={state.isEdit}
          setEdit={() => setState({ ...state, isEdit: true })}
          setRoleStatus={(data) => setState({ ...state, ...data })}
          role_type={state.role_type}
          status={state.status}
          userId={id}
          history={props.history}
        />
      )}
      {state.viewAdditional && (
        <ViewEditAdditional
          isEdit={state.isEdit}
          setEdit={() => setState({ ...state, isEdit: true })}
          setRoleStatus={(data) => setState({ ...state, ...data })}
          role_type={state.role_type}
          status={state.status}
          userId={id}
          history={props.history}
        />
      )}
    </Container>
  );
}

export default ViewEditUser;

import React, { useState, useEffect } from 'react';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import { APIService } from '../../utils/APIService';
import { useParams } from 'react-router-dom';

import { PhotoUpload } from '../ServiceRequest/ViewServiceRequest';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PhotoUploads from '../Uploads/PhotoUploads';
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react';

import ViewComments from './ViewComments';

const ViewQuoteDetails = (props) => {
  const { quoteId } = useParams();
  const [viewQuote, setViewQuote] = useState({});
  console.log(viewQuote);

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = async () => {
      const response = await APIService.fetchQuoteById(quoteId);
      const quote = response.quote[0] || {};
      const quoteData = {
        quoteId: quote.id,
        srId: quote.request_id,
        srStatus: quote.sr?.srstatus?.name || '',
        custId: quote.user_id,
        custFirstName: quote.user?.first_name || '',
        custLastName: quote.user?.last_name || '',
        spId: quote.provider_id,
        spFirstName: quote.provider?.first_name || '',
        spLastName: quote.provider?.last_name || '',
        quoteStatus: quote.quotestatus?.name || '',
        serviceIncluded: quote.services_included,
        labourQuoteAmount: quote.labor_quote_amount || '0.00',
        materialQuoteAmount: quote.material_quote_amount || '0.00',
        materialIncluded: quote.material_included_flag === 1 ? 'yes' : 'no',
        totalQuoteAmountFromSP: (
          parseFloat(quote.labor_quote_amount || 0) + parseFloat(quote.material_quote_amount || 0)
        ).toFixed(2),
        totalQuoteAmountToCustomer: (
          parseFloat(quote.uplifted_labor_quote_amount || 0) + parseFloat(quote.uplifted_material_quote_amount || 0)
        ).toFixed(2),
        additionalComments: quote.additional_comments,
        quoteServiceDate: quote.quote_service_date?.split('T')[0] || '',
      };

      setViewQuote(quoteData);
    };

    serviceReq();
  }
  function RadioButton({ value }) {
    return (
      <FormControl component="fieldset">
        <RadioGroup aria-label="address" name="adress" value={value} row aria-disabled="true">
          <FormControlLabel value="yes" control={<Radio color="primary" />} label="YES" readonly="true" />
          <FormControlLabel value="no" control={<Radio color="primary" />} label="NO" readonly="true" />
        </RadioGroup>
      </FormControl>
    );
  }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="View Quote Details" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push('/quote-management');
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 1 }}>
                  <label htmlFor="feMobile">SR ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Id"
                    name="srId"
                    readonly="true"
                    value={viewQuote.srId}
                  />
                </Col>
                <Col md={{ size: 2, order: 1 }}>
                  <label htmlFor="feMobile">Quote ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote ID"
                    name="quoteID"
                    readonly="true"
                    value={viewQuote.quoteId}
                  />
                </Col>
                <Col md={{ size: 2, order: 1 }}>
                  <label htmlFor="feMobile">Quote Service Date</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote Service Date"
                    name="quoteServiceDate"
                    readonly="true"
                    value={viewQuote.quoteServiceDate}
                  />
                </Col>
                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">SR Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Status"
                    name="srStatus"
                    readonly="true"
                    value={viewQuote.srStatus}
                  />
                </Col>
                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">Quote Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote Status"
                    name="quoteStatus"
                    readonly="true"
                    value={viewQuote.quoteStatus}
                  />
                </Col>

                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                    readonly="true"
                    value={viewQuote.custId}
                  />
                </Col>
                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                    readonly="true"
                    value={viewQuote.custFirstName}
                  />
                </Col>
                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    readonly="true"
                    value={viewQuote.custLastName}
                  />
                </Col>

                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feMobile">Service Provider ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Provider ID"
                    name="serviceProviderId"
                    readonly="true"
                    value={viewQuote.spId}
                  />
                </Col>
                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feFirstName">Service Provider First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="Service Provider First Name"
                    name="SPfistName"
                    readonly="true"
                    value={viewQuote.spFirstName}
                  />
                </Col>
                <Col md={{ size: 4, order: 1 }}>
                  <label htmlFor="feLastName">Service Provider Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Service Provider Last Name"
                    type="text"
                    name="SPlastName"
                    readonly="true"
                    value={viewQuote.spLastName}
                  />
                </Col>

                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">Labour Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Labour Quote Amount"
                    name="LabourQuoteAmount"
                    readonly="true"
                    value={viewQuote.labourQuoteAmount}
                  />
                </Col>
                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">Material Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Material Quote Amount"
                    name="MaterialQuoteAmount"
                    readonly="true"
                    value={viewQuote.materialQuoteAmount}
                  />
                </Col>
                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">Total Quote Amount from SP </label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Total Quote Amount From SP"
                    name="TotalQuoteAmountSP"
                    readonly="true"
                    value={viewQuote.totalQuoteAmountFromSP}
                  />
                </Col>
                <Col md={{ size: 3, order: 1 }}>
                  <label htmlFor="feMobile">Total Quote Amount to Customer</label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Total Quote Amount To Customer"
                    name="TotalQuoteAmountToCustomer"
                    readonly="true"
                    value={viewQuote.totalQuoteAmountToCustomer}
                  />
                </Col>

                <Col md={{ size: 2, order: 1 }}>
                  <label htmlFor="feMobile" className="mb-2 mt-2 mr-2">
                    Material Included
                  </label>
                  <RadioButton value={viewQuote.materialIncluded} />
                </Col>
                <Col md={{ size: 5, order: 1 }}>
                  <label htmlFor="feMobile">Service Included</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Included"
                    name="ServiceIncluded"
                    readonly="true"
                    value={viewQuote.serviceIncluded}
                  />
                </Col>
                
                <Col md={{ size: 5, order: 1 }}>
                  <label htmlFor="feMobile">Additional Comments </label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Additional Comments"
                    name="AdditionalComments"
                    readonly="true"
                    value={viewQuote.additionalComments}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 2, order: 2, offset: 10 }} className="form-group p-3">
                  {/* <Button
                    onClick={() => {
                      props.history.push('/view-chats');
                    }}
                    outline
                    theme="primary"
                    className="mb-2 mr-2"
                  >
                    View Chats
                  </Button> */}
                  <Button
                    outline
                    theme="primary"
                    className="mb-2 ml-2"
                    onClick={() => {
                      props.history.push('/edit-quote/' + quoteId);
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Col md={{ size: 4, order: 1 }} className="mb-2 ml-2">
        <h4>
          <b>Comments List </b>
        </h4>
      </Col>
      <ViewComments quoteId={quoteId} />
    </div>
  );
};
export default ViewQuoteDetails;

import React, { useEffect, useState } from 'react';
import {
  Col,
  FormInput,
  ListGroupItem,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormTextarea,
} from 'shards-react';
import Loader from '../../components/Loader/Loader';
import { APIService } from '../../utils/APIService';

function SpPayout(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    const serviceReq = () => {
      APIService.fetchSrSpPayout(props.srId)
        .then((response) => {
          if (response && response.spPayout) {
            const sp_home_address = ['sp_address_1', 'sp_address_2', 'sp_city', 'sp_state', 'sp_country']
              .map((key) => response.spPayout[key])
              .filter((value) => value)
              .join(', ');
            const service_address = ['sr_address_1', 'sr_address_2', 'sr_city', 'sr_state', 'sr_country']
              .map((key) => response.spPayout[key])
              .filter((value) => value)
              .join(', ');

            setData({
              ...response.spPayout,
              sp_home_address,
              service_address,
              payout_date: response.spPayout.payout_date?.split('T')[0] || '',
              sr_service_date: response.spPayout.sr_service_date?.split('T')[0] || '',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };
    serviceReq();
  }

  if (loading) {
    return <Loader />;
  }

  if (!loading && !Object.keys(data).length)
    return <h5 className="text-center">No SP Payout found for SR {props.srId}</h5>;

  return (
    <ListGroupItem>
      <Row>
        <Col>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Provider ID</InputGroupText>
            </InputGroupAddon>
            <FormInput readonly="true" value={data.provider_id} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Provider First Name</InputGroupText>
            </InputGroupAddon>
            <FormInput readonly="true" value={data.sp_first_name} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Provider Last Name</InputGroupText>
            </InputGroupAddon>
            <FormInput readonly="true" value={data.sp_last_name} />
          </InputGroup>
        </Col>
        <Col>
          <Row>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Payout #</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.payout_id} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Request ID</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.sr_id} />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Payout Date</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.payout_date} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Quote ID</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.quote_id} />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Service Date</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.sr_service_date} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Customer ID</InputGroupText>
                </InputGroupAddon>
                <FormInput readonly="true" value={data.provider_id} />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Provider's Home Address</InputGroupText>
            </InputGroupAddon>
            <FormTextarea readonly="true" value={data.sp_home_address} />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Service Address</InputGroupText>
            </InputGroupAddon>
            <FormTextarea readonly="true" value={data.service_address} />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Category</InputGroupText>
            </InputGroupAddon>
            <FormInput readonly="true" value={data.sr_category} />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText>Service Request Description</InputGroupText>
            </InputGroupAddon>
            <FormInput readonly="true" value={data.sr_description} />
          </InputGroup>
        </Col>
      </Row>

      <div className="mt-4">
        <table className="table table-bordered text-center">
          <thead className="thead-light">
            <tr>
              <th scope="col">Description of Payout</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Quote Price</th>
              <td>+ {data.quote_price}</td>
            </tr>
            <tr>
              <th scope="row">Additional Cost</th>
              <td>+ {data.additional_cost}</td>
            </tr>
            <tr>
              <th scope="row" className="table-dark">
                SUBTOTAL
              </th>
              <td className="table-dark">+ {data.sub_total}</td>
            </tr>
            <tr>
              <th scope="row">Service Charges</th>
              <td>- {data.service_charge}</td>
            </tr>
            <tr>
              <th scope="row">Tip Amount</th>
              <td>+ {data.tip_amount}</td>
            </tr>
            <tr>
              <th scope="row">Balance adjustment</th>
              <td>- {data.balance_adjustments}</td>
            </tr>
            <tr className="active">
              <th scope="row" className="table-dark">
                NET PAYOUT
              </th>
              <td className="table-dark">{data.net_payout_amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ListGroupItem>
  );
}

export default SpPayout;

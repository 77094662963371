import React, { useState } from 'react';
import { Row, Col, Container, CardHeader } from 'shards-react';
import ContentHeader from '../../components/common/ContentHeader';
import MainTitle from '../../components/common/MainTitle';
import DisputeMgt from './DisputeMgt';
import WarrantyMgt from './WarrantyMgt';
import CustBotton from '../ServiceRequest/CustButton';

const WarrantyAndDisputeMgt = (props) => {
  const [state, setState] = useState({ warrantyMgt: true, disputeMgt: false });
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Warranty And Dispute Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col className="text-md-left">
                  <CustBotton
                    state={state}
                    setState={setState}
                    buttons={{
                      warrantyMgt: 'WARRANTY REQUEST MANAGEMENT',
                      disputeMgt: 'DISPUTE REQUEST MANAGEMENT',
                    }}
                  />
                </Col>
              </Row>
              {state.warrantyMgt ? <WarrantyMgt history={props.history} /> : null}
              {state.disputeMgt ? <DisputeMgt history={props.history} /> : null}
            </CardHeader>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default WarrantyAndDisputeMgt;

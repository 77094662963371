import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, ListGroup, ListGroupItem, Row } from 'shards-react';
import MainTitle from '../../components/common/MainTitle';
import CustBotton from '../ServiceRequest/CustButton';
import HomePage from './HomePage';
import HowItWorks from './How it Works';
import StartEarningNow from './Start Earning Now';
import AboutEzunction from './About eZunction';
import Tnc from './Terms & Conditions';
import PrivacyPolicy from './Privacy Policy';
import Faq from './FAQs';
import ServiceProviderGuide from './Service Provider Guide';
import PeoplesVoice from "./People's Voice";
import GetInTouch from './Get in Touch';

const BUTTONS = {
  homePage: 'Home Page',
  howItWorks: 'How it Works',
  startEarningNow: 'Start Earning Now',
  aboutEzunction: 'About eZunction',
  tnc: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  faq: 'FAQs',
  serviceProviderGuide: 'Service Provider Guide',
  peoplesVoice: "People's Voice",
  getInTouch: 'Get in Touch',
};

function ContentManagement(props) {
  const { id } = useParams();
  const [state, setState] = useState({
    homePage: true,
    howItWorks: false,
    startEarningNow: false,
    aboutEzunction: false,
    tnc: false,
    privacyPolicy: false,
    faq: false,
    serviceProviderGuide: false,
    peoplesVoice: false,
    getInTouch: false,
  });

  return (
    <Container fluid className="main-content-container">
      <MainTitle title={BUTTONS[Object.keys(BUTTONS).filter((key) => state[key])[0]]} />

      <Row>
        <Col md={{ size: 3 }} style={{ paddingRight: 0 }}>
          <ListGroup>
            <ListGroupItem>
              <CustBotton state={state} setState={setState} buttons={BUTTONS} vertical />
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col style={{ paddingLeft: 0 }}>
          {state.homePage && <HomePage userId={id} history={props.history} />}
          {state.howItWorks && <HowItWorks userId={id} history={props.history} />}
          {state.startEarningNow && <StartEarningNow userId={id} history={props.history} />}
          {state.aboutEzunction && <AboutEzunction userId={id} history={props.history} />}
          {state.tnc && <Tnc userId={id} history={props.history} />}
          {state.privacyPolicy && <PrivacyPolicy userId={id} history={props.history} />}
          {state.faq && <Faq userId={id} history={props.history} />}
          {state.serviceProviderGuide && <ServiceProviderGuide userId={id} history={props.history} />}
          {state.peoplesVoice && <PeoplesVoice userId={id} history={props.history} />}
          {state.getInTouch && <GetInTouch userId={id} history={props.history} />}
        </Col>
      </Row>
    </Container>
  );
}

export default ContentManagement;
